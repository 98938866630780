import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@digix/electron/shared';
import { HtmlLink, RouterLink } from '@digix/electron/shared/link/styles';

const Link = ({ color, href, icon, size, kind, text, target, to, ...props }) => (
  <Fragment>
    {href && (
      <HtmlLink icon={icon} href={href} target={target}>
        {text && (icon === 'close' ? text.toUpperCase() : text)}
        {props.children}
      </HtmlLink>
    )}

    {to && (
      <RouterLink icon={icon} to={to} kind={kind} {...props}>
        {text && (icon === 'close' ? text.toUpperCase() : text)}
        {props.children}
        {icon && <Icon name={icon} kind={kind} size={icon === 'close' ? 'xsmall' : size} />}
      </RouterLink>
    )}
  </Fragment>
);

const { bool, object, string, node, oneOfType } = PropTypes;

Link.propTypes = {
  children: oneOfType([object, string, node]),
  color: string,
  icon: string,
  kind: string,
  text: string,
};

export default Link;