import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Arrow = (kind, props, size) => (
  <Container kind={kind} {...props}>
    <Icon kind={kind} viewBox="0 0 16.7 10.4" size={size}>
      <path fill="none" d="M10.9,0.4L16,5.2L10.9,10 M16,5.2H0" />
    </Icon>
  </Container>
);

Arrow.defaultProps = {
  width: '',
  height: '',
};

export default Arrow;
