import Modal from 'react-responsive-modal';
import React from 'react';
import SessionTimeoutStyles from '@digix/electron/containers/session-timeout/style';
import { connect } from 'react-redux';
import lightTheme from '@digix/electron/themes/light';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Button, Heading } from '@digix/electron/shared';
import { bool, func, object } from 'prop-types';
import { checkForInactivity, endUserSession } from '@digix/electron/utils/session-helpers';
import { resetReduxState, showSessionTimeoutWarning } from '~/actions/user';

const { Container, Cta } = SessionTimeoutStyles;

const modalStyles = {
  closeButton: {
    cursor: 'pointer',
  },
  modal: {
    padding: '3rem',
  },
  overlay: {
    background: lightTheme.colors.primary.base,
    zIndex: 9999,
  },
};

const SessionTimeout = ({
  history,
  isVisible,
  onResetReduxState,
  onShowSessionTimeoutWarning,
  t,
}) => {
  const handleOnContinueSession = () => {
    onShowSessionTimeoutWarning(false);
    checkForInactivity(history, onResetReduxState);
  };
  return (
    <Modal
      center
      closeOnEsc={false}
      closeOnOverlayClick={false}
      open={isVisible}
      showCloseIcon={false}
      styles={modalStyles}
    >
      <Container data-digix="Session-Modal">
        <Heading level="5">{t('SessionTimeout.heading')}</Heading>
        <Cta>
          <Button
            corner="light"
            kind="primary"
            onClick={e => {
              e.preventDefault();
              onResetReduxState();
              endUserSession({
                currentUser: true,
                history,
                onResetReduxState,
                path: '/portal/login?invalid_token=true',
              });
            }}
            size="medium"
            text={t('SessionTimeout.no')}
            variant="outlined"
          />
          <Button
            corner="light"
            onClick={handleOnContinueSession}
            size="medium"
            text={t('SessionTimeout.yes')}
            variant="contained"
          />
        </Cta>
      </Container>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => ({
  onResetReduxState(keystore) {
    dispatch(resetReduxState(keystore));
  },
  onShowSessionTimeoutWarning(isShown) {
    dispatch(showSessionTimeoutWarning(isShown));
  },
});

SessionTimeout.propTypes = {
  history: object.isRequired,
  isVisible: bool.isRequired,
  onResetReduxState: func.isRequired,
  onShowSessionTimeoutWarning: func.isRequired,
  t: func.isRequired,
};

export default withRouter(
  connect(null, mapDispatchToProps)(
    withTranslation('SessionTimeout')(SessionTimeout),
  ),
);
