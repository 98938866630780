import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Speaker = props => (
  <Container {...props}>
    <Icon viewBox="0 0 46 32">
      <path d="M1 19h6V9H1v10zm31 8l-5.2-2.5c-4.8-2.3-10-3.9-15.3-4.8L7 19V9l4.5-.7c5.3-.9 10.5-2.5 15.3-4.8L32 1v26zm-15-5.7V31h-6V20M26 4v20m12-10h8m-8-7l6-4m0 22l-6-4" fill="none" />
    </Icon>
  </Container>
);

export default Speaker;


