import { Button } from '@digix/electron/shared';
import React from 'react';
import StyledSteps from '@digix/electron/shared/stepper/styles';
import {
  arrayOf,
  bool,
  func,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';

const {
  BoxWrapper,
  Container,
  Details,
  Line,
  Progress,
  Step,
  Title,
  TitleWrapper,
  Wrapper,
} = StyledSteps;

const Stepper = ({
  current,
  editText,
  height,
  isEditDisabled,
  onClickEdit,
  orientation,
  steps,
  stepText,
}) => (
    <Wrapper
      height={height}
      orientation={orientation}
      totalSteps={steps.length}
    >
      <Container orientation={orientation}>
        <Line orientation={orientation}>
          <Progress
            orientation={orientation}
            percentage={(current - 1) / (steps.length - 1) * 100}
          />
        </Line>
        {steps.map((step, key) => (
          <BoxWrapper key={key}>
            <Step
              current={current}
              error={step.error}
              stepKey={key + 1}
            />
          </BoxWrapper>
        ))}
      </Container>
      <Container orientation={orientation}>
        {steps.map(({
          error,
          description,
          isEditable,
          title,
        }, i) => (
          <Details
            isEditable={isEditable && current > i + 1}
            key={i}
            orientation={orientation}
            totalSteps={steps.length}
          >
            <TitleWrapper>
              <Title error={error}>
                {`${stepText} ${i + 1}${title ? ` - ${title}` : ''}`}
              </Title>
              {(isEditable && current > i + 1) && (
                <Button
                  hasIcon
                  disabled={isEditDisabled}
                  icon="edit"
                  onClick={() => onClickEdit(i + 1)}
                  text={editText}
                  variant="text"
                />
              )}
            </TitleWrapper>
            {description && (
              <p>{description}</p>
            )}
          </Details>
        ))}
      </Container>
    </Wrapper>
  );

Stepper.propTypes = {
  current: number,
  editText: string,
  height: string,
  isEditDisabled: bool,
  onClickEdit: func,
  orientation: oneOf(['horizontal', 'vertical']),
  steps: arrayOf(
    shape({
      description: string,
      error: bool,
      isEditable: bool,
      title: string,
    })
  ),
  stepText: string,
};

Stepper.defaultProps = {
  current: 1,
  editText: 'Edit',
  height: null,
  isEditDisabled: false,
  onClickEdit: () => { },
  orientation: 'horizontal',
  steps: [],
  stepText: 'Step',
};

export default Stepper;
