/* eslint-disable */
import { BROWSER_TYPE } from '@digix/electron/utils/constants';

export default function browserDetection() {
  const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  const isFirefox = typeof InstallTrigger !== 'undefined';
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === '[object SafariRemoteNotification]'; })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));
  const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

  if (isOpera) {
    return BROWSER_TYPE.opera;
  }
  if (isFirefox) {
    return BROWSER_TYPE.firefox;
  }
  if (isSafari) {
    return BROWSER_TYPE.safari;
  }
  if (isChrome) {
    return BROWSER_TYPE.chrome;
  }

  return BROWSER_TYPE.unlisted;
}
