import { WALLET_LOADER_TYPE } from '@digix/electron/utils/constants';
import { actions } from '~/actions/keystore';

const initialState = {
  JSONFile: undefined,
  ethAddress: '',
  hdPath: '',
  showWalletLoader: {
    postAction: undefined, // action to be done after loading wallet
    show: false,
    type: WALLET_LOADER_TYPE.register,
  },
  walletType: '',
};

const keystore = (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_KEYSTORE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default keystore;
