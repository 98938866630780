import React from 'react';
import StyledTooltip from '@digix/electron/shared/tooltip/styles';
import {
  bool,
  node,
  object,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';

const {
  BoxArrow,
  Children,
  Title,
  Wrapper,
} = StyledTooltip;

const Tooltip = ({
  children,
  fontSize,
  placement,
  text,
  type,
  width,
  withArrow,
}) => (
  <Children>
    {children}
    <Wrapper
      placement={placement}
      type={type}
      width={width}
    >
      <Title fontSize={fontSize}>{text}</Title>
      {withArrow && (
        <BoxArrow
          placement={placement}
          type={type}
        />
      )}
    </Wrapper>
  </Children>
);

Tooltip.defaultProps = {
  fontSize: null,
  placement: 'right',
  type: 'default',
  width: null,
  withArrow: false,
};

Tooltip.propTypes = {
  children: node.isRequired,
  fontSize: string,
  placement: oneOf(['bottom', 'left', 'right', 'top']),
  text: oneOfType([object, string]).isRequired,
  type: oneOf(['bordered', 'error', 'default', 'primary', 'success', 'warning']),
  width: oneOfType([object, string]),
  withArrow: bool,
};

export default Tooltip;
