import styled from 'styled-components';
import { Button } from '@digix/electron/shared/button/styles';
import { cornerAccent } from '@digix/electron/shared';

export const Outlined = styled(Button)`
  --background: transparent;
  --border-color: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-primary)'};
  --color: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-primary)'};
  --font-size: var(--font-size-2xs);
  --text-muted: var(--color-inverse);
  position: relative;

  div svg {
    --disabled: var(--color-inverse);
    --fill: var(--fill, none);
    --stroke: ${props => props.accent ? 'var(--color-primary)' : 'var(--color-inverse)'};
  }

  /* stylelint-disable-next-line */
  ${props => cornerAccent((props.disabled ? 'disabled' : 'primary'), 'medium')};


  &:hover {
    --background: transparent;
    --border-color: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-secondary)'};
    --color: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-secondary)'};

    svg {
      --stroke: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-secondary)'};
    }

    ${props => cornerAccent((props.disabled ? 'disabled' : 'secondary'), 'medium')};
  }
`;
