import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Transactions = props => (
  <Container {...props}>
    <Icon viewBox="0 0 34.6 19.2">
      <path fill="none" strokeWidth="1.3" d="M25.6 4.9L1.6 5 7.2-.7" />
      <path fill="none" strokeWidth="1.3" d="M26.4 11.8l3.8 3.8 2.8 2.9-23.4.1" />
      <polygon fill="none" stroke="none" points="22.4,13.6 30.7,13.6 30.7,10.4 22.4,10.4 " />
      <polygon fill="none" stroke="none" points="3.2,0 9.6,0 9.6,-1.8 3.2,-1.8 " />
    </Icon>
  </Container>
);

export default Transactions;
