import { css } from 'styled-components';

export const breakpoints = {
  xxs: 320,
  xs: 414,

  sm: 768,   //  iPhoneX landscape,iPad
  md: 1024,  //  iPad landscape, iPad Pro 12.9 portrait

  lg: 1366,  //  iPad Pro 12.9 landscape, standard desktop
  xl: 1600,  //  hd desktop and up

  xxl: 1680, //  DevNote: to be potentially updated to 1920
};

// When creating breakpoints, we use em unit so that it will give consistent result if the user has larger text.

export const media = Object.keys(breakpoints).reduce((accumulator, label) => {
  const emSize = breakpoints[label] / 16
  accumulator[label] = (...args) => css`
    @media only screen and (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export default media;
