import { ApolloProvider } from 'react-apollo';
import ElectronUi from '@digix/electron/App';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { electronClient } from '@digix/electron/api/graphql';
import { hot } from 'react-hot-loader'; // react-hot-loader must be imported before react and react-dom
// eslint-disable-next-line
import React from 'react';
import { render } from 'react-dom';
import {
  HashRouter,
  Route,
  Switch,
} from 'react-router-dom';
import i18n from '~/translations/i18n'; // eslint-disable-line
import store from '~/store';
import '~/helpers/offlinePlugin';

const renderApp = () => {
  render(
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={electronClient}>
          <HashRouter>
            <Switch>
              <Route
                component={ElectronUi}
                path="/"
              />
            </Switch>
          </HashRouter>
        </ApolloProvider>
      </I18nextProvider>
    </Provider>,
    document.getElementById('app'),
  );
};

renderApp();

if (module.hot) {
  module.hot.accept(() => hot(renderApp));
}
