/*
 * This stylesheet exports the Pagination styled component, which is used for
 * fetching/setting new content of whatever is wrapping the component. Check the
 * Table component to see how this is used.
 *
 * =============================================================================
 * The hierarchy for the component is as follows:
 *
 * Pagination.List (div)
 *   Pagination.Item (Button)
 *
 * =============================================================================
 *
 * There are no props for this styled component.
 *
 */

import styled, { css } from 'styled-components';
import { Button, cornerAccent } from '@digix/electron/shared';

const PaginationStyles = {
  Button: styled(Button)`
    --disabled: transparent;
    --color: var(--muted);
    --margin-left: ${props => props.variant === 'icon' ? 'var(--space-3xs)' : 'var(--space-4xs)'};
    --margin-right: ${props => props.variant === 'icon' ? 'var(--space-3xs)' : 'var(--space-4xs)'};
    height: 2.6rem;
    padding: ${props => (!props.isActive && props.variant === 'icon') ? 'var(--space-4xs)' : 'var(--space-2xs)'};
    ${props => props.isActive && css`
      --background: var(--color-secondary);
      --border-color: var(--color-secondary);
      --color: var(--color-inverse);
      --disabled: var(--muted);
      ${cornerAccent((props.disabled ? 'disabled' : 'secondary'), 'small')};
    `};


    div svg {
      --stroke: var(--muted);
      stroke-width: 1.75;
    }
    
    &[data-name="backward"] {
      --margin-right: var(--space-2xs);
      --margin-left: var(--space-xs);
    }
    &[data-name="forward"] {
      --margin-left: var(--space-2xs);
      --margin-right: var(--space-xs);
    }
  `,
  Item: styled.button`
    align-items: center;
    background: ${props => props.active ? 'var(--color-secondary)' : 'var(--color-inverse)'};
    border: solid 1px ${props => props.active ? 'var(--color-secondary)' : 'transparent'};
    color: ${props => props.theme.pagination.textColor.default};
    cursor: pointer;
    display: inline-flex;
    font-family: ${props => props.theme.typography.typeface.primary.bold};
    font-size: ${props => props.theme.typography.fontSize.sm};
    height: 2rem;
    justify-content: center;
    margin: 0.8rem;
    outline: none;
    padding: 0 0.4rem;
    transition: all var(--transition);
    width: 2rem;

    &:hover {
      ${props => !props.active && css`
        background: ${props => props.theme.pagination.background.default};
        color: ${props => props.theme.pagination.textColor.default};
      `}
    }

    ${props => props.active && css`
      cursor: default;
      color: var(--color-inverse);

      ${props => cornerAccent(props.corner, (props.kind ? props.kind : 'disabled'), 'xsmall')};
    `}
  `,
  List: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2.5rem;
  `,
  Wrapper: styled.div`
    align-items: center;
    display: flex;
    margin-left: auto;
    width: fit-content;

    button {
      min-width: fit-content;
      width: fit-content;

      :first-child, :nth-child(2) {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  `,
};

export default PaginationStyles;
