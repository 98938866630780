import * as AbsintheSocket from '@absinthe/socket';
import { ASSETS_EXPLORER_SERVER } from '@digix/electron/config/endpoints';
import { Socket as PhoenixSocket } from 'phoenix';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { hasSubscription } from '@jumpn/utils-graphql';
import { split } from 'apollo-link';

const setupAssetsExplorerServer = ({ ssr }) => {
  let absintheSocket;
  let assetsSocketLink;

  let assetsLink = createHttpLink({
    fetch,
    uri: ASSETS_EXPLORER_SERVER().http,
  });

  if (!ssr) {
    absintheSocket = AbsintheSocket.create(
      new PhoenixSocket(ASSETS_EXPLORER_SERVER().webSocket, {
        params: () => {
          const token = localStorage.getItem('user-token');
          if (token) {
            return { token };
          }

          return {};
        },
      }),
    );

    assetsSocketLink = createAbsintheSocketLink(absintheSocket);
    assetsLink = split(
      (operation) => hasSubscription(operation.query),
      assetsSocketLink,
      assetsLink,
    );
  }

  const queries = new RegExp([
    'fetchAsset',
    'fetchAssets',
    'fetchAudits',
    'fetchBalances',
    'fetchCustodians',
    'fetchLocations',
    'fetchStates',
    'fetchTokenDetails',
    'fetchUserTokenInformation',
  ].join('|'));

  return {
    absintheSocket,
    link: assetsLink,
    queries,
    socket: assetsSocketLink,
  };
};


export default setupAssetsExplorerServer;
