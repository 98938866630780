import styled, { css } from "styled-components";
import { zindex, media } from '@digix/electron/shared';

export const Spinner = {
  ContentLoader: styled.div`
    align-items: center;
    background: ${props => props.isInverted
      ? props.theme.colors.primary.base
      : (props.background || props.theme.loader.backgroundColor)};
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
    z-index: ${zindex('loader')};
    
    &[data-digix="Loader-BuyDgx"] {
      margin: -2.4rem -3rem;

      ${media.sm`
        margin: -4rem -4.8rem;
      `}

      ${media.md`
        margin: -2.4rem -3rem;
      `}

      ${media.lg`
        margin: -3.2rem -4.8rem;
      `}
    }

    &[data-digix="Loader-RegisterWallet"] {
      margin: 0rem;

      ${media.md`
        margin: -2.4rem -3rem;
      `}

      ${media.lg`
        margin: -3.2rem -4.8rem;
      `}
    }
  `,

  Icon: styled.svg`
    fill: ${({ color, theme: { loader: { svg } } }) => (color === 'primary' && svg.primary)
      || (color === 'inverted' && svg.inverted)
      || svg.primary};

    height: ${({ size }) => (size === 'small' && 1.0)
      || (size === 'large' && 1.6)
      || 1.6}rem;
    margin: 0 auto;
    min-width: ${props => props.grid ? '7rem' : 'unset'};
    width: ${props => props.simple ? '6rem' : '100%'};
  `,

  Text: styled.div`
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 3rem;
    text-align: center;
    white-space: pre-line;

    ${props => props.color === 'inverted' && css`
      color: ${props.theme.colors.neutral.white};
    `}
  `,
};
