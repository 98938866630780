import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Avatar = props => (
  <Container {...props}>
    <Icon viewBox="0 0 34 34">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-25 -27)" stroke={props.stroke} strokeWidth="1.2">
          <g transform="translate(26 28)">
            <path d="m25.658 28.497v-4.7659c0-0.26239-0.0092972-0.91474-0.0092972-0.91474s-6.4579-3.0252-9.6335-3.0252h-0.11622c-3.175 0-9.6861 3.0082-9.6861 3.0082l-0.0015495 5.6976"/>
            <path d="m15.529 7.1431c-1.8315 0-3.2917 1.4602-3.2917 3.2685v3.1755c0 1.831 1.4602 3.2912 3.2917 3.2912h0.85741c1.8078 0 3.268-1.4602 3.268-3.268v-3.1988c0-1.8083-1.4602-3.2685-3.2912-3.2685h-0.83417z"/>
            <path d="m31.399 11.976c0.32592 1.2701 0.49895 2.6012 0.49895 3.9725 0 8.8086-7.1403 15.949-15.949 15.949-8.8086 0-15.949-7.1403-15.949-15.949 0-8.8076 7.1403-15.949 15.949-15.949 1.6513 0 3.2442 0.25154 4.7426 0.71743"/>
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

export default Avatar;
