/*
 * This stylesheet exports the Table styled component, which is used for tables
 * such as the address list (when loading a ledger/trezor wallet) and the Latest
 * Transactions module.
 *
 * =============================================================================
 * The hierarchy for the component is as follows:
 *
 * Table.Container (ul)
 *   Table.Item (li)
 *     Table.Head (span)
 *     Table.Data (span)
 *
 * =============================================================================
 * The following are the available props:
 *
 * Table.Item
 *   gridTemplateColumns: overrides grid-template-columns
 *   head: boolean; if true, display row as header
 */

import styled, { css } from 'styled-components';
import { media } from '@digix/electron/shared';

const TableStyles = {
  Container: styled.ul`
    display: grid;
    grid-gap: 3.2rem;
    list-style: none;
    margin-bottom: 2.4rem;
    margin-left: -2rem;
    margin-right: 2rem;

    ${media.xs`
      margin-left: -2rem;
      margin-right: 0;
    `}

    ${media.sm`
      margin-left: 0;
      margin-right: 0;
    `}

    ${media.md`
      grid-gap: 0;
    `}

    .swiper-container {
      width: 100%;
    }
  `,

  Item: styled.li`
    background-color: var(--color-inverse);
    border: 0.8px solid rgba(26,28,32,0.06);
    box-shadow: 0 1px 32px 0 rgba(197,201,223,0.29);
    box-sizing: border-box;
    cursor: ${props => props.isClickEnabled ? 'pointer' : 'initial'};
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
    grid-template-columns: ${props => props.gridTemplateColumns || '1fr'};
    padding: var(--padding-top-bottom, '3.2rem')3.2rem;
    width: 100%;

    span {
      word-break: keep-all;
    }

    &:hover {
      ${props => !props.head && css`
        background: ${props.theme.table.hover.background};
      `}
    }

    ${media.xxs`
      display: ${props => props.head ? 'none' : 'block'};
    `}

    ${media.sm`
      border: 1px solid ${props => props.theme.table.borderColor};
      box-shadow: ${props => props.theme.card.boxShadow.onLight};
    `}

    ${media.md`
      box-shadow: none;
      border: 1px solid transparent;
      border-bottom-color: ${props => props.head ? 'transparent' : props.theme.table.borderColor};
      display: grid;
      grid-template-columns: ${props => props.gridTemplateColumns || '1fr'};
      padding: ${props => props.head ? 1.6 : 3.2}rem 1.6rem;

      &:last-child {
        border-bottom: 0;
      }

      ${props => props.head && css`
        background: var(--muted);
      `}
    `}
  `,

  Head: styled.span`
    word-break: keep-all;

    ${media.md`
      display: inline-block;
      font-family: ${props => props.theme.typography.typeface.primary.bold};
    `}
  `,

  Data: styled.span`
    align-items: flex-start;
    color: ${props => props.theme.colors.neutral.gray100};
    display: flex;
    flex-direction: column;
    font-family: ${props => props.theme.typography.typeface.primary.bold};
    font-size: 1.4rem;
    grid-column: span 2;
    margin-bottom: 2rem;
    min-width: fit-content;
    width: 100%;

    ${props => props['data-content'] !== 'Status' && css`
      word-break: break-all;
    `};

    &:nth-child(odd) {
      margin-right: 2rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &[data-content]:before {
      color: ${props => props.theme.colors.neutral.gray100};
      content: ${props => typeof props['data-content'] === 'object'
      ? `'${props['data-content'].props.children[0]}'`
      : 'attr(data-content)'};
      display: flex;
      font-family: ${props => props.theme.typography.typeface.primary.light};
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
      opacity: 0.61;
      text-transform: capitalize;
      
      ${media.sm`
        font-family: ${props => props.theme.typography.typeface.primary.bold};
        margin-bottom: 1.6rem;
      `}

      ${media.md`
        content: attr(data-content);
      `}
    }

    ${media.md`
      margin-bottom: 3.2rem;
      grid-column: auto;
      font-size: 1.6rem;
      font-family: ${props => props.theme.typography.typeface.primary.light};
      color: inherit;
      &:nth-child(odd) {
        overflow: auto;
        margin-right: 0;
      }
    `}

    ${media.md`
      flex-direction: row;
      margin-bottom: 0;

      &[data-content]:before {
        display: none;
      }
    `}

    ${props => props.status && css`
      grid-column: span 2;

      ${media.md`
        grid-column: auto;
      `}

      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
  `}
  `,

  ErrorMessage: styled.div`
    background-color: ${props => props.theme.colors.neutral.gray20};
    padding: 1.6rem;
    word-break: break-word;
  `,

  PaginationContainer: styled.div`
    margin-right: 2rem;

    ${media.md`
      margin-right: 0;
    `}
  `,
};

export default TableStyles;
