import {
  EMAIL_REGEX,
  MAX_PWD_CHAR,
  MIN_PWD_CHAR,
  NON_EMPTY_STRING_REGEX,
  PASSWORD_REGEX,
  TWO_FACTOR_CODE_LENGTH,
  TWO_FACTOR_CODE_REGEX,
} from '@digix/electron/utils/constants';

export const isEmailValid = email => {
  if (email && email.length > 0) {
    return EMAIL_REGEX.test(email);
  }
  return false;
};

export const isMatchingPassword = (password1, password2) => {
  const valid = ![null, undefined].includes(password1)
    && ![null, undefined].includes(password2)
    && password1.trim().length > 0
    && password2.trim().length > 0
    && password1.trim() === password2.trim();
  return valid;
};

export const isNonEmptyString = string => !!string && NON_EMPTY_STRING_REGEX.test(string);

export const isPasswordWeak = password => {
  let isPasswordStrong = false;
  if (password) {
    const passwordLength = password.trim().length;
    if (passwordLength >= MIN_PWD_CHAR && passwordLength <= MAX_PWD_CHAR) {
      isPasswordStrong = PASSWORD_REGEX.test(password.trim());
    }
  }
  return !isPasswordStrong;
};

export const isVerificationCodeValid = verificationCode => {
  const code = Object.values(verificationCode).join('');
  return TWO_FACTOR_CODE_REGEX.test(code) && code.length === TWO_FACTOR_CODE_LENGTH;
};

export const getAuthErrorFromResponse = errors => {
  const hasError = errors && Array.isArray(errors) && errors.length > 0;
  if (hasError) {
    const authError = errors.find(error => error.message === 'Not Authenticated');
    if (authError) {
      return {
        isAuthenticated: false,
        message: authError.message,
      };
    }
  }
  return null;
};
