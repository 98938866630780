import React from 'react';
import PropTypes from 'prop-types';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Help = ({ ...props }) => (
    <Container {...props}>
        {props.children}
        <Icon viewBox="0 0 14 14">
            <defs />
            <g fill="none" fillRule="evenodd">
                <path d="M3.121 2.422a6 6 0 11-1.667 2.283M7 3v4l2 2" />
            </g>
        </Icon>
    </Container>
);

const { object, string, node, oneOfType } = PropTypes;

Help.propTypes = {
    children: oneOfType([object, string, node]),
};

export default Help;
