import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Angle = (props) => (
  <Container {...props}>
    <Icon viewBox="0 0 10.1 16.5">
      <polyline fill="none" points="0 -0.5 9.3 8.3 0 17.1" />
    </Icon>
  </Container>
);

Angle.defaultProps = {
  width: '',
  height: '',
};

export default Angle;
