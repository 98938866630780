import styled from 'styled-components';

export const StyledProgress = {
  Label: styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  `,
  Bar: styled.div`
    background: ${props => props.theme.progress.background};
    height: 3.8rem;
    position: relative;
    width: 100%;
    & > div {
      align-items: center;
      background: ${props => props.theme.progress.active};
      display: flex;
      font-family: ${props => props.theme.typography.typeface.bold};
      height: 100%;
      justify-content: flex-end;
      padding-right: 2rem;
    }
    & > p {
      text-align: center;
    }
`,
};
