import { colors } from '@digix/electron/themes/light/colors';

const BASE_FONT_SIZE = '1rem';

const FONT_SCALE_RATIO = 1.125; // Sized from the Major Second Type Scale | 1.125
const FONT_PRIMARY_REG = "'FuturaPT-Book', Helvetica, sans-serif";
const FONT_PRIMARY_MED = "'FuturaPT-Medium'";
const FONT_PRIMARY_DEMI = "'FuturaPT-Demi'";
const FONT_PRIMARY_HEAVY = "'Futura PT Heavy'";
const FONT_SECONDARY_REG = "'Futura PT Book'";
const FONT_PLAYFAIR_DISPLAY_BOLD = "'Playfair Display'";

export const typography = {
  typeface: {
    primary: {
      light: FONT_PRIMARY_REG,
      regular: FONT_PRIMARY_REG,
      semibold: FONT_PRIMARY_MED,
      bold: FONT_PRIMARY_DEMI,
    },
    secondary: {
      bold: FONT_PLAYFAIR_DISPLAY_BOLD,
    },
  },

  typeScale: {
    xxxl: `calc(5.8 * ${BASE_FONT_SIZE})`,
    xxl: `calc(4.6 * ${BASE_FONT_SIZE})`,
    xl: `calc(3.2 * ${BASE_FONT_SIZE})`,
    lg: `calc(2.5 * ${BASE_FONT_SIZE})`,
    //TODO: new value added for mobile, all sizes below will be adjusted on mobile sprint.
    lg_new: `calc(2.0 * ${BASE_FONT_SIZE})`,
    md: `calc(1.6 * ${BASE_FONT_SIZE})`,
    sm: `calc(1.4 * ${BASE_FONT_SIZE})`,
    xs: `calc(1.2 * ${BASE_FONT_SIZE})`,
    xxs: `calc(1.1 * ${BASE_FONT_SIZE})`,
  },

  letterSpacing: {
    narrow: -0.06,
    normal: -0.03375,
    wide: 0.075,
    wider: 0.15,
  },

  lineHeight: {
    xxxl: 7.2,
    xxl: 5.6,
    xl: 4.4,
    lg: 4.0,
    //TODO: new value added for mobile, all sizes below will be adjusted on mobile sprint.
    lg_new: 3.8,
    md: 3.2,
    sm: 2.8,
    xs: 2.0,
    xxs: 1.5,
  },

  // TODO: To be removed. Do not use.
  link: {
    textColor: colors,
  },
};
