import React from 'react';
import { Icon } from '@digix/electron/shared';
import PropTypes from 'prop-types';

import { IconBtn } from '@digix/electron/shared/button/icon-button/style';

const IconButton = ({ kind, icon, size, ...props }) => (
  <IconBtn icon={icon} {...props}>
    <Icon name={icon} kind={kind} size={size} />
  </IconBtn>
);

const { object, string, node, oneOfType } = PropTypes;

IconButton.propTypes = {
  children: oneOfType([object, string, node]),
};

export default IconButton;
