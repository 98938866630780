import styled, { css } from 'styled-components';
import { lineHeight } from '@digix/electron/shared';
import { media } from '@digix/electron/shared';

const getTooltipMargins = (placement, margin) => css`
  margin-bottom: ${placement === 'top' ? margin : 0}rem;
  margin-left: ${placement === 'right' ? margin : 0}rem;
  margin-right: ${placement === 'left' ? margin : 0}rem;
  margin-top: ${placement === 'bottom' ? margin : 0}rem;
`;

const getTooltipPosition = placement => css`
  ${media.xxs`
    margin-top: 0.8rem;
    padding: 0.8rem;
    right: -100%;
    top: 100%;
  `}

  ${media.xs`
    ${getTooltipMargins(0.8)}
  `}

  ${media.sm`
    align-self: ${['left', 'right'].includes(placement) ? 'center' : 'unset'}
    bottom: ${placement === 'top' ? '100%' : 'unset'};
    left: ${['bottom', 'top'].includes(placement) ? '25%' : placement === 'right' ? '100%' : 'unset'};
    right: ${placement === 'left' ? '100%' : 'unset'};
    top: ${['left', 'right'].includes(placement) ? 'unset' : placement === 'bottom' ? '100%' : 'unset'};
    padding: 1rem;

    ${getTooltipMargins(placement, 1.2)}
  `}
`;

const getTooltipWidth = width => {
  if (typeof width === 'object') {
    const sizes = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
    return sizes.filter(i => width[i])
      .map(size => css`${media[size]` width: ${width[size]};`}`);
  }
  if (typeof width === 'string') {
    return css`width: ${width}`;
  }
  return null;
};

const StyledTooltip = {
  BoxArrow: styled.i`
    background-color: ${props => props.type === 'bordered' ? props.theme.colors.neutral.darkGray100 : 'inherit'};
    bottom: ${props => props.placement === 'bottom' ? '100%' : 'unset'};
    clip-path: polygon${({ placement }) =>
      placement === 'bottom' ? '(100% 100%, 0 100%, 50% 0)'
      : placement === 'top' ? '(100% 0, 0 0, 50% 100%)'
      : placement === 'left' ? '(0 0, 0 100%, 100% 50%)'
      : placement === 'right' ? '(100% 0, 100% 100%, 0 50%)'
      : ''};
    height: 1rem;
    left: ${props => props.placement === 'left' ? '100%' : 'unset'};
    overflow: hidden;
    position: absolute;
    right: ${props => props.placement === 'right' ? '100%' : 'unset'};
    top: ${({ placement }) => ['left', 'right'].includes(placement) ? '50%' : placement === 'top' ? '100%' : 'unset'};
    width: 1rem;

    ::after {
      background-color: ${({ theme: { colors }, type }) =>
        type === 'error' ? colors.semantic.error
        : type === 'primary' ? colors.primary.light
        : type === 'success' ? colors.semantic.success
        : type === 'warning' ? colors.accent.base
        : colors.neutral.gray05_rgb};
      bottom: ${props => props.placement === 'bottom' ? '-1' : '1'}px;
      clip-path: polygon${({ placement }) =>
        placement === 'bottom' ? '(100% 100%, 0 100%, 50% 0)'
        : placement === 'top' ? '(100% 0, 0 0, 50% 100%)'
        : placement === 'left' ? '(0 0, 0 100%, 100% 50%)'
        : placement === 'right' ? '(100% 0, 100% 100%, 0 50%)'
        : ''};
      content: '';
      left: ${props => props.placement === 'left' ? '-1' : '1'}px;
      position: absolute;
      right: ${props => props.placement === 'right' ? '-1' : '1'}px;
      top: ${props => props.placement === 'top' ? '-1' : '1'}px;
    }
  `,
  Children: styled.div`
    cursor: pointer;
    display: inline-flex;
    position: relative;
    text-align: left;
    width: fit-content;

    :hover div:last-child {
      display: block;
    }
  `,
  Title: styled.div`
    font-size: ${props => props.fontSize || props.theme.typography.fontSize.xs};
    line-height: ${props => props.theme.typography.lineHeight.xxs};
    text-align: left;
  `,
  Wrapper: styled.div`
    background-color: ${({ theme: { colors }, type }) =>
      type === 'error' ? colors.semantic.error
      : type === 'primary' ? colors.primary.light
      : type === 'success' ? colors.semantic.success
      : type === 'warning' ? colors.neutral.gray05_rgb
      : colors.neutral.white
    };
    border: ${({ theme, type }) => type === 'bordered' ? `1px solid ${theme.colors.neutral.gray15}` : 'none'};
    color: ${({ theme, type }) => theme.colors.neutral[
      ['bordered', 'default', 'warning'].includes(type) ? 'darkGray100' : 'white'
    ]};
    display: none;
    font-family: ${props => props.theme.typography.typeface.primary.regular};
    font-weight: normal;
    letter-spacing: 0.035rem;
    position: absolute;
    z-index: 1;

    ${lineHeight(1.6)};
    ${props => getTooltipPosition(props.placement)}
    ${props => getTooltipWidth(props.width)}
  `,
};

export default StyledTooltip;
