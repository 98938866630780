import styled, { css } from 'styled-components';
import { Button } from '@digix/electron/shared/button/styles';
import { cornerAccent } from '@digix/electron/shared';


export const Contained = styled(Button)`
  --background: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-primary)'};
  --border-color: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-primary)'};
  --color: ${props => props.accent ? 'var(--color-primary)' : 'var(--color-inverse)'};
  --font-size: var(--font-size-2xs);
  position: relative;

  div svg {
    --fill: none;
    --stroke: ${props => props.accent ? 'var(--color-primary)' : 'var(--color-inverse)'};
  }

  /* stylelint-disable-next-line */
  ${props => cornerAccent((props.disabled ? 'disabled' : (props.accent && 'accent' || props.primary && 'primary' || props.kind)), 'medium')};

  :hover {
    --background: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-secondary)'};
    --border-color: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-secondary)'};
  }
`;
