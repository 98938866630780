import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Check = props => (
  <Container {...props}>
    <Icon viewBox="0 0 24 24">
      <path d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z" />
    </Icon>
  </Container>
);

Check.defaultProps = {
  width: '2.25rem',
  height: '2.25rem',
};

export default Check;
