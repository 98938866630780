import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Personal = props => (
    <Container {...props}>
        <Icon viewBox="0 0 32.7 32">
            <path
                d="M14.3 20h-.2C9.7 20 .7 24.2.7 24.2v7.9M13.6.6C11.1.6 9 2.6 9 5.1v4.4c0 2.5 2 4.6 4.6 4.6h1.2c2.5 0 4.5-2 4.5-4.5V5.2c0-2.5-2-4.5-4.6-4.5h-1.1V.6zM19.8 20h12.9m-12.9 5.5h12.9m-12.9 5.6h12.9"
                fill="none"
                strokeWidth="1.3"
            />
        </Icon>
    </Container >
);

export default Personal;

