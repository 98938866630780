import styled, { css } from 'styled-components';
import { media, getAppWidth } from '@digix/electron/shared';

export const TEMPLATE_MAIN_GRID = css`
  ${media.sm`
    display: grid;
    grid-template-columns:
      [full-start] 4.8rem
      [content-start] minmax(min-content, 1fr)
      [content-end] 4.8rem 
      [full-end];
  `}

  ${media.md`
    grid-template-columns:
      [full-start] 
        minmax(4.8rem, 1fr)
      [content-start] 
        minmax(min-content, ${props => getAppWidth(props.template, 'lg')}rem)
      [content-end] 
        minmax(4.8rem, 1fr)
      [full-end];
  `}

  ${media.lg`
    grid-template-columns:
      [full-start] 
        minmax(4.8rem, 1fr)
      [content-start] 
        minmax(min-content, ${props => getAppWidth(props.template, 'xl')}rem)
      [content-end] 
        minmax(4.8rem, 1fr)
      [full-end];
  `}

  ${media.xl`
    grid-template-columns:
      [full-start] 
        minmax(4.8rem, 1fr)
      [content-start] 
        minmax(min-content, ${props => getAppWidth(props.template, 'xxl')}rem)
      [content-end] 
        minmax(4.8rem, 1fr)
      [full-end];
  `}
`;


const SECTION_MODE = css`
  background: ${props =>
        (props.mode === 'neutral' &&
            props.theme.layout.section.neutral.background) ||
        (props.mode === 'dark' &&
            `linear-gradient(135deg, ${props.theme.layout.section.dark.linear} 0%, ${props.theme.layout.section.dark.background} 60%)`) ||
        (props.mode === 'light' && props.theme.layout.section.light.background) ||
        (props.mode === 'inverted' &&
            props.theme.layout.section.inverted.background) ||
        props.theme.layout.section.light.background};

  border: 1px solid
    ${props =>
        (props.mode === 'neutral' && 'transparent') ||
        (props.mode === 'dark' && 'transparent') ||
        (props.mode === 'light' &&
            props.border &&
            props.theme.layout.section.light.border) ||
        'none'};

  color: ${props =>
        (props.mode === 'neutral' &&
            props.theme.layout.section.neutral.textColor) ||
        (props.mode === 'dark' && props.theme.layout.section.dark.textColor) ||
        (props.mode === 'light' && props.theme.layout.section.light.textColor) ||
        (props.mode === 'inverted' &&
            props.theme.layout.section.inverted.textColor) ||
        props.theme.layout.section.light.textColor};
`;

export const Card = styled.div`
  align-items: ${props => (props.align === 'center' ? 'center' : 'flex-start')};
  background: ${props => props.theme.card.background};
  border: 1px solid ${props => props.theme.card.border};
  border-radius: ${props => props.theme.card.borderRadius};
  color: ${props => props.theme.card.textColor};
  display: flex;
  flex-direction: column;
  padding: 4rem;

  /* stylelint-disable-next-line */
  ${props =>
        props.align === 'center' &&
        css`
      p {
        text-align: center;
      }
    `}
`;

export const MainWrapper = styled.main`
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;

  ${TEMPLATE_MAIN_GRID};

  ${media.sm`
    align-items: flex-start;
    margin-right: 0;
  `}

  ${media.md`  
    margin-right: ${props => (props.template === 'webpage' ? 0 : 8)}rem;
  `}
`;

// FIXME: not layout related, but leave here for now since it's used as a selector
export const Paragraph = styled.p`
  color: ${props =>
        (props.color === 'medium' && props.theme.paragraph.medium) ||
        (props.color === 'light' && props.theme.paragraph.light) ||
        (props.light && props.theme.paragraph.light) ||
        props.theme.paragraph.dark};
  margin-bottom: ${props => (props.noMargin ? 0 : '2.4rem')};
`;

export const Section = styled.section`
  ${SECTION_MODE};
  padding: 6.4rem 4rem;
  position: relative;
  
  ${media.sm`
    display: grid;
    grid-gap: 0 ${props => props.theme.grid.gap.small}rem;
    grid-template-columns: 1fr;

    grid-column: ${props => props.fullWidth ? 'full' : 'content'};
    grid-template-columns: repeat(${props => props.theme.grid.column.large}, 1fr);
    padding: 6.4rem 0;

    ${props =>
            props.fullWidth &&
            css`
      grid-template-columns:
        [full-start] 
          minmax(4.8rem, 1fr)
        [content-start] 
          minmax(min-content, ${getAppWidth('webpage', 'xxl')}rem)
        [content-end] 
          minmax(4.8rem, 1fr)
        [full-end];
      `} 
  `};

  ${media.lg`
    grid-gap: 0 ${props => props.theme.grid.gap.large}rem;
    padding: 12rem 0;
  `}

  ${props =>
        props.mode === 'dark' &&
        css`
      & > ${Paragraph} {
        color: ${props.theme.paragraph.inverted};
      }
    `};

  ${props =>
        props.vAlign === 'center' &&
        css`
      align-items: center;
      display: flex;
      justify-content: center;
    `};

  ${props =>
        props.align === 'centered' &&
        css`
      align-items: center;
      display: flex;
      justify-content: center;
    `};
`;

export const SubSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  ${SECTION_MODE};

  ${media.sm`
    grid-column: span ${props =>
            (props.width === 'half' && props.theme.grid.column.large) ||
            (props.width === 'full' && props.theme.grid.column.large) ||
            1};
  `}

  ${media.md`
    grid-column: span ${props =>
            (props.width === 'half' && props.theme.grid.column.large / 2) ||
            (props.width === 'full' && props.theme.grid.column.large) ||
            1};
  `};
`;

// DevNote: SectionGrid is DEPRECATED. Cleanup to follow.

export const SectionGrid = styled.div`
  background-color: ${props =>
        (props.mode === 'neutral' &&
            props.theme.layout.section.neutral.background) ||
        (props.mode === 'dark' && props.theme.layout.section.dark.background) ||
        (props.mode === 'light' && props.theme.layout.section.light.background) ||
        props.theme.layout.section.light.background};

  color: ${props =>
        (props.mode === 'neutral' &&
            props.theme.layout.section.neutral.textColor) ||
        (props.mode === 'dark' && props.theme.layout.section.dark.textColor) ||
        (props.mode === 'light' && props.theme.layout.section.light.textColor) ||
        props.theme.layout.section.light.textColor};

  ${Paragraph} {
    color: ${props =>
        (props.mode === 'neutral' && props.theme.app.textColor) ||
        (props.mode === 'dark' && props.theme.app.textColor) ||
        (props.mode === 'light' && props.theme.app.textColor) ||
        props.theme.app.textColor};
  }

  ${props =>
        props.vAlign === 'center' &&
        css`
      align-items: center;
      display: flex;
      justify-content: center;
    `};

  ${props =>
        props.align === 'centered' &&
        css`
      align-items: center;
      display: flex;
      justify-content: center;
    `};
`;
