import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Uptick = (props, size) => (
  <Container {...props}>
    <Icon viewBox="0 0 18 10" size={size}>
      <path d="M15.3 1.58h-2.846V0h4.763c.438 0 .794.354.794.8v4.742h-1.588V2.698l-6.186 6.16c-.3.31-.813.31-1.123 0L5.696 5.465 1.123 10 0 8.882l5.135-5.094c.3-.31.813-.31 1.123 0L9.665 7.18l5.625-5.6z" stroke="none"/>
    </Icon>
  </Container>
);

export default Uptick;

