// English (EN)
import BuyDgx from '~/translations/en/buy-dgx.json';
import Compliance from '~/translations/en/compliance.json';
import Dashboard from '~/translations/en/dashboard.json';
import Deals from '~/translations/en/deals.json';
import DigixEcosystem from '~/translations/en/ecosystem.json';
import DigixStory from '~/translations/en/about.json';
import FileUploader from '~/translations/en/file-uploader.json';
import Footer from '~/translations/en/footer.json';
import Homepage from '~/translations/en/homepage.json';
import Kyc from '~/translations/en/kyc.json';
import KycStatus from '~/translations/en/kyc-status.json';
import Menu from '~/translations/en/menu.json';
import Partners from '~/translations/en/partners.json';
import PersonalDetails from '~/translations/en/personal-details.json';
import Portal from '~/translations/en/portal.json';
import Recast from '~/translations/en/recast.json';
import SellDgx from '~/translations/en/sell-dgx.json';
import Referral from '~/translations/en/referral.json';
import Transactions from '~/translations/en/transactions.json';
import Wallet from '~/translations/en/wallet.json';
import Promo3OffGold from '~/translations/en/3-off-gold.json';
import OurStory from '~/translations/en/ourstory.json';
import NotFound from '~/translations/en/404.json';
import SessionTimeout from '~/translations/en/session-timeout.json';
import PublicNotice from '~/translations/en/public-notice.json';

// Chinese (CN)
import BuyDgxCN from '~/translations/cn/buy-dgx.json';
import ComplianceCN from '~/translations/cn/compliance.json';
import DashboardCN from '~/translations/cn/dashboard.json';
import DealsCN from '~/translations/cn/deals.json';
import DigixEcosystemCN from '~/translations/cn/ecosystem.json';
import DigixStoryCN from '~/translations/cn/about.json';
import FileUploaderCN from '~/translations/cn/file-uploader.json';
import FooterCN from '~/translations/cn/footer.json';
import HomepageCN from '~/translations/cn/homepage.json';
import KycCN from '~/translations/cn/kyc.json';
import KycStatusCN from '~/translations/cn/kyc-status.json';
import MenuCN from '~/translations/cn/menu.json';
import PartnersCN from '~/translations/cn/partners.json';
import PersonalDetailsCN from '~/translations/cn/personal-details.json';
import PortalCN from '~/translations/cn/portal.json';
import RecastCN from '~/translations/cn/recast.json';
import SellDgxCN from '~/translations/cn/sell-dgx.json';
import ReferralCN from '~/translations/cn/referral.json';
import TransactionsCN from '~/translations/cn/transactions.json';
import WalletCN from '~/translations/cn/wallet.json';
import Promo3OffGoldCN from '~/translations/cn/3-off-gold.json';
import OurStoryCN from '~/translations/cn/ourstory.json';
import NotFoundCN from '~/translations/cn/404.json';
import SessionTimeoutCN from '~/translations/cn/session-timeout.json';
import PublicNoticeCN from '~/translations/cn/public-notice.json';

// Malay (MS)
import BuyDgxMS from '~/translations/ms/buy-dgx.json';
import ComplianceMS from '~/translations/ms/compliance.json';
import DashboardMS from '~/translations/ms/dashboard.json';
import DealsMS from '~/translations/ms/deals.json';
import DigixEcosystemMS from '~/translations/ms/ecosystem.json';
import DigixStoryMS from '~/translations/ms/about.json';
import FileUploaderMS from '~/translations/ms/file-uploader.json';
import FooterMS from '~/translations/ms/footer.json';
import HomepageMS from '~/translations/ms/homepage.json';
import KycMS from '~/translations/ms/kyc.json';
import KycStatusMS from '~/translations/ms/kyc-status.json';
import MenuMS from '~/translations/ms/menu.json';
import PartnersMS from '~/translations/ms/partners.json';
import PersonalDetailsMS from '~/translations/ms/personal-details.json';
import PortalMS from '~/translations/ms/portal.json';
import RecastMS from '~/translations/ms/recast.json';
import SellDgxMS from '~/translations/ms/sell-dgx.json';
import ReferralMS from '~/translations/ms/referral.json';
import TransactionsMS from '~/translations/ms/transactions.json';
import WalletMS from '~/translations/ms/wallet.json';
import Promo3OffGoldMS from '~/translations/ms/3-off-gold.json';
import OurStoryMS from '~/translations/ms/ourstory.json';
import NotFoundMS from '~/translations/ms/404.json';
import SessionTimeoutMS from '~/translations/ms/session-timeout.json';
import PublicNoticeMS from '~/translations/ms/public-notice.json';

// Indonesian (ID)
import BuyDgxID from '~/translations/id/buy-dgx.json';
import ComplianceID from '~/translations/id/compliance.json';
import DashboardID from '~/translations/id/dashboard.json';
import DealsID from '~/translations/id/deals.json';
import DigixEcosystemID from '~/translations/id/ecosystem.json';
import DigixStoryID from '~/translations/id/about.json';
import FileUploaderID from '~/translations/id/file-uploader.json';
import FooterID from '~/translations/id/footer.json';
import HomepageID from '~/translations/id/homepage.json';
import KycID from '~/translations/id/kyc.json';
import KycStatusID from '~/translations/id/kyc-status.json';
import MenuID from '~/translations/id/menu.json';
import PartnersID from '~/translations/id/partners.json';
import PersonalDetailsID from '~/translations/id/personal-details.json';
import PortalID from '~/translations/id/portal.json';
import RecastID from '~/translations/id/recast.json';
import SellDgxID from '~/translations/id/sell-dgx.json';
import ReferralID from '~/translations/id/referral.json';
import TransactionsID from '~/translations/id/transactions.json';
import WalletID from '~/translations/id/wallet.json';
import Promo3OffGoldID from '~/translations/id/3-off-gold.json';
import OurStoryID from '~/translations/id/ourstory.json';
import NotFoundID from '~/translations/id/404.json';
import SessionTimeoutID from '~/translations/id/session-timeout.json';
import PublicNoticeID from '~/translations/id/public-notice.json';

const translationsEN = {
  BuyDgx,
  Compliance,
  Dashboard,
  Deals,
  DigixStory,
  DigixEcosystem,
  FileUploader,
  Footer,
  Homepage,
  Kyc,
  KycStatus,
  Menu,
  Partners,
  Portal,
  Recast,
  SellDgx,
  Referral,
  Transactions,
  PersonalDetails,
  Wallet,
  Promo3OffGold,
  OurStory,
  NotFound,
  SessionTimeout,
  PublicNotice,
};

const translationsCN = {
  BuyDgx: BuyDgxCN,
  Compliance: ComplianceCN,
  Dashboard: DashboardCN,
  Deals: DealsCN,
  DigixStory: DigixStoryCN,
  DigixEcosystem: DigixEcosystemCN,
  FileUploader: FileUploaderCN,
  Footer: FooterCN,
  Homepage: HomepageCN,
  Kyc: KycCN,
  KycStatus: KycStatusCN,
  Menu: MenuCN,
  Partners: PartnersCN,
  Portal: PortalCN,
  Recast: RecastCN,
  Referral: ReferralCN,
  Transactions: TransactionsCN,
  PersonalDetails: PersonalDetailsCN,
  Wallet: WalletCN,
  Promo3OffGold: Promo3OffGoldCN,
  OurStory: OurStoryCN,
  NotFound: NotFoundCN,
  SessionTimeout: SessionTimeoutCN,
  PublicNotice: PublicNoticeCN,
};

const translationsMS = {
  BuyDgx: BuyDgxMS,
  Compliance: ComplianceMS,
  Dashboard: DashboardMS,
  Deals: DealsMS,
  DigixStory: DigixStoryMS,
  DigixEcosystem: DigixEcosystemMS,
  FileUploader: FileUploaderMS,
  Footer: FooterMS,
  Homepage: HomepageMS,
  Kyc: KycMS,
  KycStatus: KycStatusMS,
  Menu: MenuMS,
  Partners: PartnersMS,
  Portal: PortalMS,
  Recast: RecastMS,
  Referral: ReferralMS,
  Transactions: TransactionsMS,
  PersonalDetails: PersonalDetailsMS,
  Wallet: WalletMS,
  Promo3OffGold: Promo3OffGoldMS,
  OurStory: OurStoryMS,
  NotFound: NotFoundMS,
  SessionTimeout: SessionTimeoutMS,
  PublicNotice: PublicNoticeMS,
};

const translationsID = {
  BuyDgx: BuyDgxID,
  Compliance: ComplianceID,
  Dashboard: DashboardID,
  Deals: DealsID,
  DigixStory: DigixStoryID,
  DigixEcosystem: DigixEcosystemID,
  FileUploader: FileUploaderID,
  Footer: FooterID,
  Homepage: HomepageID,
  Kyc: KycID,
  KycStatus: KycStatusID,
  Menu: MenuID,
  Partners: PartnersID,
  Portal: PortalID,
  Recast: RecastID,
  Referral: ReferralID,
  Transactions: TransactionsID,
  PersonalDetails: PersonalDetailsID,
  Wallet: WalletID,
  Promo3OffGold: Promo3OffGoldID,
  OurStory: OurStoryID,
  NotFound: NotFoundID,
  SessionTimeout: SessionTimeoutID,
  PublicNotice: PublicNoticeID,
};

const translations = {
  en: translationsEN,
  cn: translationsCN,
  ms: translationsMS,
  id: translationsID,
};

export default translations;
