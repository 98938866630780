import { colors } from '@digix/electron/themes/light/colors';
import { composeInitialProps } from 'react-i18next';

const BOX_SHADOW_ON_DARK = `0px 5px 9px -2px ${colors.primary.base}`;
const BOX_SHADOW_ON_LIGHT = `0px 1px 2px ${colors.neutral.gray10}`;
const SITE_WIDTH = '1608px';
const DASHBOARD_WIDTH = '1336px';
const KYC_ADMIN_WIDTH = 160.8;

const SPACER_UNIT = '0.8';

export const BASE_BOX_SHADOW = `0px 2px 4px #f0f0f0`;
export const TRANSITION = '.3s ease-in-out';

export const spacer = {
  none: `calc(${SPACER_UNIT} * 0)`, // 0
  xxs: `calc(${SPACER_UNIT} * 0.5)`, // 4px
  xs: SPACER_UNIT, // 8px
  sm: `calc(${SPACER_UNIT} * 2)`, // 16px
  md: `calc(${SPACER_UNIT} * 3)`, // 24px
  lg: `calc(${SPACER_UNIT} * 4)`, // 32px
  xl: `calc(${SPACER_UNIT} * 5)`, // 40px
  xxl: `calc(${SPACER_UNIT} * 6)`, // 48px
  xxxl: `calc(${SPACER_UNIT} * 7)`, // 54px
};

export const input = {
  button: {
    contained: {
      primary: {
        background: colors.primary.base,
        borderColor: colors.primary.base,
        textColor: colors.neutral.white,
        icon: colors.neutral.white,
      },
      secondary: {
        background: colors.primary.light,
        borderColor: colors.primary.light,
        textColor: colors.neutral.white,
        icon: colors.neutral.white,
      },
      accent: {
        background: colors.accent.base,
        borderColor: colors.accent.base,
        textColor: colors.primary.base,
        icon: colors.primary.base,
      },
      disabled: {
        background: colors.neutral.light,
        borderColor: colors.neutral.light,
        textColor: colors.neutral.white,
        icon: colors.neutral.white,
      },
    },
    outlined: {
      primary: {
        background: 'transparent',
        borderColor: colors.primary.base,
        textColor: colors.primary.base,
      },
      secondary: {
        background: 'transparent',
        borderColor: colors.primary.light,
        textColor: colors.primary.light,
      },
      inverted: {
        background: 'transparent',
        borderColor: colors.neutral.white,
        textColor: colors.neutral.white,
      },
      error: {
        background: 'transparent',
        borderColor: colors.semantic.error,
        textColor: colors.semantic.error,
      },
      disabled: {
        background: colors.neutral.light,
        borderColor: colors.neutral.light,
      },
    },
    text: {
      default: {
        background: 'transparent',
        textColor: colors.neutral.darkGray60,
        border: 'transparent',
        icon: colors.neutral.darkGray60,
      },
      primary: {
        background: 'transparent',
        textColor: colors.primary.base,
      },
      secondary: {
        background: 'transparent',
        textColor: colors.primary.light,
      },
      active: {
        background: 'transparent',
        textColor: colors.primary.light,
        border: 'transparent',
        icon: colors.primary.light,
      },
      hover: {
        background: 'transparent',
        textColor: colors.primary.light,
        border: 'transparent',
        icon: colors.primary.light,
      },
      focus: {
        background: colors.primary.light,
        textColor: colors.neutral.white,
        border: colors.primary.light,
        icon: colors.primary.base,
      },
    },
    accent: {
      active: {
        background: colors.secondary.base,
        textColor: colors.primary.base,
        border: colors.secondary.base,
        icon: colors.primary.base,
      },
      hover: {
        background: colors.secondary.base,
        textColor: colors.primary.base,
        border: colors.secondary.base,
        icon: colors.primary.base,
      },
      focus: {
        background: colors.secondary.base,
        textColor: colors.primary.base,
        border: colors.secondary.base,
        icon: colors.primary.base,
      },
    },

    active: {
      background: colors.primary.base,
      fontColor: colors.neutral.white,
      borderColor: colors.primary.base,
    },
    hover: {
      background: colors.primary.light,
      fontColor: colors.neutral.white,
      borderColor: colors.primary.light,
    },
    focus: {
      background: colors.primary.light,
      fontColor: colors.neutral.white,
      borderColor: colors.primary.light,
    },
    disabled: {
      background: colors.neutral.light,
      fontColor: colors.neutral.white,
      borderColor: colors.neutral.light,
    },
    borderRadius: 0,
    boxShadow: 0,
    length: {
      xl: 22.4,
      lg: 15.2,
      md: 12.4,
      sm: 9.6,
      xs: 7.2,
    },
  },

  checkbox: {
    label: {
      base: colors.primary.base,
      default: colors.primary.light,
      active: colors.status.success,
      hover: colors.primary.light,
      disabled: colors.primary.light,
    },
    hover: {
      borderColor: colors.primary.light,
    },
    disabled: {
      borderColor: colors.primary.light,
    },
    selected: {
      background: colors.status.success,
      borderColor: colors.neutral.white,
    },
    unselected: {
      borderColor: colors.primary.light,
    },

    // TO DO: To be removed soon. DO NOT USE.
    background: colors,
    borderColor: colors,
  },

  fileUploader: {
    textColor: colors.neutral.darkGray60,
    background: colors.neutral.gray05,
    borderColor: colors.neutral.gray50,
  },

  radioButton: {
    active: {
      borderColor: colors.neutral.gray50,
      background: colors.neutral.white,
      icon: colors.neutral.white,
    },
    selected: {
      borderColor: colors.semantic.success,
      background: colors.semantic.success,
      icon: colors.neutral.white,
    },
  },

  select: {
    background: colors.neutral.white,
    border: {
      default: colors.neutral.gray50,
      focus: colors.neutral.gray80,
    },
    error: colors.semantic.error,
    highlight: colors.neutral.gray10,
    textColor: colors.neutral.darkGray100,
  },

  slider: {},
  stepper: {
    active: colors.semantic.success,
    background: colors.neutral.base,
    progress: {
      active: colors.semantic.success,
      default: colors.neutral.gray50,
      error: colors.semantic.error,
    },
    box: {
      background: colors.neutral.white,
    },
  },
  switch: {},

  textField: {
    normal: {
      background: colors.neutral.white,
      border: colors.neutral.gray50,
    },
    hover: {
      background: colors.neutral.white,
      border: colors.neutral.gray50,
    },
    focus: {
      background: colors.neutral.white,
      border: colors.neutral.gray50,
    },
    error: {
      background: colors.neutral.white,
      border: colors.semantic.error,
      color: colors.semantic.error,
    },
    disabled: {
      background: colors.neutral.gray30,
      border: colors.neutral.gray30,
      textColor: colors.neutral.gray100,
    },
    large: {
      border: colors.neutral.white,
    },

    borderRadius: 0,
    boxShadow: 'none',
    label: colors.neutral.light,
    textColor: colors.neutral.darkGray100,
  },
};

export const layout = {
  body: {
    background: colors.neutral.gray05,
    textColor: colors,
  },

  card: {
    background: colors.neutral.white,
    border: colors.neutral.white,
    borderRadius: 0,
    boxShadow: {
      onDark: BOX_SHADOW_ON_DARK,
      onLight: BOX_SHADOW_ON_LIGHT,
    },
    textColor: colors.neutral.darkGray100,
  },

  container: {
    width: {
      website: SITE_WIDTH,
      dashboard: DASHBOARD_WIDTH,
    },
  },

  footer: {
    primary: colors.primary.base,
    secondary: colors.neutral.gray100,
    textColor: colors.neutral.white,
  },

  grid: {
    gap: {
      large: 2.4,
      small: 1.6,
    },
    column: {
      large: 12,
      medium: 12,
      small: 4,
    },
  },

  header: {
    background: colors.neutral.white,
  },

  nav: {
    background: colors.neutral.white,
    hoverTextColor: colors.primary.light,
    textColor: colors.primary.base,

    dropdown: {
      background: colors.primary.base,
      hoverTextColor: colors.primary.light,
      textColor: colors.neutral.white,
    },
    primary: {
      active: colors.primary.base,
      hover: colors.primary.light,
    },
    inverted: {
      active: colors.neutral.white,
      hover: colors.neutral.white,
    }
  },

  langSelector: {
    normal: {
      background: colors.neutral.white,
      selectedText: colors.primary.base,
      options: colors.primary.base,
      optionsHover: colors.primary.light,
      optionsText: colors.neutral.white,
      icon: colors.primary.base,
      iconHover: colors.primary.light,
    },
    inverted: {
      background: 'transparent',
      selectedText: colors.neutral.white,
      options: colors.primary.light,
      optionsHover: colors.primary.light,
      optionsText: colors.neutral.white,
      icon: colors.neutral.white,
    },
  },

  section: {
    light: {
      background: colors.neutral.white,
      textColor: colors.primary.base,
      border: colors.neutral.gray20,
      boxShadow: BOX_SHADOW_ON_LIGHT,
    },
    dark: {
      background: colors.primary.base,
      linear: colors.primary.lighter,
      textColor: colors.neutral.white,
    },
    neutral: {
      background: colors.neutral.gray05,
      textColor: colors.primary.base,
    },
    accent: {
      background: colors.accent.base,
    },
    inverted: {
      background: 'transparent',
      textColor: colors.neutral.white,
    },

    sizes: {
      sm: '5rem 5rem',
      md: '10rem 10rem',
      lg: '12rem 16rem',
    },
  },

  sidenav: {
    active: colors.accent.base,
    background: colors.primary.base,
    hoverColor: colors.accent.base,
    textColor: colors.neutral.white,
  },

  table: {
    borderColor: colors.neutral.gray10,
    header: {
      background: 'transparent',
      backgroundSecondary: colors.neutral.gray05,
      backgroundAlt: colors.neutral.white,
      textColor: colors.neutral.darkGray100,
      border: colors.primary.light,
    },
    body: {
      background: 'transparent',
      backgroundAlt: colors.neutral.white,
      backgroundAccent: colors.neutral.gray05,
      textColor: colors.neutral.darkGray100,
      border: colors.primary.light,
    },
    hover: {
      background: colors.neutral.gray03,
    },
  },

  tabs: {
    boxShadow: BOX_SHADOW_ON_LIGHT,
    active: {
      textColor: colors.primary.light,
      border: colors.primary.light,
      background: 'transparent',
      mobile: {
        textColor: colors.primary.base
      }
    },
    inactive: {
      textColor: colors.neutral.gray50,
      border: colors.primary.light,
      background: 'transparent',
    },
  },

  divider: {
    normal: colors.neutral.gray30,
    accent: colors.accent.base,
  },

  notification: {
    error: {
      background: colors.neutral.white,
      fontColor: colors.semantic.error,
    },
    warning: {
      background: colors.neutral.white,
      fontColor: colors.semantic.warning,
    },
    success: {
      background: colors.neutral.white,
      fontColor: colors.semantic.success,
    },
    info: {
      background: colors.neutral.white,
      fontColor: colors.semantic.info,
    },

    // TODO: To be removed soon. Do not use.
    background: colors,
  },

  // Components' z-index levels
  zindex: {
    error: 0,
    dropdown: 0,
    footer: 0,
    header: 998,
    loader: 997,
    modal: 0,
    sidebar: 9999,  // mobile menu & dashboard sidebar
    snackbar: 999,
  },
};

export const ui = {
  app: {
    textColor: colors.neutral.darkGray100,
    inverted: colors.neutral.darkGray100,
    webpage: {
      width: SITE_WIDTH,
      sm: 76.8,
      md: 92.4,
      lg: 160.8,
      xl: 160.8,
      xxl: 160.8,
    },
    dashboard: {
      width: DASHBOARD_WIDTH,
      sm: 76.8,
      md: 76.8,
      lg: 76.8,
      xl: 102.4,
      xxl: 136,
    },
    admin: {
      width: KYC_ADMIN_WIDTH,
      sm: 76.8,
      md: 136,
      lg: 160.8,
      xl: 160.8,
      xxl: 160.8,
    },
  },
  shape: {
    primary: colors.primary.base,
    secondary: colors.primary.light,
    accent: colors.accent.base,
    neutral: colors.neutral.gray05_rgb,
    inverted: colors.neutral.white,
  },
  icon: {
    primary: colors.primary.base,
    secondary: colors.primary.light,
    inverted: colors.neutral.white,
    accent: colors.accent.base,
    disabled: colors.neutral.gray50,

    active: colors.primary.base,
    inactive: colors.primary.base,

    ...colors.semantic,

    size: {
      xsmall: 0.8,
      small: 1.2,
      medium: 1.6,
      large: 2.4,
      xlarge: 3.2,
    },
  },

  heading: {
    primary: colors.primary.base,
    secondary: colors.primary.light,
    tertiary: colors.neutral.darkGray100,
    accent: colors.accent.base,
    inverted: colors.neutral.white,
  },

  label: {
    normal: colors.neutral.darkGray100,
    focus: colors.neutral.gray50,
    disabled: colors.neutral.gray50,
    muted: colors.semantic.muted,
    error: colors.semantic.error,

    large: {
      color: colors.primary.base,
    },
  },

  link: {
    primary: {
      active: colors.primary.base,
      hover: colors.primary.light,
    },
    secondary: {
      active: colors.primary.light,
      hover: colors.primary.light,
    },
    inverted: {
      active: colors.neutral.white,
      hover: colors.neutral.white,
    },
    accent: {
      active: colors.accent.base,
      hover: colors.accent.base,
    },
    muted: {
      active: colors.semantic.muted,
      hover: colors.semantic.muted,
    },

    // For deletion.
    textColor: colors,
  },

  pagination: {
    background: {
      active: colors.primary.light,
      default: colors.neutral.white,
    },
    textColor: {
      active: colors.neutral.white,
      default: colors.neutral.darkGray100,
    },
  },

  paragraph: {
    dark: colors.neutral.darkGray100,
    medium: colors.neutral.gray80,
    light: colors.neutral.gray50,
    ligher: colors.neutral.gray20,
    inverted: colors.neutral.white,
  },

  pdfViewer: {
    background: colors.neutral.base,
  },

  progress: {
    active: colors.semantic.success,
    background: colors.neutral.base,
  },

  snackbar: {
    background: colors.primary.base,
    heading: colors.primary.base,
    text: colors.primary.base,
  },

  status: {
    success: colors.semantic.success,
    error: colors.semantic.error,
    warning: colors.semantic.warning,
    info: colors.semantic.info,
    textColor: colors.neutral.white,
    button: colors.primary.light,
    dropped: colors.semantic.info,
  },

  tooltip: {},
};

export const portal = {
  left: {
    background: colors.primary.base,
    paragraphColor: colors.neutral.white
  },
  right: {
    paragraphColor: colors.neutral.darkGray100
  }
}

export const snackbar = {
  success: {
    background: colors.semantic.success,
    color: colors.neutral.white
  },
  warning: {
    background: colors.neutral.darkGray100,
    color: colors.neutral.white
  },
  error: {
    background: colors.semantic.error,
    color: colors.neutral.white
  }
};

export const loader = {
  backgroundColor: colors.neutral.gray05_rgb,
  svg: {
    primary: colors.neutral.darkGray100,
    inverted: colors.neutral.white,
  },
  flat: {
    dark: colors.neutral.gray07, // #D8D8D8
    normal: colors.neutral.gray06, // #E1E1E2
    light: colors.neutral.gray04, // #F7F7F7
  }


};
