
import { css } from 'styled-components';

export const reset = css`
  *,
  *::after,
  *::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  *[hidden] {
    display: none; 
  }

  html {
    font-size: 62.5%;
  }

  html,
  body {
    height: 100%;
    min-height: 100vh;
  }

  
  body {
    box-sizing: border-box;
    overflow-x: hidden;
    position: relative;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section {
    display: block;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  input,
  select,
  button,
  textarea {
    outline: none;
    -webkit-appearance: none;
  }

  img {
    border-style: none;
    display: block;
    height: 100%;
    width: 100%;
  }

  ol,
  ul {
    list-style: none;
  }

  li {
    line-height: initial;
  }

  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    background-color: transparent;
    text-decoration: none;
  }

  p {
    margin-bottom: 2.4rem;
  }

  em {
    font-style: italic;
  }
`;