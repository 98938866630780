import { Alert, Icon } from '@digix/electron/shared';
import styled, { css } from 'styled-components';

const focusedInput = css`
  border: 1px solid ${props => (props.variant === 'outlined')
    ? 'var(--border-color)'
    : 'transparent'
  };
  outline: 0;
`;

const focusedValidInput = css`
  border-top: 1px solid transparent;
`;

const focusedLabelBeforeAfter = css`
  border-top-color: transparent;
  /* stylelint-disable-next-line */
  border-color: ${props => (props.invalid)
    ? 'var(--error)'
    : 'var(--border-color)'
  };
`;

const focusedLabelSpan = css`
  --color: var(--label-color);
  color: ${props => props.invalid
    ? 'var(--error)'
    : 'var(--color)'};
  font-size: var(--font-size-focus);
  line-height: 1.6rem;
  transform: translateY(-1rem) scale(1);
`;

const TextField = styled.input`
  background: ${props => props.disabled ? 'var(--disabled)' : 'var(--background)'};
  border: 0;
  border: 1px solid
    ${props => (props.variant === 'outlined' ? 'var(--border-color)' : 'transparent')};
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  color: ${props =>
    (props.disabled || props.readonly) ? 'var(--text-muted)' : 'var(--color)'};
  display: inline-block;
  font-family: var(--font-family);
  font-size: var(--font-size);
  height: 4.8rem;
  overflow-x: hidden;
  padding: 0 1.6rem;
  -webkit-tap-highlight-color: transparent;
  transition: border 0.2s;
  width: 100%;

  &:invalid {
    ${props => props.value || props.defaultValue ? 'border-top-color: transparent' : ''}
  }

  &:focus,
  &:valid {
    ${focusedValidInput}
  }

  &[readonly], &[readonly="readonly"] {
    --color: var(--text-muted);
    color: var(--color);
  }

  /* stylelint-disable-next-line */
  ${props => props.value || props.defaultValue
    ? `${focusedInput} ${focusedValidInput}`
    : null
  }

  ${props =>
    props.invalid &&
    css`
      border: 1px solid var(--error);
  `}
`;

const Label = styled.label`
  cursor: text;
  display: flex;
  height: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 1;

  &::before,
  &::after {
    border-top: solid 1px;
    border-top-color: transparent;
    border-width: 1px;
    content: '';
    display: block;
    height: 0.1rem;
    margin-top: 0;
    min-width: 1.6rem;
    pointer-events: none;
    position: relative;
    transition: border-color 0.05s, box-shadow 0.2s;
  }

  &::before {
    border-left: solid 1px transparent;
    margin-right: 0.8rem;
  }

  &::after {
    border-right: solid 1px transparent;
    flex-grow: 1;
    left: 0.2rem;
    margin-left: 0.8rem;
    margin-right: 0.2rem;
  }

  span {
    color: ${props => props.disabled ? 'var(--text-muted)' : 'var(--color)'};
    font-size: var(--font-size);
    left: 1.6rem;
    line-height: 4.8rem;
    margin: 0;
    transition: transform 0.2s, color 0.2s, font-size 0.2s, line-height 0.2s;
  }

  /* stylelint-disable-next-line */
  ${props =>
    props.size === 'large' &&
    css`
      --color: var(--color-primary);
      color: var(--color);
      font-size: var(--font-size);
    `}
`;

export const Input = {
  Wrapper: styled.div`
    background: transparent;
    display: inline-block;
    position: relative;
    transition: border 0.2s;
    width: 100%;
  `,

  TextField: styled(TextField)`
    /* stylelint-disable-next-line */
    &:focus + ${Label}, &:valid + ${Label} {
      &::before,
      &::after {
        ${focusedLabelBeforeAfter};
      }

      & > span {
        ${focusedLabelSpan};
      }
    }

    & + ${Label} {
      &::before,
      &::after {
        box-sizing: content-box;
        ${props => props.value || props.defaultValue
      ? focusedLabelBeforeAfter
      : ''}
      }

      & > span {
        ${props => props.value || props.defaultValue
      ? focusedLabelSpan
      : ''}
      }
    }
  `,

  Label,

  ErrorMessage: styled(Alert)`
    background: transparent;
    padding: 0;
    position: absolute;
  `,

  InputIcon: styled(Icon)`
    position: absolute;
    right: 2rem;
    top: 0.8rem;

    ${props => props.isClickable && css`
      cursor: pointer;
    `}
  `,
};
