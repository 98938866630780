import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Heart = props => (
  <Container {...props}>
    <Icon viewBox="0 0 16 15">
      <g stroke="none" stokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-697.000000, -131.000000)" stroke="#436ab1">
          <path d="M705.01792,133.663186 C702.018826,130.71883 697.598808,132.045303 698.029218,136.383968 C698.393436,140.05446 701.939867,143.005185 704.975921,144.908108 L704.999441,144.923077 L705.023296,144.908108 C708.05935,143.005185 711.605445,140.05446 711.969664,136.383968 C712.40041,132.045303 708.150741,130.598126 705.151646,133.5428 L705.01792,133.663186 Z" />
        </g>
      </g>
    </Icon>
  </Container>
);

export default Heart;
