import React from 'react';
import PropTypes from 'prop-types';
import { Outlined } from '@digix/electron/shared/button/outlined/styles';
import { Icon, Loader } from '@digix/electron/shared';

const OutlinedButton = ({ hasIcon, isLoading, text, ...props }) => (
  <Outlined {...props}>
    {isLoading && <Loader kind="simple" color="inverted" />}
    {!isLoading && props.children}
    {!isLoading && (text && text)}
    {!isLoading && (hasIcon && <Icon name="arrow" />)}
  </Outlined>
);

const { object, string, node, oneOfType } = PropTypes;

OutlinedButton.propTypes = {
  children: oneOfType([object, string, node]),
};

export default OutlinedButton;
