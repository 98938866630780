import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const SellDgx = props => (
    <Container {...props}>
        <Icon viewBox="0 0 24 23">
            <g stroke-width=".7" fill="none" fill-rule="evenodd">
                <path d="m10.1 1 1.622 1.618L10.1 4.235" />
                <path d="M1.34 5.207a2.6 2.6 0 0 1 2.595-2.59h7.786M13.024 22.486l-1.69-1.618 1.69-1.617" />
                <path d="M22.156 18.279c0 1.424-1.218 2.59-2.705 2.59h-8.118M12.179 17.589V5.77M18.294 11.92H6.45M10.312 6.31c-.016.02-1.164 1.543-1.164 5.37 0 3.855 1.152 5.355 1.164 5.37 0 0 .2.318.302.45M13.588 5.69l.223.38c.051.064 1.247 1.62 1.247 5.61 0 3.988-1.196 5.544-1.247 5.609l-.209.281" />
                <path d="M12.372 17.782c-3.373 0-6.116-2.737-6.116-6.103 0-3.365 2.743-6.102 6.116-6.102 3.373 0 6.116 2.737 6.116 6.102 0 3.366-2.743 6.103-6.116 6.103Z" />
                <path d="M17.962 14.039c-.064.05-1.624 1.243-5.622 1.243-3.997 0-5.522-1.193-5.585-1.244M6.763 9.305c.063-.05 1.58-1.212 5.578-1.212 3.997 0 5.557 1.192 5.621 1.244M10.295 6.33l.363-.509" />
            </g>
        </Icon>
    </Container>
);

export default SellDgx;
