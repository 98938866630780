import { CheckBox } from '@digix/electron/shared';
import React, { useEffect, useState } from 'react';
import { NavDropdown } from '@digix/electron/shared/checklist-dropdown/styles';
import {
  array,
  bool,
  func,
  string,
} from 'prop-types';

const {
  Container,
  List,
  Option,
  SelectedOption,
} = NavDropdown;

const ChecklistDropdown = ({
  defaultSelected,
  forceCollapse, // force hide the dropdown
  options,
  placeholder,
  saveSelectedOptions,
}) => {
  let initialOptionValues = {};
  options.forEach(({ value }) => initialOptionValues = {
    ...initialOptionValues,
    [value]: defaultSelected.includes(value),
  });

  const [showDropdown, setShowDropdown] = useState(false);
  const [optionValues, setOptionValues] = useState(initialOptionValues);

  const selectOption = (value) => {
    const newOptionValues = {
      ...optionValues,
      [value]: !optionValues[value],
    };

    const selectedOptions = Object.keys(newOptionValues).filter((key) => !!newOptionValues[key]);
    setOptionValues(newOptionValues);
    saveSelectedOptions(selectedOptions);
  }

  useEffect(() => {
    if (forceCollapse) {
      setShowDropdown(false);
    }

  }, [forceCollapse]);

  return (
    <Container data-digix="ChecklistDropdown-Option">
      <SelectedOption
        hasIcon
        icon="angle"
        onClick={() => setShowDropdown(!showDropdown)}
        text={placeholder}
        variant="text"
      >
      </SelectedOption>
      <List show={showDropdown}>
        {options.map(({ name, value }) => (
          <Option
            key={value}
            data-digix={value}
          >
            <CheckBox
              id={value}
              isChecked={optionValues[value]}
              isMultiOptions
              key={value}
              name={`${placeholder}-${name}`}
              onChange={() => selectOption(value)}
              text={name}
              value={value}
            />
          </Option>
        ))}
      </List>
    </Container>
  );
};

ChecklistDropdown.propTypes = {
  defaultSelected: array,
  options: array.isRequired,
  placeholder: string,
  forceCollapse: bool,
  saveSelectedOptions: func.isRequired,
};

ChecklistDropdown.defaultProps = {
  defaultSelected: [],
  forceCollapse: false,
  placeholder: 'Select an option',
};

export default ChecklistDropdown;
