import styled from 'styled-components';
import { Input } from '@digix/electron/shared/textfield/styles';
import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';

const {
  ErrorMessage,
  InputIcon,
  Label,
  TextField: InputTextField,
  Wrapper,
} = Input;

const TextField = styled(props => {
  const {
    autoFocus,
    disabled,
    error,
    icon,
    invalid,
    label,
    name,
    onChange,
    readOnly,
    required,
    size,
    type,
    variant,
    'data-digix': dataDigix, // Separation here
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  let input = null;

  const handleLabelClick = input => input.focus();

  const getOptionalAttributes = () => {
    const optionalAttributes = {};
    const fields = [
      'defaultValue',
      'id',
      'name',
      'onBlur',
      'readOnly',
      'value',
    ];

    fields.forEach(key => {
      if (![null, undefined].includes(props[key])) {
        optionalAttributes[key] = props[key];
      }
    });

    return optionalAttributes;
  };

  return (
    <Wrapper {...props} data-digix={dataDigix}>
      <InputTextField
        autoFocus={autoFocus}
        autoComplete="off"
        onChange={onChange}
        invalid={invalid}
        readonly={readOnly}
        size={size}
        type={type === 'password' && isPasswordVisible ? 'text' : type}
        variant={variant}
        required={required}
        ref={element => input = element}
        {...getOptionalAttributes()}
      />
      {label && (
        <Label
          htmlFor={name}
          invalid={invalid}
          onClick={() => handleLabelClick(input)}
          size={size}
        >
          <span>{label}</span>
        </Label>
      )}
      {type === 'password' && (
        <InputIcon
          isClickable
          name={isPasswordVisible ? 'eyesOpen' : 'eyesClosed'}
          onClick={() => setIsPasswordVisible(value => !value)}
          size="large"
        />
      )}
      {icon && (
        <InputIcon
          name={icon}
          size="large"
        />
      )}
      {invalid && error.length > 0 && (
        <ErrorMessage kind="error" role="alert">
          {error}
        </ErrorMessage>
      )}
    </Wrapper>
  )
})``;

TextField.propTypes = {
  autoFocus: bool,
  defaultValue: string,
  disabled: bool,
  error: string,
  id: string,
  icon: string,
  invalid: bool,
  name: string,
  onChange: func,
  readOnly: bool,
  required: bool,
  size: string,
  type: string,
  value: string,
  variant: string,
};

TextField.defaultProps = {
  disabled: false,
  error: '',
  icon: undefined,
  invalid: false,
  onChange: () => { },
  required: false,
};

export default TextField;
