import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Edit = props => (
  <Container {...props}>
    <Icon viewBox="0 0 11 11">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-798 -219)">
          <g transform="translate(799 220)">
            <polyline points="2 8.0018 1.4918 8.4883 -7.276e-12 9 0.34023 7.5785 0.97819 7" />
            <polygon points="7.7201 0 9 1.2263 2.2192 8 1 6.7464" />
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

export default Edit;
