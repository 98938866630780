export const actions = {
  LOGOUT: 'LOGOUT',
  SET_ACCOUNT_ACTION: 'SET_ACCOUNT_ACTION',
  SHOW_SESSION_TIMEOUT_WARNING: 'SHOW_SESSION_TIMEOUT_WARNING',
  UPDATE_BALANCES: 'UPDATE_BALANCES',
};

export const resetReduxState = () => ({
  payload: {},
  type: actions.LOGOUT,
});

export const updateBalances = (balances) => ({
  payload: balances,
  type: actions.UPDATE_BALANCES,
});

export const showSessionTimeoutWarning = (isShown) => ({
  payload: isShown,
  type: actions.SHOW_SESSION_TIMEOUT_WARNING,
});

export const setAccountAction = action => ({
  payload: action,
  type: actions.SET_ACCOUNT_ACTION,
});
