/*
 * A generic pagination component. Sample usage of this can be found in
 * src/electron/shared/table/index.js
 */

import Page from '@digix/electron/shared/pagination/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }

  componentDidUpdate(prevProps) {
    const { fetchData, isFilterChanged } = this.props;
    if (
      fetchData !== prevProps.fetchData ||
      isFilterChanged && (isFilterChanged !== prevProps.isFilterChanged)
    ) {
      this.setState({ currentPage: 1 });
    }
  }

  setErrorMessage = (msg) => {
    const { fetchErrorMsg, setError, setLoading } = this.props;
    const error = msg || fetchErrorMsg;

    setLoading(false);
    setError(error);
  }

  setNewData = (data, currentPage) => {
    const { setData, setLoading } = this.props;

    setData(data);
    setLoading(false);
    this.setState({ currentPage });
  }

  setPage(currentPage) {
    const {
      fetchData,
      setError,
      setLoading,
    } = this.props;

    setError(false);
    setLoading(true);

    fetchData(currentPage)
      .then(data => {
        this.setNewData(data, currentPage);
      })
      .catch(this.setErrorMessage);
  }

  renderPageButton = (content, pageToSet, dataDigixName) => {
    const name = dataDigixName || pageToSet;
    return (
      <Page.Item
        corner="light"
        data-digix={`Pagination-${name}`}
        onClick={() => this.setPage(pageToSet)}
        variant="icon"
      >
        {content}
      </Page.Item>
    );
  }

  render() {
    const { currentPage } = this.state;

    return (
      <Page.List>
        {currentPage > 2 && this.renderPageButton(`<`, currentPage - 1, 'Previous')}
        {currentPage > 1 && this.renderPageButton(currentPage - 1, currentPage - 1)}
        <Page.Item active kind="secondary" corner="light">
          {currentPage}
        </Page.Item>
        {this.renderPageButton(currentPage + 1, currentPage + 1)}
        {currentPage === 1 && this.renderPageButton(3, 3)}
        {this.renderPageButton(`>`, currentPage + 1, 'Next')}
      </Page.List>
    );
  }
}

const { bool, func, string } = PropTypes;
Pagination.propTypes = {
  fetchData: func.isRequired,
  fetchErrorMsg: string,
  isFilterChanged: bool,
  setData: func.isRequired,
  setError: func.isRequired,
  setLoading: func.isRequired,
};

Pagination.defaultProps = {
  fetchErrorMsg: 'Unable to fetch data.',
  isFilterChanged: false,
};

export default Pagination;
