import styled, { css } from 'styled-components';
import { Button } from '@digix/electron/shared/button/styles';

export const IconBtn = styled(Button)`
  --background: transparent;
  --disabled: var(--background);
  --height: auto;
  --min-width: auto;
  --width: ${props =>
    (props.size === 'large' && 1.6)
    || (props.size === 'medium' && 1.2)
    || (props.size === 'small' && 0.8)
    || 1.6}rem;
  border: 0;
  margin: var(--margin-top, 0) var(--margin-right, 0) var(--margin-bottom, 0) var(--margin-left, 0);
  padding: var(--padding-top, 0) var(--padding-right, 0) var(--padding-bottom, 0) var(--padding-left, 0);

  > div {
    margin: 0;
  }

  div svg {
    --disabled: var(--text-muted);
    --fill: ${props => props.icon === 'close' ? 'var(--color-inverse)' : 'none'};
    --stroke: ${props => props.accent ? 'var(--color-accent)' : 'var(--color-secondary)'};
  }
`;
