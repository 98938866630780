import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Support = props => (
  <Container {...props}>
    <Icon viewBox="0 0 22 22">
      <path d="M18.682 13.35h1.87V1H1v12.35h2.584l1.562 2.8 1.83-2.8h2.56"/>
      <text>
        <tspan x="10.665" y="21.332">?</tspan>
      </text>
      <path d="M4.383 4.343h11.724M4.383 7.72H7.83"/>
    </Icon>
  </Container>
);

export default Support;
