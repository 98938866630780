import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Brand = props => (
  <Container {...props}>
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31 30">
      <path d="M15.5 14.5l7.9 7.9-7.9 7.4-7.9-7.4 7.9-7.9zm0-14.5L31 15.2l-5.6 5.5-9.9-9.7-9.9 9.7L0 15.2 15.5 0z" />
    </Icon>
  </Container>
);

Brand.defaultProps = {
  width: '',
  height: '',
};

export default Brand;
