/*
 * This stylesheet exports the Select styled components, which are used for
 * generic dropdown elements in forms.
 *
 * =============================================================================
 * The hierarchy for composing a Select component is as follows:
 *
 * Fragment (div)
 *   Label (span)
 *   StyledSelect (select)
 *     options
 *
 * =============================================================================
 * The following are the available props for certain components:
 *
 * StyledSelect
 *   hasError: boolean; if true, wraps the Select componen in a red border
 *   noBorder: boolean; if true, removes border
 *   width: string; defaults to 25rem
 *
 * Label
 *   hasError: boolean; if true, colors the label typeface in red
 *
 */

import styled from 'styled-components';
import { Alert } from '@digix/electron/shared';

export const StyledSelect = styled.select`
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 306 306'%3E%3Cpath fill='%23464E5B' d='M0,94.3l35.7-35.7L153,175.9L270.3,58.7L306,94.3l-153,153L0,94.3z' /%3E%3C/svg%3E");
  background-position: right 1rem top 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 1rem auto, 100%;

  border: 1px solid ${props => (
    props.hasError ? props.theme.select.error : props.theme.select.border.default
  )};

  border-radius: 0;
  border-width: ${props => props.noBorder ? '0px' : '1px'};
  cursor: pointer;
  font-family: ${props => (props.theme.typography.typeface.primary.regular)};
  font-size: ${props => (props.theme.typography.fontSize.md)};
  height: 4.8rem;
  padding-left: 2rem;
  position: relative;
  width: ${props => (props.width ? props.width : '25rem')};

  &:focus {
    border: 1px solid ${props => props.hasError ? props.theme.select.error : props.theme.select.border.focus};
    border-width: ${props => props.noBorder ? '0px' : '1px'};
    outline: none;
  }

  option {
    width: ${props => (props.width ? props.width : '25rem')};
  }
`;

export const Label = styled.span`
  background: ${props => (props.theme.select.background)};
  color: ${props => (
    props.hasError ? props.theme.select.error : props.theme.label.focus
  )};
  font-family: ${props => (props.theme.typography.typeface.primary.regular)};
  font-size: ${props => (props.theme.typography.fontSize.sm)};
  left: 1rem;
  padding: 0 0.5rem;
  position: relative;
  top: 0.8rem;
  z-index: 10;
`;

export const AlertMessage = styled(Alert)`
  position: absolute;
`;