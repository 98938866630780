import React, { useState, useEffect } from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';
import lightTheme from '@digix/electron/themes/light';

const LETTERS = ['A', 'Z'];
const TIME = ['Old', 'New'];
const COLOR = lightTheme.colors;

const Sort = (props) => {
  const { active, sortBy, sortOrder } = props;
  const arrowXTranslate = sortBy === 'letters' ? 12 : 28;
  const iconColor = active
    ? COLOR.primary.light
    : COLOR.neutral.black;

  const [sortStart, setSortStart] = useState(TIME[0]);
  const [sortEnd, setSortEnd] = useState(TIME[1]);

  useEffect(() => {
    let sortKey = sortBy === 'letters' ? LETTERS : TIME;
    if (sortOrder === 'DESC') {
      sortKey = sortKey.slice().reverse();
    }

    setSortStart(sortKey[0]);
    setSortEnd(sortKey[1]);
  }, [sortBy, sortOrder]);

  return (
    <Container {...props}>
      <Icon viewBox="0 0 32 34">
        <g stroke="none" strokeWidth="1" fill="none">
          <g transform="translate(-1060, -635)">
            <g transform="translate(1060, 635)">
              <text
                fontFamily="FuturaPT-Book, Futura PT"
                fontSize={12}
                fontWeight={500}
                fill={iconColor}
              >
                <tspan x="0" y="10">
                  {sortStart}
                </tspan>
              </text>
              <text
                fontFamily="FuturaPT-Book, Futura PT"
                fontSize={12}
                fontWeight={500}
                fill={iconColor}
              >
                <tspan x="0" y="25">
                  {sortEnd}
                </tspan>
              </text>
              <g transform={`translate(${arrowXTranslate}, 4)`} stroke={iconColor}>
                <g transform="translate(0.5, 0)">
                  <line x1="2.5" y1="0" x2="2.5" y2="17"></line>
                  <polyline points="0 15 2.5 17 5 15"></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </Icon>
    </Container>
  )
};

Sort.defaultProps = {
  width: '',
  height: '',
};

export default Sort;
