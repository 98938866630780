import React, { PropTypes } from 'react';

import { H1, H2, H3, H4, H5, H6 } from '@digix/electron/shared/heading/styles';

const Heading = ({ level, children, ...props }) =>
  (level === '1' && <H1 {...props}>{children}</H1>) ||
  (level === '2' && <H2 {...props}>{children}</H2>) ||
  (level === '3' && <H3 {...props}>{children}</H3>) ||
  (level === '4' && <H4 {...props}>{children}</H4>) ||
  (level === '5' && <H5 {...props}>{children}</H5>) ||
  (level === '6' && <H6 {...props}>{children}</H6>) ||
  (level > 6 && <H6>{children}</H6>);

export default Heading;
