import { Select } from '@digix/electron/shared';
import StyledDatePicker from '@digix/electron/shared/date-picker/styles';
import { getAllMonths } from '@digix/electron/shared/utils/dates';
import React, { Fragment } from 'react';
import {
  bool,
  func,
  instanceOf,
  number,
  shape,
  string,
} from 'prop-types';

const { DateWrapper, ErrorMessage, Label } = StyledDatePicker;

const DEFAULT_DATE = '0000-00-00';
const DEFAULT_MAX_YEAR_OFFSET = 50;
const DEFAULT_MIN_YEAR = new Date().getFullYear();

const validateDate = (year = 0, month = 0, day = 0, minDate, isFuture) => {
  const date = [year, month, day];
  const isValid = !!(+year && +month && +day);
  const isEmpty = date.reduce((a, b) => (+a) + (+b)) === 0;
  let isExpired = false;
  if (isValid && minDate) {
    const dateValue = new Date(+year, +month - 1, +day).getTime();
    isExpired = isFuture
      ? dateValue <= minDate.getTime()
      : dateValue > minDate.getTime();
  }
  return {
    date: date.join('-'),
    isEmpty,
    isValid,
    isExpired,
  };
};

const DatePicker = ({
  errorMessage,
  isAbbreviated,
  isDisabled,
  isInvalid,
  label,
  locale,
  maxYearOffset,
  minDate,
  minYear,
  noValidation,
  nullable,
  setDate,
  showFutureDates,
  value,
  width,
}) => {
  const date = (value.date || DEFAULT_DATE).split('-');
  const year = date[0];
  const month = date[1];
  const day = date[2];
  const dateValidation = validateDate(year, month, day, minDate, showFutureDates);
  const isDateValid = dateValidation.isValid;
  const isDateEmpty = dateValidation.isEmpty;
  const isExpired = dateValidation.isExpired;
  const hasError = noValidation ? isInvalid : (isExpired || (!isDateEmpty && (!isDateValid || isInvalid)));
  const isInvalidDate = nullable && hasError && !isDateEmpty && (!isDateValid || isInvalid);

  const handleSetDate = ({ target }) => {
    const { name, value } = target;
    const fieldIndex = ['year', 'month', 'day'].indexOf(name);
    const dateSelected = date.map((field, i) => i === fieldIndex ? value : field);
    if (noValidation) {
      return setDate({
        date: dateSelected.join('-'),
        valid: true,
      });
    }
    const { isValid, isEmpty, isExpired } = validateDate(dateSelected[0], dateSelected[1], dateSelected[2], minDate, showFutureDates);
    return setDate({
      date: dateSelected.join('-'),
      valid: ((!nullable && isValid && !isExpired)
        || (nullable && isValid && !isExpired)
        || (nullable && isEmpty)) && !isInvalid,
    });
  };

  const getDayList = () =>
    [...Array(32).keys()].map(day => ({
      disabled: !nullable && day === 0,
      name: day === 0 ? 'DD' : day,
      value: `${+day < 10 ? 0 : ''}${day}`,
    }));

  const getMonthList = (locale, isAbbreviated) =>
    [{ name: 'MM', value: 0 },
    ...getAllMonths(locale, isAbbreviated)].map(({ name, value }) => ({
      disabled: !nullable && value === 0,
      name: name,
      value: `${+value < 10 ? 0 : ''}${value}`,
    }));

  const getYearList = () =>
    [{ disabled: !nullable, name: 'YYYY', value: '0000' },
    ...[...Array(maxYearOffset).keys()].map(offset => {
      const year = minYear + (showFutureDates ? offset : - offset);
      return {
        disabled: false,
        name: year,
        value: year,
      };
    })];

  return (
    <Fragment>
      {label && (
        <Label>{label}</Label>
      )}
      <DateWrapper width={width}>
        <Select
          data-digix="DatePicker-Day"
          disabled={isDisabled}
          hasError={hasError}
          value={day}
          name="day"
          options={getDayList()}
          onChange={handleSetDate}
        />
        <Select
          data-digix="DatePicker-Month"
          disabled={isDisabled}
          hasError={hasError}
          value={month}
          name="month"
          options={getMonthList(locale, isAbbreviated)}
          onChange={handleSetDate}
        />
        <Select
          data-digix="DatePicker-Year"
          disabled={isDisabled}
          hasError={hasError}
          value={year}
          name="year"
          options={getYearList()}
          onChange={handleSetDate}
        />
      </DateWrapper>
      {hasError && isExpired ? (
        <ErrorMessage kind="error" role="alert">
          <span>{errorMessage.expired}</span>
        </ErrorMessage>
      ) : (isInvalidDate || isInvalid || (!nullable && hasError && isDateEmpty)) ? (
        <ErrorMessage kind="error" role="alert">
          <span>{errorMessage.invalid}</span>
        </ErrorMessage>
      ) : null}
    </Fragment>
  );
}

DatePicker.propTypes = {
  dateLabel: string,
  errorMessage: shape({
    expired: string,
    invalid: string,
  }),
  isAbbreviated: bool,
  isDisabled: bool,
  isInvalid: bool,
  label: string,
  locale: string.isRequired,
  maxYearOffset: number,
  minDate: instanceOf(Date),
  minYear: number,
  noValidation: bool,
  nullable: bool,
  setDate: func.isRequired,
  showFutureDates: bool,
  value: shape({
    date: string,
    valid: bool,
  }),
  width: string,
};

DatePicker.defaultProps = {
  dateLabel: '',
  errorMessage: {
    expired: 'Date must be set in the future.',
    invalid: 'Please select a valid date.',
  },
  isAbbreviated: false,
  isDisabled: false,
  isInvalid: false,
  maxYearOffset: DEFAULT_MAX_YEAR_OFFSET,
  minDate: null,
  minYear: DEFAULT_MIN_YEAR,
  noValidation: false,
  nullable: false,
  showFutureDates: false,
  value: {
    date: DEFAULT_DATE,
    valid: false,
  },
  width: '100%',
};

export default DatePicker;
