/*
 * A generic table component. Sample usage of this can be found in
 * src/electron/components/dashboard/load-wallet/trezor
 *
 * This component will not use pagination by default. To use pagination,
 * provide a value for props.fetchData.
 *
 * In case the data needs to be filtered and re-fetched with the applied filters,
 * set `isFilterChanged` prop to `true` in the parent component and reset it to `false`
 * immediately within 100ms to re-trigger the filter update.
 */

import TableStyles from '@digix/electron/shared/table/styles';
import { Loader, Pagination } from '@digix/electron/shared';
import React, { Component, Fragment } from 'react';
import {
  any,
  array,
  arrayOf,
  bool,
  func,
  shape,
  string,
} from 'prop-types';

const Table = TableStyles;

class TableComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      isLoading: false,
      fetchError: false,
    };
  }

  componentDidMount() {
    const { fetchData } = this.props;
    if (!fetchData) {
      return;
    }

    this.setLoading(true);
    this.fetchTable();
  }

  componentDidUpdate(prevProps) {
    const { fetchData, isFilterChanged } = this.props;
    if (
      fetchData !== prevProps.fetchData ||
      isFilterChanged && (isFilterChanged !== prevProps.isFilterChanged)
    ) {
      this.setLoading(true);
      this.fetchTable();
    }
  }

  fetchTable = () => {
    const { fetchData } = this.props;
    fetchData(1)
      .then(items => {
        this.setData(items);
        this.setLoading(false);
      })
      .catch(this.setErrorMessage);
  }

  setData = (items) => {
    this.setState({ items });
  }

  setError = (fetchError) => {
    this.setState({ fetchError });
  }

  setLoading = (isLoading) => {
    this.setState({ isLoading });
  }

  renderItems() {
    const { gridTemplateColumns, noItemsMsg, onClickRow } = this.props;
    const { fetchError, items, isLoading } = this.state;

    if (isLoading) {
      return (
        <Table.Item>
          <Table.Data data-digix="Table-StatusInfo">
            {<Loader kind="simple" color="primary" />}
          </Table.Data>
        </Table.Item>
      );
    }

    if (fetchError) {
      return (
        <Table.Item>
          <Table.Data data-digix="Table-StatusInfo">
            {fetchError}
          </Table.Data>
        </Table.Item>
      );
    }

    if (items.length === 0) {
      return (
        <Table.Item>
          <Table.Data data-digix="Table-StatusInfo">
            {noItemsMsg}
          </Table.Data>
        </Table.Item>
      );
    }

    return items.map((item, i) => {
      const keyItem = item.find(i => i.name === 'key');
      const key = keyItem ? keyItem.value : null;

      return (
        <Table.Item
          key={key || i}
          gridTemplateColumns={gridTemplateColumns}
          onClick={() => onClickRow ? onClickRow(key) : undefined}
          isClickEnabled={!!onClickRow}
        >
          {item.map(data => {
            if (data.name !== 'key') {
              return (
                <Table.Data key={data.name} data-content={data.name}>
                  {data.value}
                </Table.Data>
              );
            }
          })}
        </Table.Item>
      )
    });
  }

  renderPagination() {
    const { fetchData, fetchErrorMsg, isFilterChanged } = this.props;
    if (!fetchData) {
      return null;
    }

    return (
      <Pagination
        fetchData={fetchData}
        fetchErrorMsg={fetchErrorMsg}
        isFilterChanged={isFilterChanged}
        setData={this.setData}
        setError={this.setError}
        setLoading={this.setLoading}
      />
    );
  }

  render() {
    const { head, gridTemplateColumns } = this.props;

    return (
      <Fragment>
        <Table.Container>
          <Table.Item head gridTemplateColumns={gridTemplateColumns}>
            {head.map((item) => (
              <Table.Head key={item}>{item}</Table.Head>
            ))}
          </Table.Item>
          {this.renderItems()}
        </Table.Container>
        <Table.PaginationContainer>
          {this.renderPagination()}
        </Table.PaginationContainer>
      </Fragment>
    );
  }
}

TableComponent.propTypes = {
  fetchData: func,
  fetchErrorMsg: string,
  gridTemplateColumns: string,
  head: array,
  isFilterChanged: bool,
  items: arrayOf(
    shape({
      name: any,
      value: any,
    }),
  ),
  noItemsMsg: string,
  onClickRow: func,
};

TableComponent.defaultProps = {
  fetchData: undefined,
  fetchErrorMsg: 'Unable to fetch data.',
  gridTemplateColumns: undefined,
  head: [],
  isFilterChanged: false,
  items: [],
  noItemsMsg: 'No items to show.',
  onClickRow: undefined,
};

export default TableComponent;
