import AnnouncementStyles from '@digix/electron/shared/announcement/styles';
import Modal from 'react-responsive-modal';
import { ZENDESK_DIGIX_OFFICIAL_CHANNELS } from '@digix/electron/config/endpoints';
import React, { useEffect, useState } from 'react';
import { Heading } from '@digix/electron/shared';
import { func } from 'prop-types';
import { getCookie } from '@digix/electron/utils/cookies';
import { withTranslation } from 'react-i18next';

const { Button, Container, Content, Text } = AnnouncementStyles;

const Announcement = ({
  t,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(true);

  if (isModalVisible) {
    return (
      <Modal
        center
        closeOnEsc={false}
        closeOnOverlayClick={false}
        onClose={() => setIsModalVisible(false)}
        open={isModalVisible}
        styles={{
          modal: {
            padding: '3.2rem',
            maxWidth: '60rem',
            minWidth: '38rem',
            minHeight: '28rem',
            width: '100%',
          },
        }}
      >
        <Container>
          <Content>
            <Heading level="4">{t('Announcement.title')}</Heading>
            <Text>{t('Announcement.message')}</Text>
          </Content>
        </Container>
        <Button
          icon="arrow"
          onClick={() => window.open(ZENDESK_DIGIX_OFFICIAL_CHANNELS, '_blank')}
          size="medium"
          text={t('BuyDgx:Purchase.PurchaseDisabled.link')}
          underline
          variant="text"
        />
      </Modal>
    );
  }

  return null;
};

Announcement.propTypes = {
  t: func.isRequired,
};

export default withTranslation(['Homepage', 'BuyDgx'])(Announcement);
