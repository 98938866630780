import * as base from '@digix/electron/themes/light/base';

import { input, ui } from '@digix/electron/themes/light/base';

import { colors } from '@digix/electron/themes/light/colors';
import { typography } from '@digix/electron/themes/light/typography';

const themeLight = {
  typography: {
    typeface: typography.typeface,
    fontSize: typography.typeScale,
    fontWeight: typography.typeface.primary,
    letterSpacing: typography.letterSpacing,
    lineHeight: typography.lineHeight,
  },

  layout: {
    container: base.layout.container,
    nav: base.layout.nav,
    section: base.layout.section,
    sidenav: base.layout.sidenav,
  },

  card: base.layout.card,
  divider: base.layout.divider,
  footer: base.layout.footer,
  grid: base.layout.grid,
  header: base.layout.header,
  loader: base.loader,
  table: base.layout.table,
  tabs: base.layout.tabs,

  spacing: base.spacer,

  button: input.button,
  checkbox: input.checkbox,
  colors,
  date: input.checkbox,
  fileUploader: input.fileUploader,
  language: base.layout.langSelector,
  radio: input.radioButton,
  pagination: base.ui.pagination,
  pdfViewer: base.ui.pdfViewer,
  shape: base.ui.shape,
  select: input.select,
  slider: input.slider,
  stepper: input.stepper,
  switch: input.switch,
  textField: input.textField,

  heading: ui.heading,
  notification: base.layout.notification,

  label: ui.label,
  icon: base.ui.icon,
  link: base.ui.link,
  paragraph: base.ui.paragraph,
  status: base.ui.status,

  app: base.ui.app,
  zindex: base.layout.zindex,

  snackbar: base.snackbar,

  // ////////////////////////////////////////////////
  // Dev Note: For review and cleanup for design 2.0
  // ////////////////////////////////////////////////
  body: base.layout.body,
  caption: typography.caption,
  boxShadow: base.BASE_BOX_SHADOW,
  layout: base.layout,
  progress: base.ui.progress,
  spacer: base.spacer,
  transition: base.TRANSITION,

  // Dev Note -- these are old tokens. DO NOT USE.
  primaryColor: colors.primary.base,
  secondaryColor: colors.secondary.base,

  portal: base.portal,

};

export default themeLight;
