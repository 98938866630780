import React from 'react';
import PropTypes from 'prop-types';
import Angle from '@digix/electron/shared/icon/angle';
import Announcement from '@digix/electron/shared/icon/announcement';
import App from '@digix/electron/shared/icon/app';
import Arrow from '@digix/electron/shared/icon/arrow';
import ArrowHeavy from '@digix/electron/shared/icon/arrow-heavy';
import Avatar from '@digix/electron/shared/icon/avatar';
import Brand from '@digix/electron/shared/icon/brand';
import Calendar from '@digix/electron/shared/icon/calendar';
import CheckMark from '@digix/electron/shared/icon/check';
import Clock from '@digix/electron/shared/icon/clock';
import Close from '@digix/electron/shared/icon/close';
import Commision from '@digix/electron/shared/icon/commision';
import Computer from '@digix/electron/shared/icon/computer';
import Copy from '@digix/electron/shared/icon/copy';
import Dashboard from '@digix/electron/shared/icon/dashboard';
import DigixYellow from '@digix/electron/shared/icon/digix-yellow';
import DisabledHeart from '@digix/electron/shared/icon/disabled-heart';
import Divisible from '@digix/electron/shared/icon/gold-divisible';
import DoubeAngle from '@digix/electron/shared/icon/double-angle';
import Edit from '@digix/electron/shared/icon/edit';
import Email from '@digix/electron/shared/icon/email';
import EyesOpen from '@digix/electron/shared/icon/eyes-open';
import EyesClosed from '@digix/electron/shared/icon/eyes-closed';
import Filter from '@digix/electron/shared/icon/filter';
import FingerPrint from '@digix/electron/shared/icon/fingerprint';
import Globe from '@digix/electron/shared/icon/globe';
import Globe2 from '@digix/electron/shared/icon/globe2';
import Heart from '@digix/electron/shared/icon/heart';
import Help from '@digix/electron/shared/icon/help';
import Invite from '@digix/electron/shared/icon/invite';
import Personal from '@digix/electron/shared/icon/personal';
import Photo from '@digix/electron/shared/icon/photo';
import Login from '@digix/electron/shared/icon/login';
import Logout from '@digix/electron/shared/icon/logout';
import Refresh from '@digix/electron/shared/icon/refresh';
import Search from '@digix/electron/shared/icon/search';
import SellDgx from '@digix/electron/shared/icon/sell';
import SolidHeart from '@digix/electron/shared/icon/solid-heart';
import Sort from '@digix/electron/shared/icon/sort';
import Speaker from '@digix/electron/shared/icon/speaker';
import Support from '@digix/electron/shared/icon/support';
import Trash from '@digix/electron/shared/icon/trash';
import Transactions from '@digix/electron/shared/icon/transactions';
import Transferable from '@digix/electron/shared/icon/gold-transferable';
import Transparent from '@digix/electron/shared/icon/gold-transparent';
import Uptick from '@digix/electron/shared/icon/uptick';
import Spinner from '@digix/electron/shared/icon/spinner';
import Faceebook from '@digix/electron/shared/icon/facebook';
import Twitter from '@digix/electron/shared/icon/twitter';
import Instagram from '@digix/electron/shared/icon/instagram';
import Youtube from '@digix/electron/shared/icon/youtube';

const icons = {
  angle: Angle,
  announcement: Announcement,
  app: App,
  arrow: Arrow,
  arrow2: ArrowHeavy,
  avatar: Avatar,
  brand: Brand,
  calendar: Calendar,
  check: CheckMark,
  clock: Clock,
  close: Close,
  commision: Commision,
  computer: Computer,
  copy: Copy,
  dashboard: Dashboard,
  digixYellow: DigixYellow,
  disabledHeart: DisabledHeart,
  divisible: Divisible,
  doubleAngle: DoubeAngle,
  edit: Edit,
  email: Email,
  error: Close,
  eyesOpen: EyesOpen,
  eyesClosed: EyesClosed,
  filter: Filter,
  fingerprint: FingerPrint,
  globe: Globe,
  globe2: Globe2,
  heart: Heart,
  help: Help,
  invite: Invite,
  login: Login,
  logout: Logout,
  personal: Personal,
  photo: Photo,
  refresh: Refresh,
  sell: SellDgx,
  solidHeart: SolidHeart,
  speaker: Speaker,
  success: CheckMark,
  support: Support,
  trash: Trash,
  transactions: Transactions,
  transferable: Transferable,
  transparent: Transparent,
  uptick: Uptick,
  warning: Close,
  search: Search,
  sell: SellDgx,
  sort: Sort,
  spinner: Spinner,
  facebook: Faceebook,
  twitter: Twitter,
  instagram: Instagram,
  youtube: Youtube,
};

const Icon = ({ kind, ...props }) => {
  const SvgIcon = icons[props.name];
  return <SvgIcon kind={kind} {...props} />;
};

Icon.propTypes = {
  name: PropTypes.string,
  kind: PropTypes.string,
};

Icon.defaultProps = {
  name: 'brand',
  kind: '',
};

export default Icon;
