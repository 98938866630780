import React, { Component } from 'react';
import { getIsoLanguage } from '@digix/electron/utils/languages';
import { LANGUAGES, LOCAL_STORAGE_LNG_KEY } from '@digix/electron/utils/constants';
import { NavDropdown } from '@digix/electron/shared/language-dropdown/styles';

const { Icon } = NavDropdown;

export default class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLanguage: LANGUAGES[0],
      showDropdown: false,
    };
  }

  componentDidMount() {
    this.setState({
      currentLanguage: this.getCurrentLanguage() || LANGUAGES[0],
    });
  }

  changeLanguage(language) {
    localStorage.setItem(LOCAL_STORAGE_LNG_KEY, language);
    location.reload();
  }

  getCurrentLanguage() {
    const isoLanguage = getIsoLanguage();
    const currentLanguage = LANGUAGES.filter(language => language.value === isoLanguage);
    return currentLanguage[0];
  }

  toggleDropdown() {
    const { showDropdown } = this.state;
    this.setState({
      showDropdown: !showDropdown,
    });
  }

  renderOptions() {
    const { currentLanguage } = this.state;

    return LANGUAGES.map(language => {
      const { name, value } = language;
      const isCurrentLanguage = currentLanguage.value === value;
      const { kind, showOn } = this.props;

      return (
        <NavDropdown.Option
          selected={isCurrentLanguage}
          showOn={showOn}
          kind={kind}
          key={value}
          onClick={() => this.changeLanguage(value)}
          data-digix={value}
        >
          <span>{name}</span>
          {isCurrentLanguage && <Icon name="check" size="small" />}
        </NavDropdown.Option>
      );
    });
  }

  render() {
    const { currentLanguage, showDropdown } = this.state;
    const { dataPage, kind, position, showOn } = this.props;

    return (
      <NavDropdown.Container kind={kind} showOn={showOn} data-digix="Language-Option">
        <NavDropdown.SelectedOption
          data-name="language"
          data-page={dataPage}
          hasIcon
          icon="angle"
          onClick={() => this.toggleDropdown()}
          showOn={showOn}
          kind={kind}
          text={currentLanguage.name}
          variant="text"
        >
          <Icon data-name="language" data-page={dataPage} kind={kind} name="globe2" size="small" />
        </NavDropdown.SelectedOption>
        <NavDropdown.List
          data-name="language-list"
          kind={kind}
          position={position}
          show={showDropdown}
          showOn={showOn}
        >
          {this.renderOptions()}
        </NavDropdown.List>
      </NavDropdown.Container>
    );
  }
}
