/*
 * This stylesheet exports the Footer styled components, which are
 * used for the Footer component in homepage and dashboard
 *
 * =============================================================================
 * The hierarchy for each component is as follows:
 *
 * Foot.Container (footer)
 *   Foot.Content (Section, 12-col grid)
 *       Foot.Links (nav)
 *       Foot.Address (address)
 *       Foot.Social (div)
 *
 * =============================================================================
 */
import { Button } from '@digix/electron/shared/button/styles'
import { Link as RouterLink } from 'react-router-dom';
import { NavDropdown } from '@digix/electron/shared/language-dropdown/styles';
import styled, { css } from 'styled-components';
import { lineHeight, Link, media, Section } from '@digix/electron/shared';

export const Foot = {
  Container: styled.footer`
    background: ${props =>
      (props.page === 'dashboard' && props.theme.layout.footer.primary)
      || (props.page === 'homepage' && props.theme.layout.footer.secondary)
      || props.theme.layout.footer.primary};
    min-width: 38rem;
  
    ${media.sm`
      margin-right: 0;
    `}

    ${media.md`
      display: grid;
      grid-template-columns: [full-start] 1fr
        [content-start] minmax(min-content, ${({ page, theme: { app } }) =>
        (page === 'homepage' && app.webpage.width)
        || (page === 'dashboard' && app.dashboard.width)
        || app.webpage.width
      })[content-end] 1fr;
      margin-right: ${props => props.isLoggedIn ? '8rem' : 0};
    `}
      
    ${media.lg`
      margin-right: ${props => props.isLoggedIn ? '8rem' : 0};
    `}
  `,

  Content: styled(Section)`
    background: ${props =>
      (props.page === 'dashboard' && props.theme.layout.footer.primary)
      || (props.page === 'homepage' && props.theme.layout.footer.secondary)
      || props.theme.layout.footer.primary};

    ${media.xxs`
      ${props => props.page === 'dashboard' && css`
        align-items: center;
        display: flex;
        justify-content: center;
      `}
    `}
    
    ${media.sm`
      display: grid;
    `}

    ${media.md`
      font-family: ${props => props.theme.typography.typeface.primary.bold};
      font-size: ${props => props.theme.typography.fontSize.sm};
      grid-row-gap: 0;
      grid-template: auto auto / repeat(${props => props.theme.grid.column.large}, 1fr);
      ${props => props.page === 'dashboard' && css`
        grid-template: auto auto / 1fr 1fr 2fr repeat(9, 1fr);
      `};
      padding: ${props => (props.page === 'dashboard' && `4rem 0`) || ''};
    `}
  `,

  Digix: styled(RouterLink)`
    align-items: center;
    color: ${props => props.theme.colors.neutral.white};
    display: flex;
    font-family: ${props => props.theme.typography.typeface.primary.regular};
    grid-column: 2 span;
    justify-content: center;
    width: fit-content;

    svg {
      height: 3rem;
      margin-right: 1.2rem;
    }

    ${media.xs`
      margin-right: auto;
    `}

    ${media.sm`
      margin-right: 0;
    `}

    ${media.lg`
      margin: auto;
    `}
  `,

  Links: styled.nav`
    display: flex;
    grid-column: 2 / span 7;

    & > div {
      flex: 1;
    }
  `,

  LinksWrapper: styled.div`
    // display: ${props => props.mobile ? 'block' : 'none'};

    ${props => props.mobile && css`
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
    `};

    ${NavDropdown.SelectedOption} {
      color: ${props => props.theme.colors.neutral.white};
      svg {
        stroke: ${props => props.theme.colors.neutral.white};
      }
    }

    ${NavDropdown.List} {
      &:after {
        border-bottom-color: ${props => props.theme.colors.neutral.white};
        top: -0.9rem;
      }
    }

    ${NavDropdown.Option} {
      background-color: ${props => props.theme.colors.neutral.white};
      span {
        color: ${props => props.theme.colors.primary.base};
      }
    }

    ${media.sm`
      display: block;
      ${NavDropdown.Container} {
        display: none;
      }
    `}
  `,

  Link: styled(Link)`
    display: ${props => props.mobile ? 'block' : 'none'};
    ${props => lineHeight(props.theme.typography.lineHeight.sm)};

    ${media.sm`
      margin: 0 0 1.6rem 0;
      display: block;
    `}

    &:last-child {
      margin-bottom: 0;
    }
  `,

  LinkColumn: styled.div`
    display: flex;

    ${media.xxs`
      align-items: flex-start;
      align-self: baseline;
      flex-direction: column;
      grid-column: 2 / 4 span;
      justify-content: space-between;
      width: fit-content;

      & > * {
        margin-bottom: 2.4rem;
      }
    `}

    ${media.sm`
      align-items: center;
      align-self: center;
      flex-direction: unset;
      grid-column: 1 / 6 span;
      justify-content: space-around;
      margin-right: 0;
      width: 100%;
    `}

    ${media.md`
      grid-column: 2 / 3 span;
      justify-content: space-between;
    `}

    ${media.lg`
      grid-column: 1 / 4 span;
    `}
  `,

  MenuColumn: styled.div`
    align-items: center;
    display: flex;

    ${media.xxs`
      flex-direction: column;
      grid-column: 7 / 5 span;
      justify-content: space-between;
      margin-left: auto;
      width: fit-content;

      & > * {
        margin-bottom: 2.4rem;
      }
    `}

    ${media.sm`
      flex-direction: unset;
      grid-column: 7 / 6 span;
      justify-content: space-around;

      margin-left: 0;
      width: 100%;
    `}

    ${media.md`
      grid-column: 6 / 6 span;
      justify-content: space-between;
    `}

    ${media.lg`
      grid-column: 6 / 7 span;
    `}
  `,


  Address: styled.address`
    position: relative;
    color: ${props => props.theme.footer.textColor};
    font-family: ${props => props.theme.typography.typeface.primary.regular};
    font-style: normal;
    grid-column: auto / span 2;
    margin-left: 0.8rem;
    ${props => lineHeight(props.theme.typography.lineHeight.sm)};

    span {
      display: block;
      font-family: ${props => props.theme.typography.typeface.primary.bold};
      margin: 0 0 1.6rem 0;
      margin-top: 1.6rem;
      text-transform: uppercase;
    }

    ${media.sm`
      margin-left: 0;
      span {
        margin-top: 0;
      }
    `}
  `,

  Social: styled.div`
    grid-column: 2 / span 10;
    grid-row: 2;
    margin-left: 0.8rem;
    margin-top: 4rem;

    a {
      color: ${props => props.theme.layout.footer.textColor};
      display: inline-block;
      height: 1.6rem;
      margin-right: 1.6rem;
      width: auto;

      svg {
        height: 100%;
        width: auto !important; /* stylelint-disable-line */
      }
    }

    ${media.sm`
      margin-left: 0;
    `}
  `,

  Terms: styled(Link)`
    grid-column: span 2;

    & > a {
      margin-left: 0;
    }

    ${media.xxs`
      padding-left: 4.2rem;
    `}

    ${media.sm`
      grid-column: 3 / span 2;
      padding-left: 2rem;
    `}

    ${media.md`
      grid-column: 3 / span 2;
      padding-left: 0;
    `}

    ${media.lg`
      grid-column: span 2;
    `}
  `,

  HelpCentre: styled.div`
    grid-column: 12;
    margin: 0;
    margin-bottom: ${props => props.isDefault ? '2.4rem' : 0};
    margin-left: ${props => props.isDefault ? 0 : 'auto'};
    margin-top: ${props => props.isDefault ? 0 : '-4rem'};
    position: ${props => props.isDefault ? 'relative' : 'absolute'};
    right: 0;
    width: fit-content;
    display: ${props => props.tabletOnly ? 'none' : 'block'};

    ${Button} {
      min-width: 12.4rem;
      width: fit-content;
    }

    & > button {
      margin: ${props => props.isDefault ? 0 : 'auto'};
    }

    ${media.sm`
      margin-top: 0;
      position: static;
    `}

    ${media.md`
      ${props => !props.tabletOnly && !props.isDefault && css`
        display: none;
      `}
      ${props => props.tabletOnly && css`
        display: block;
        position: absolute;
        left: 0;
        grid-column: 1 / span 10;
        margin: 2rem 0 0;
        ${Button} {
          margin: 0
          width: fit-content;
        }
      `}
    `}

    ${media.lg`
      display: ${props => props.tabletOnly ? 'none' : 'block'};
      grid-column: none;
      margin-top: initial;
      margin-right: initial;
    `}
  `,
};
