import React from 'react';

const NotFound = props => (
  <svg
    fill="none"
    fillRule="evenodd"
    height="136"
    width="194"
    {...props}
  >
    <path
      d="M1 135h192V1H1z"
      stroke="#446bb2"
      strokeWidth="2"
    />
    <path
      d="M92.03 82.642h9.947V55.36H92.03v27.28zM85 89h24V49H85v40zM67.974 65.888h-9.95V49H51v23.253h16.974V89H75V49h-7.026zm67.998 0h-9.944V49H119v23.253h16.972V89H143V49h-7.028zM32 74h7v-7h-7zm123 0h7v-7h-7z"
      fill="#f5cd65"
    />
    <g fill="#446bb2">
      <g fillRule="nonzero">
        <circle
          cx="154"
          cy="12.828"
          r="2"
        />
        <circle
          cx="162"
          cy="12.828"
          r="2"
        />
        <circle
          cx="170"
          cy="12.828"
          r="2"
        />
        <path d="M30 11.54h112v2H30z" />
      </g>
      <path d="M16 10l2.828 2.828L16 15.657l-2.828-2.828z" />
    </g>
  </svg>
);

export default NotFound;
