import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Calendar = props => (
  <Container {...props}>
    <Icon viewBox="0 0 16 17">
      <defs>
        <polygon  points="0 5.73401484e-16 9.33333333 5.73401484e-16 9.33333333 7.46666667 0 7.46666667" />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1412.000000, -59.000000)">
          <g transform="translate(1413.000000, 60.000000)">
            <g transform="translate(2.800000, 4.666667)">
              <mask fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-3" />
            </g>
            <polygon stroke="#446BB2" points="0 14.9333333 14 14.9333333 14 1.86666667 0 1.86666667" />
            <line x1="0" y1="7.46666667" x2="14" y2="7.46666667" fill="#32363D" />
            <line x1="0" y1="7.46666667" x2="14" y2="7.46666667" stroke="#446BB2" />
            <polygon stroke="#446BB2" fill="#FFFFFF" points="2.8 2.8 5.6 2.8 5.6 0 2.8 0" />
            <polygon stroke="#446BB2" fill="#FFFFFF" points="8.4 2.8 11.2 2.8 11.2 0 8.4 0" />
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

export default Calendar;
