import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Login = props => (
  <Container {...props}>
    <Icon viewBox="0 0 78 144">
      <g stroke="#F9D167" strokeWidth="2" fill="none" fillRule="evenodd">
        <path d="M53.886 78.937c-4.928-1.904-12.187-4.38-16.624-4.38h-.263c-7.21 0-21.995 6.5-21.995 6.5L15 88.533M36.254 46c-4.05 0-7.28 3.281-7.28 7.343v7.135c0 4.114 3.23 7.395 7.28 7.395h1.897c3.999 0 7.229-3.28 7.229-7.343v-7.187c0-4.062-3.23-7.343-7.28-7.343h-1.846zM63 79.418L49.834 93.392l-8.707-8.151" />
        <g>
          <path d="M39 139.6c-3.143 0-5.7-2.512-5.7-5.6 0-3.086 2.557-5.6 5.7-5.6 3.143 0 5.7 2.514 5.7 5.6 0 3.088-2.557 5.6-5.7 5.6z" />
          <path d="M68.993 143H9.007C4.592 143 1 139.471 1 135.134v-9.067c0-.59.486-1.067 1.086-1.067h73.828c.6 0 1.086.478 1.086 1.067v9.067c0 4.337-3.592 7.866-8.007 7.866zM30.348 9.034h2.307M36.115 9.034h13.843M1 17V8.966C1 4.573 4.592 1 9.007 1h59.985C73.407 1 77 4.573 77 8.966V17" />
          <path d="M1 126V17.08c0-.595.486-1.08 1.086-1.08h73.828c.6 0 1.086.485 1.086 1.08V126" />
        </g>
      </g>
    </Icon>
  </Container>
);

export default Login;
