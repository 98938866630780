import { LANGUAGES, LOCAL_STORAGE_LNG_KEY } from '@digix/electron/utils/constants';

function getIsoLanguage() {
  const validIsoList = LANGUAGES.map((language) => language.value);

  const iso = localStorage.getItem(LOCAL_STORAGE_LNG_KEY);
  if (validIsoList.includes(iso)) {
    return iso;
  }

  return 'en';
}

export default getIsoLanguage;
export { getIsoLanguage };
