import { actions } from '~/actions/compliance';

const initialState = {
  filters: {
    applicationStatus: [],
    applicationType: '',
    keywords: '',
    walletStatus: [],
    walletType: [],
  },
  userId: null,
};

const compliance = (state = initialState, action) => {
  switch (action.type) {
    case actions.SAVE_KYC_DASHBOARD_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case actions.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
};

export default compliance;
