/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { autoRehydrate } from 'redux-persist';
import reducers from '~/reducers';
import thunk from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';

const isProduction = process.env.ENVIRONMENT === 'production';
const isStaging = process.env.ENVIRONMENT === 'kovan';
const isDebug = process.env.DEBUG === 'true';
const isDev = isDebug || (!isProduction && !isStaging);

const composeEnhancers = isDev ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
const enhancers = composeEnhancers(autoRehydrate({ log: true }), applyMiddleware(thunk));
const store = createStore(reducers, {}, enhancers);

export default store;
