import styled, { css } from 'styled-components';
import { Button } from '@digix/electron/shared/button/styles';

export const TextButton = styled(Button)`
  --background: transparent;
  --border-color: transparent;
  --disabled: transparent;
  --radius: 0;
  --color: ${props => props.isLoading ? 'var(--text-muted)' : 'var(--color-secondary)'};
  --font-size: var(--font-size-base);
  --letter-spacing: var(--letter-spacing-base);
  --margin-left: 0rem;
  --margin-right: 0rem;
  --min-width: auto;
  --padding-right: 1.6rem;
  --padding-left: 1.6rem;
  --text-decoration: underline;
  --text-transform: none;
  width: auto;

  div svg {
    --disabled: var(--text-muted);
    --fill: none;
    --stroke: ${props => props.accent ? 'var(--color-primary)' : 'var(--color-secondary)'};
  }

  ${props => props.icon === 'close' && css`
    --color: var(--muted);
    --font-size: var(--font-size-xs);
    --margin-left: 0;
    --margin-right: 0;
    --letter-spacing: var(--letter-spacing-2xl);
    --text-transform: uppercase;

    div svg {
      --stroke: var(--muted);
      --fill: var(--muted);
      stroke: var(--muted);
    }
  `}

  &:hover {
    --color: var(--color-primary);
  }

`;
