import React from 'react';
import PaginationV1 from '@digix/electron/shared/pagination/pagination';
import PaginationV2 from '@digix/electron/shared/pagination/pagination-v2';
import { oneOf } from 'prop-types';

const Pagination = props => {
  const paginationProps = {};
  Object.keys(props)
    .filter(item => item !== 'version')
    .forEach(key => {
      paginationProps[key] = props[key];
    });
  if (props.version === 'v2') {
    return <PaginationV2 {...paginationProps} />
  }
  return <PaginationV1 {...paginationProps} />
};

Pagination.propTypes = {
  version: oneOf(['v1', 'v2']),
};

Pagination.defaultProps = {
  version: 'v1',
};

export default Pagination;