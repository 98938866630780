/* eslint-disable no-underscore-dangle */
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import fragmentTypes from '@digix/electron/api/fragmentTypes.json';
import { getMainDefinition } from 'apollo-utilities';
import setupAssetsExplorerServer from '@digix/electron/api/services/assets-explorer';
import { setupElectronServer } from '@digix/electron/api/services/electron-root';
import setupPricefeedServer from '@digix/electron/api/services/price-feed';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';

const _getApi = (server, fallbackServer) => (
  ApolloLink.split(
    ({
      query: {
        loc: {
          source: { body },
        },
      },
    }) => server.queries.test(body),
    server.link,
    fallbackServer.link,
  )
);

const _getLinkWithSubscription = (socket, link) => (
  ApolloLink.split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    socket,
    link,
  )
);

const createDefaultClient = ({ ssr, fetch } = {}) => {
  const AssetsServer = setupAssetsExplorerServer({ ssr });
  const ElectronServer = setupElectronServer({ fetch });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: fragmentTypes,
  });

  const cache = new InMemoryCache({ fragmentMatcher });
  if (!ssr) {
    cache.restore(window.__APOLLO_STATE__);
  }

  const electronApi = _getApi(AssetsServer, ElectronServer);
  const electronLink = _getLinkWithSubscription(ElectronServer.socket, electronApi);

  const client = new ApolloClient({
    cache,
    connectToDevTools: !ssr,
    link: electronLink,
    ssrMode: ssr,
  });

  return {
    absintheSocket: ElectronServer.absintheSocket,
    client,
  };
};

const createPricefeedClient = ({ ssr } = {}) => {
  const { absintheSocket, link, socket } = setupPricefeedServer({ ssr });
  const priceFeedLink = _getLinkWithSubscription(socket, link);
  const cache = new InMemoryCache();

  if (!ssr) {
    cache.restore(window.__APOLLO_STATE__);
  }

  const client = new ApolloClient({
    cache,
    connectToDevTools: !ssr,
    link: priceFeedLink,
    ssrMode: ssr,
  });

  return {
    absintheSocket,
    client,
  };
};

const electronClient = createDefaultClient().client;
const priceFeedClient = createPricefeedClient().client;

export default electronClient;
export {
  electronClient,
  priceFeedClient,
};
