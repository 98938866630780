import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Search = (props) => (
  <Container {...props}>
    <Icon viewBox="0 0 37 38">
      <g stroke="none" strokeWidth="1" fill="none">
        <g transform="translate(-1648, -240)">
          <g transform="translate(204, 219)">
            <g transform="translate(1445, 22.25)" stroke="#32363D" strokeWidth="1.3">
              <path
                d="M16,27 C10,27 5,22 5,16 C5,10 10,5 16,5 C22,5 27,10 27,16 C27,22 22,27 16,27 Z"
                transform="translate(16, 16) rotate(-45) translate(-16, -16)">
              </path>
              <line x1="31" y1="37" x2="31" y2="24" transform="translate(31, 31) rotate(-45) translate(-31, -31) "></line>
            </g>
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

Search.defaultProps = {
  width: '',
  height: '',
};

export default Search;
