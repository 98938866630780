import * as AbsintheSocket from '@absinthe/socket';
import { ELECTRON_ROOT_SERVER } from '@digix/electron/config/endpoints';
import { GUEST_ROUTE_PATHS } from '@digix/electron/utils/constants';
import { Socket as PhoenixSocket } from 'phoenix';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { hasSubscription } from '@jumpn/utils-graphql';
import { onError } from 'apollo-link-error';
import {
  ApolloLink,
  from,
  split,
} from 'apollo-link';
// eslint-disable-next-line sort-imports
import i18n from '~/translations/i18n';
import { showSnackbar } from '~/actions/snackbar';
import store from '~/store';

const onErrorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message === 'Rate Limited') {
        store.dispatch(showSnackbar({
          duration: 'indefinite',
          text: i18n.t('NotFound:404.rateLimited'),
          variant: 'warning',
          visible: true,
        }));
      }
    });
  }

  if (networkError) {
    const NETWORK_ERROR_MESSAGE = `[Network error]: ${networkError}`;
    const basePath = window.location.hash.split('/')[1];
    const isGuestRoute = GUEST_ROUTE_PATHS.includes(basePath);
    if (operation.operationName === 'getCurrentUser' && networkError.message === 'Failed to fetch') {
      response.errors = null;
    }

    switch (networkError.statusCode) {
      case 401:
        if (isGuestRoute) {
          break;
        }

        console.log(NETWORK_ERROR_MESSAGE);
        localStorage.removeItem('user-token');
        localStorage.removeItem('token-expiry');
        localStorage.removeItem('websocket-token');
        window.location.replace(`${window.location.origin}/#/portal/login?invalid_token=true`);
        break;
      default:
        console.log(NETWORK_ERROR_MESSAGE);
        break;
    }
  }
});

const httpAuthorizedLink = () => (
  new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('user-token');
    operation.setContext(({ headers }) => ({
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    }));

    return forward(operation);
  })
);

const phoenixSocket = new PhoenixSocket(ELECTRON_ROOT_SERVER().webSocket, {
  params: () => {
    const token = localStorage.getItem('websocket-token');
    if (token) {
      return {
        websocket_token: token,
      };
    }

    return {};
  },
});

const closeElectronSocket = () => {
  if (phoenixSocket.conn) {
    phoenixSocket.conn.close();
  }
};

const setupElectronServer = () => {
  let electronLink = createHttpLink({
    fetch,
    uri: ELECTRON_ROOT_SERVER().http,
  });

  const electronAuthHttpLink = from([
    onErrorLink,
    httpAuthorizedLink(),
    electronLink,
  ]);

  const absintheSocket = AbsintheSocket.create(phoenixSocket);
  const electronSocketLink = createAbsintheSocketLink(absintheSocket);
  electronLink = split(
    (operation) => hasSubscription(operation.query),
    electronSocketLink,
    electronAuthHttpLink,
  );

  return {
    absintheSocket,
    link: electronLink,
    socket: electronSocketLink,
  };
};

export default setupElectronServer;
export {
  closeElectronSocket,
  setupElectronServer,
};
