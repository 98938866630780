import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Computer = props => (
  <Container {...props}>
    <Icon viewBox="0 0 29 19">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-128.000000, -13686.000000)" strokeWidth="0.7">
          <g transform="translate(129.000000, 13687.595754)">
            <g>
              <polygon stroke="#091831" points="17.1267937 14.5779976 16.8509327 14.5779976 16.6290224 14.7408212 16.262565 15.0074447 10.5315785 15.0074447 10.166139 14.7408212 9.94321076 14.5779976 9.66734978 14.5779976 0.84590583 14.5779976 0 14.5779976 0 15.4236624 0 15.8103682 26.7941435 15.8103682 26.7941435 15.4236624 26.7941435 14.5779976 25.9482377 14.5779976" />
              <polyline stroke="#091831" points="24.7086955 13.3556 24.7086955 -5.16031662e-13 2.08605874 -5.16031662e-13 2.08605874 13.3556" />
              <polygon stroke="#091831" points="4.60066906 5.55319824 22.0572744 5.55319824 22.0572744 2.21124529 4.60066906 2.21124529" />
              <polygon stroke="#091831" points="4.5497722 11.5637047 8.55840897 11.5637047 8.55840897 7.90424588 4.5497722 7.90424588" />
              <polygon stroke="#FFD25F" points="11.282409 11.4460453 15.2910457 11.4460453 15.2910457 7.78658647 11.282409 7.78658647" />
              <polygon stroke="#FFD25F" points="18.0140278 11.4460453 22.0226646 11.4460453 22.0226646 7.78658647 18.0140278 7.78658647" />
              <polyline stroke="#FFD25F" points="19.097113 9.59932118 19.7608081 10.2618094 21.0362834 8.98771529" />
              <polyline stroke="#FFD25F" points="12.4114031 9.59932118 13.0750982 10.2618094 14.3505735 8.98771529" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

export default Computer;
