import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Trash = props => (
  <Container {...props}>
    <Icon viewBox="0 0 14 17">
      <g stroke="none" strokeWidth="1" fill="none" filleRule="evenodd">
        <g transform="translate(-730.000000, -129.000000)" stroke="#32363D">
          <g transform="translate(730.000000, 130.000000)">
            <g transform="translate(0.000000, 0.000000)">
              <line x1="5.37419658" y1="5.83333333" x2="5.37419658" y2="12.8333333" />
              <line x1="8.87419658" y1="5.83333333" x2="8.87419658" y2="12.8333333" />
              <polyline  points="0.591593424 3.5 0.591593424 15.1666667 13.4249268 15.1666667 13.4249268 3.5" />
              <path d="M13.9973653,2.33333333 L8.76473845,2.33333333 L8.76473845,2.02059601 C8.76473845,0.971622904 8.03277263,0.00490622903 7.08604477,0 C6.12405283,-0.00486681233 5.25826009,0.899546724 5.25826009,2.02059601 L5.25826009,2.33333333 L-2.68229883e-13,2.33333333" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

export default Trash;
