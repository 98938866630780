import { ENABLE_KYC_V2 } from '@digix/electron/utils/constants';
import KycApplicationGQL from '@digix/electron/api/queries/compliance/kyc-application';
import React from 'react';
import gql from 'graphql-tag';
import { setAuthStatus } from '~/actions/auth-status';
import store from '~/store';
import { Query, Subscription } from 'react-apollo';
import { get, isEqual } from 'lodash';

export const getPurchaseConfig = gql`
  query getPurchaseConfig {
    config {
      canPurchase
    }
  }
`;

export const requestAuthorization = gql`
  mutation requestAuthorization($email: String!, $password: String!) {
    requestAuthorization(input: { email: $email, password: $password }) {
      errors {
        field
        message
      }
      response {
        ... on Authorization {
          exp
          jwt
          websocketToken
        }
        ... on AuthChallenge {
          id
          type
          expiresAt
          resendAt
          createdAt
        }
      }
    }
  }
`;

export const getChallengeDetails = gql`
  query getChallengeDetails($challengeId: ID) {
    authChallenge(id: $challengeId) {
      email
      expiresAt
      resendAt
      createdAt
    }
  }
`;

export const requestAuthorizationWithCode = gql`
  mutation requestAuthorizationWithCode($challengeId: ID!, $code: String!) {
    answerChallenge(input: { challengeId: $challengeId, code: $code }) {
      authorization {
        exp
        iat
        jwt
        websocketToken
      }
      errors {
        field
        message
      }
    }
  }
`;

export const resendVerificationCode = gql`
  mutation resendVerificationCode($challengeId: ID!) {
    renewChallenge(input: { challengeId: $challengeId }) {
      authChallenge {
        id
        email
        type
        expiresAt
        resendAt
        createdAt
      }
      errors {
        field
        message
      }
    }
  }
`;

export const reactivateAccount = gql`
  mutation reactivateAccount{
    reactivateAccount(input: {}) {
      user {
        id
        status
      }
      errors {
        field
        message
      }
    }
  }
`;

export const requestAccountDeactivation = gql`
  mutation requestDeactivation($reasons: [DeactivationReason!], $longReason: String) {
    requestDeactivation(input: { reasons: $reasons, longReason: $longReason }) {
      user {
        id
        status
        deactivationRequest {
          id
          reasons
          longReason
          createdAt
        }
      }
      errors {
        field
        message
      }
    }
  }
`;

export const refreshToken = gql`
  mutation {
    refreshToken {
      authorization {
        jwt
        websocketToken
        exp
        scope
      }
    }
  }
`;

export const signOut = gql`
  mutation {
    signOut
  }
`;

export const requestPasswordReset = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(input: { email: $email }) {
      errors {
        field
        message
      }
    }
  }
`;

export const resetPassword = gql`
  mutation resetPasswordMutation(
    $token: String!
    $password: String!
  ) {
    resetPassword(
      input: { token: $token, password: $password}
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export const resetOtpPassword = gql`
  mutation resetOtpPasswordMutation(
    $token: String!
    $password: String!
  ) {
    resetOtpPassword(
      input: { token: $token, password: $password}
    ) {
      errors {
        field
        message
      }
    }
  }
`;

export const updateWallet = gql`
  mutation(
    $wallet: WalletType!,
    $hdPath: String
  ) {
    updateWallet(input: {
      wallet: $wallet,
      hdPath: $hdPath
    }) {
      errors {
        field
        message
      }
      user {
        wallet
        hdPath
      }
    }
  }
`;

const getKycFields = () => {
  if (ENABLE_KYC_V2) {
    return `
      kycV2 {
        id
        aresUrl
        daysExpiring
        expirationDate
        name
        status
        record {
          address
          age
          birthCountry
          birthdate
          email
          gender
          identificationNumber
          incomeSource
          industry
          name
          nationality
          occupation
          phoneNumber
          residenceCountry
          riskRating
          status
          title
        }
      }
      walletChangeCheck
      walletChangeRequest {
        id
        ethAddress
        wallet
        hdPath
        status
        expiresAt
      }
      deactivationRequest {
        reasons
        longReason
        createdAt
      }
    `;
  }

  return `
    applyingKyc {
      ... on KycTier2 {
        id
        rejectionReasons {
          group
          name
          value
        }
        status
      }
    }
    previouslyAppliedKyc {
      ... on KycTier2 {
        status
      }
    }
    kyc {
      id
      code
      tier
      firstName
      lastName
      fullName
      incomeSource
      birthdate
      residence {
        country
        city
        postalCode
        line1
        line2
      }
      citizenship
      expirationDate
      identificationProof {
        number
      }
    }
  `;
};

export const getCurrentUser = gql`
  query getCurrentUser {
    currentUser {
      email
      ethAddress
      hdPath
      id
      status
      isKycOfficer
      tncVersion
      wallet
      changeEmailRequest {
        email
        expiresAt
      }
      ${getKycFields()}
    }
    tnc {
      text
      version
    }
  }
`;

export const authenticationSubscription = gql`
  subscription {
    authenticationUpdated {
      exp
    }
  }
`;

export const kycAndWalletStatusSubscription = gql`
  subscription {
    currentUserUpdated {
      id
      email
      ethAddress
      kycV2 {
        id
        status
      }
      status
      walletChangeCheck
      walletChangeRequest {
        ethAddress
        status
        expiresAt
      }
    }
  }
`;

export const getPurchaseHistory = gql`
  query getPurchaseHistory(
    $ethAddress: String,
    $first: Int,
    $offset: Int,
    $orderBy: SortOrder,
  ) {
    currentUser {
      id
      purchaseTransactions(
        ethAddress: $ethAddress,
        first: $first,
        offset: $offset,
        orderBy: $orderBy,
      ) {
        edges {
          cursor
          node {
            amount
            id
            createdAt
            orderId
            orderStatus
            price
            symbol
            txHash
            txStatus
            type
            value
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        totalCount
      }
    }
  }
`;

export const getUserPurchaseHistory = gql`
  query getUserPurchaseHistory(
    $first: Int,
    $offset: Int,
    $orderBy: SortOrder,
    $id: ID!,
    $ethAddress: String,
  ) {
    kycV2(id: $id) {
      id
      user {
        id
        purchaseTransactions(
          first: $first,
          offset: $offset,
          orderBy: $orderBy,
          ethAddress: $ethAddress
        ) {
          edges{
            cursor
            node{
              amount
              id
              createdAt
              orderId
              orderStatus
              price
              symbol
              txHash
              txStatus
              type
              value
            }
          }
          pageInfo{
            hasNextPage
            hasPreviousPage
          }
          totalCount
        }
      }
    }
  }
`;

export const getWalletChangesHistory = gql`
  query getWalletChangesHistory(
    $first: Int,
    $offset: Int,
    $orderBy: SortOrder,
    $status: WalletChangeRequestStatus,
  ) {
    currentUser {
      id
      walletChanges(
        first: $first,
        offset: $offset,
        orderBy: $orderBy,
        status: $status,
      ) {
        edges {
          cursor
          node {
            approvedAt
            comment
            createdAt
            id
            fromAddress
            toAddress
            txHash
            txStatus
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  }
`;

export const getPreviousAddresses = gql`
  query getPreviousAddresses(
    $first: Int,
    $offset: Int,
    $orderBy: SortOrder,
    $id: ID!,
    $status: WalletChangeRequestStatus,
  ) {
    kycV2(id: $id) {
      id
      user {
        id
        walletChanges(
          first: $first,
          offset: $offset,
          orderBy: $orderBy,
          status: $status,
        ) {
          edges {
            cursor
            node {
              id
              createdAt
              comment
              approvedAt
              fromAddress
              toAddress
              txStatus
            }
          }
        }
      }
    }
  }
`;

export const getUserWalletChangesHistory = gql`
  query getUserWalletChangesHistory(
    $first: Int,
    $offset: Int,
    $orderBy: SortOrder,
    $id: ID!,
    $status: WalletChangeRequestStatus,
  ) {
    kycV2(id: $id) {
      id
      user {
        id
        walletChanges(
          first: $first,
          offset: $offset,
          orderBy: $orderBy,
          status: $status,
        ) {
          edges {
            cursor
            node {
              createdAt
              comment
              approvedAt
              fromAddress
              id
              toAddress
              rejectedAt
              txHash
              txStatus
            }
          }
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          totalCount
        }
      }
    }
  }
`;

export const withCurrentUser = Component => props => (
  <Query
    fetchPolicy="network-only"
    query={getCurrentUser}
  >
    {({
      loading,
      data,
      error,
      refetch,
    }) => {
      let currentUser;
      const hasUserData = data && data.currentUser;

      if (!loading) {
        if (hasUserData) {
          currentUser = {
            ...data.currentUser,
            tnc: data.tnc,
          };
        } else if (error) {
          localStorage.removeItem('user-token');
          localStorage.removeItem('token-expiry');
          localStorage.removeItem('websocket-token');
        }
      }

      return (
        <Component
          currentUser={currentUser}
          error={error}
          loading={loading}
          refetch={refetch}
          {...props}
        />
      );
    }}
  </Query>
);

export const withAuthenticationSubscription = Component => props => (
  <Subscription
    fetchPolicy="network-only"
    shouldResubscribe
    subscription={authenticationSubscription}
  >
    {({ data }) => {
      const { authStatus: prevAuthStatus } = store.getState();
      const tokenExpiry = localStorage.getItem('token-expiry') / 1000;
      const authStatus = {
        hasNewSession: !!(data && tokenExpiry < get(data, 'authenticationUpdated.exp', 0)),
        isLoggedOut: !!data && get(data, 'authenticationUpdated') === null,
      };
      if (!isEqual(prevAuthStatus, authStatus)) {
        store.dispatch(setAuthStatus(authStatus));
      }
      return <Component {...props} />;
    }}
  </Subscription>
);

export const withKycAndWalletStatusSubscription = Component => props => {
  if (!get(props, 'currentUser.isKycOfficer', false)) {
    return (
      <Subscription
        fetchPolicy="network-only"
        shouldResubscribe
        subscription={kycAndWalletStatusSubscription}
      >
        {() => <Component {...props} />}
      </Subscription>
    );
  }
  return <Component {...props} />;
};

export const withKycNotesAddedSubscription = Component => props => (
  <Subscription
    fetchPolicy="network-only"
    shouldResubscribe
    subscription={KycApplicationGQL.subscription.notesAdded}
    variables={{
      kycId: get(props, 'kycId'),
    }}
  >
    {({ data }) => {
      if (data && data.kycV2NoteAdded) {
        return (
          <Component
            {...props}
            newData={data.kycV2NoteAdded.id}
          />
        );
      }
      return <Component {...props} />;
    }}
  </Subscription>
);

export const withKycNotesDeletedSubscription = Component => props => (
  <Subscription
    fetchPolicy="network-only"
    shouldResubscribe
    subscription={KycApplicationGQL.subscription.notesDeleted}
    variables={{
      kycId: get(props, 'kycId'),
    }}
  >
    {({ data }) => {
      if (data && data.kycV2NoteDeleted) {
        return (
          <Component
            {...props}
            newData={data.kycV2NoteDeleted}
          />
        );
      }
      return <Component {...props} />;
    }}
  </Subscription>
);

export const withKycNotesMarkedSubscription = Component => props => (
  <Subscription
    fetchPolicy="network-only"
    shouldResubscribe
    subscription={KycApplicationGQL.subscription.notesMarked}
    variables={{
      kycId: get(props, 'kycId'),
    }}
  >
    {() => <Component {...props} />}
  </Subscription>
);

export const withKycNotesUnMarkedSubscription = Component => props => (
  <Subscription
    fetchPolicy="network-only"
    shouldResubscribe
    subscription={KycApplicationGQL.subscription.notesUnmarked}
    variables={{
      kycId: get(props, 'kycId'),
    }}
  >
    {() => <Component {...props} />}
  </Subscription>
);

export const withKycDetails = Component => props => (
  <Query
    query={KycApplicationGQL.query.fetchDetails}
    variables={{
      id: get(props, 'match.params.kycId'),
    }}
  >
    {({ data, loading, refetch }) => (
      <Component
        kycV2={get(data, 'kycV2') || null}
        loading={loading}
        refetch={refetch}
        {...props}
      />
    )}
  </Query>
);

export const withKycNotes = Component => props => {
  if (get(props, 'kycId')) {
    return (
      <Query
        query={KycApplicationGQL.query.fetchNotes}
        variables={{
          kycId: get(props, 'kycId'),
          limit: 10,
          offset: 0,
        }}
      >
        {({ data, fetchMore, loading }) => {
          const notes = !loading
            ? get(data, 'kycV2.notes.edges').map(({ node }) => ({ ...node }))
            : [];
          return (
            <Component
              loading={loading}
              notes={notes}
              onFetchMore={fetchMore}
              totalCount={get(data, 'kycV2.notes.totalCount') || 0}
              {...props}
            />
          );
        }}
      </Query>
    );
  }
  return <Component {...props} />;
};

export const checkPortalSession = ({ query }, history) => {
  query({
    fetchPolicy: 'network-only',
    query: getCurrentUser,
  })
    .then(({ data }) => {
      const isLoggedIn = !!(data && data.currentUser);
      if (isLoggedIn) {
        history.push('/dashboard');
      }
    })
    .catch(() => null);
};
