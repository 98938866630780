import styled from 'styled-components';
import { Alert, Label } from '@digix/electron/shared';
import emptyBackground from '@digix/electron/assets/images/img-placeholder.svg';
import { Button } from '@digix/electron/shared';

const StyledUploader = {
  ButtonGroup: styled.div`
    display: flex;
    justify-content: space-around;
  `,
  ButtonLabel: styled.div`
    margin: auto;
    width: fit-content;
    svg {
      margin-right: 0.5rem;
    }
  `,
  ButtonWrapper: styled.div`
    width: fit-content;
  `,
  ErrorMessage: styled(Alert)`
    background: transparent;
    margin-top: 0.5rem;
    padding: 0;
  `,
  Instruction: styled.p`
    color: ${props => props.theme.fileUploader.textColor};
    font-family: ${props => props.theme.typography.typeface.primary.regular};
    font-size: ${props => props.theme.typography.fontSize.xs};
    line-height: ${props => props.theme.typography.lineHeight.xs};
    margin: 0;
    padding: 0;
    text-align: center;
  `,
  Label: styled(Label)`
    font-family: ${props => props.theme.typography.typeface.primary.semibold};
    margin-bottom: 2.4rem;
  `,
  Preview: styled.div`
    align-items: ${props => props.isEmpty ? 'flex-end' : 'center'};
    background-color: ${props => props.theme.fileUploader.background};
    background-image: ${props => props.isEmpty ? `url(${emptyBackground})` : 'none'};
    background-position: center 6rem;
    background-repeat: no-repeat;
    background-size: 42%;
    border: 1px solid ${props => props.hasError ? props.theme.colors.semantic.error : props.theme.fileUploader.borderColor};
    display: flex;
    height: 26rem;
    justify-content: center;
    margin-bottom: ${props => props.hasError ? 0 : 1.6}rem;
    max-height: 30rem;
    overflow: hidden;
    padding: ${props => props.isEmpty ? 4 : 0}rem;
    width: 100%;
  `,
  Wrapper: styled.div`
    background-color: ${props => props.theme.colors.neutral.white};
    width: 100%;
    button {
      margin: 1.2rem 0;
    }
    input {
      display: none;
    }
  `,
  WebCamWrapper: styled.div`
    height: ${props => props.isVisible ? '100%' : 0};
    width: ${props => props.isVisible ? '100%' : 0};
  `,
  FullScreenWebCamControlWrapper: styled.div`
    bottom: 6rem;
    display: flex;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 1;
  `,
  BaseButton: styled(Button)`
    background-color: ${props => props.theme.colors.primary.light};
    border-color: transparent;
    width: 22.6rem;
    &:before {
      background-color: transparent;
    }
  `,
  RetakePicButton: styled(Button)`
    border-color: ${props => props.theme.colors.neutral.white};
    color: ${props => props.theme.colors.neutral.white};

    &:before {
      background-color: ${props => props.theme.colors.neutral.white};
    }
  `,
  CloseButton: styled(Button)`
    clip-path: none;
  `,
};

export default StyledUploader;
