/* How to Use the Loader on Containers (<section />)
 * <Loader />component must come IMMEDIATELY after the <section /> tag.
 *
 * import { Loader } from '@digix/electron/shared';
 *
 * <Section>
 * <Loader kind="simple | grid" color="primary | inverted" />
 * </Section>
 *
 * How to Use the Loader on a Button (variant="contained")
 * <Button isLoading ...>
 *
 * How to Use the Loader with loading text (works only with kind="grid")
 * <Loader kind="grid" color="primary" text={<span>Loading...</span>} />
 *
 * ----------------------------------------------------------
 * Properties:
 *
 * kind (string) - two variations at the moment
 *    simple  | has no container and just the animated svg itself;
 *    grid    | has a container with grey background color, mainly used for containers and sections.
 *
 * color (string) - define the color of the SVG icon
 *
 * background (string) - define the background color of the container (only works with kind="grid")
 *
 * text (string | JSX element) - display loading text along with the SVG icon (only works with kind="grid")
 *
 * ----------------------------------------------------------
 * visible = boolean; TBD, show/hide the loader as required. Leaving to JS dev to decide.
 * animation= string; TBD; nice to have in the future.
 *
 */

import { Spinner } from '@digix/electron/shared/loader/styles';
import React, { Fragment } from "react"
import {
  element,
  oneOf,
  oneOfType,
  string,
} from 'prop-types';

const { ContentLoader, Icon, Text } = Spinner;

const SvgLoader = props => (
  <Icon {...props} viewBox="0 0 16 24">
    <rect x="-34" y="-16" width="8" height="8" opacity="0.2" transform="rotate(-45)">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="1s" repeatCount="indefinite" />
      <animate attributeName="height" attributeType="XML" values="10; 15; 10" begin="0s" dur="1s" repeatCount="indefinite" />
      <animate attributeName="width" attributeType="XML" values="10; 15; 10" begin="0s"
        dur="1s" repeatCount="indefinite" />
    </rect>
    <rect x="-10" y="8" width="8" height="8" opacity="0.2" transform="rotate(-45)">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.25s" dur="1s" repeatCount="indefinite" />
      <animate attributeName="height" attributeType="XML" values="10; 15; 10" begin="0.25s" dur="1s" repeatCount="indefinite" />
      <animate attributeName="width" attributeType="XML" values="10; 15; 10" begin="0.25s"
        dur="1s" repeatCount="indefinite" />
    </rect>
    <rect x="14" y="32" width="8" height="8" opacity="0.2" transform="rotate(-45)">
      <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.5s" dur="1s" repeatCount="indefinite" />
      <animate attributeName="height" attributeType="XML" values="10; 15; 10" begin="0.5s" dur="1s" repeatCount="indefinite" />
      <animate attributeName="width" attributeType="XML" values="10; 15; 10" begin="0.5s"
        dur="1s" repeatCount="indefinite" />
    </rect>
  </Icon>
);

const Loader = ({
  background,
  color,
  dataDigix,
  kind,
  text,
}) => (
  <Fragment>
    {kind === 'simple' && (
      <SvgLoader
        color={color}
        data-digix={dataDigix}
        size="small"
        simple
      />
    )}
    {kind === 'grid' && (
      <ContentLoader
        background={background}
        data-digix={dataDigix}
        isInverted={color === 'inverted'}
      >
        <Text
          color={color}
          data-digix="Loader-Text"
        >
          {text && text}
          <SvgLoader
            color={color}
            grid
            size="large"
          />
        </Text>
      </ContentLoader>
    )}
  </Fragment>
);

Loader.propTypes = {
  background: string,
  color: oneOf(['inverted', 'primary']).isRequired,
  dataDigix: string,
  kind: oneOf(['grid', 'simple']).isRequired,
  text: oneOfType([string, element]),
};

Loader.defaultProps = {
  background: null,
  dataDigix: 'Loader',
  text: null,
};

export default Loader;
