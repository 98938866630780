import * as AbsintheSocket from '@absinthe/socket';
import { PRICE_FEED_SERVER } from '@digix/electron/config/endpoints';
import { Socket as PhoenixSocket } from 'phoenix';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { hasSubscription } from '@jumpn/utils-graphql';
import { split } from 'apollo-link';

const setupPricefeedServer = ({ ssr }) => {
  let absintheSocket;
  let priceFeedSocketLink;

  let priceFeedLink = createHttpLink({
    fetch,
    uri: PRICE_FEED_SERVER().http,
  });

  if (!ssr) {
    absintheSocket = AbsintheSocket.create(
      new PhoenixSocket(PRICE_FEED_SERVER().webSocket, {
        params: () => {
          const token = localStorage.getItem('user-token');
          if (token) {
            return { token };
          }

          return {};
        },
      }),
    );

    priceFeedSocketLink = createAbsintheSocketLink(absintheSocket);
    priceFeedLink = split(
      (operation) => hasSubscription(operation.query),
      priceFeedSocketLink,
      priceFeedLink,
    );
  }

  const queries = new RegExp([
    'fetchLatestCandleTick',
    'fetchTick',
  ].join('|'));

  return {
    absintheSocket,
    link: priceFeedLink,
    queries,
    socket: priceFeedSocketLink,
  };
};


export default setupPricefeedServer;
