import React from 'react';

const GoldDivisible = props => (
  <div {...props}>
    <svg viewBox="0 0 107 104" ><g strokeWidth="1.5" fill="none" fillRule="evenodd"><path d="M19 29c7.836-10.983 21.123-19.435 34.909-18.977 7.081.238 13.893 2.519 20.091 5.88M81.415 24c9.586 7.938 26.219 36.635-2.415 58" stroke="#091831"/><path d="M30 18C55.52-3.713 77.806-4.208 96.86 16.516 106.5 27 106.352 37.13 103.98 48.194 101.415 60.146 93.759 69.83 84.5 77.5" stroke="#091831"/><path d="M72 62.5C72 79.897 58.12 94 41 94c-17.12 0-31-14.103-31-31.5C10 45.105 23.88 31 41 31c1.88 0 3.721.17 5.507.497" stroke="#FFD25F"/><path d="M69.786 31.148C78.006 39.18 84 49.708 84 62.022 84 84.652 65.419 103 42.5 103S1 84.654 1 62.022c0-24.808 24.94-44.466 49.421-40.515 7.311 1.179 14.087 4.48 19.365 9.64z" stroke="#FFD25F"/><path d="M49 52.222v-2.59A2.634 2.634 0 0 0 46.363 47h-7.1A3.261 3.261 0 0 0 36 50.259v8.107a3.261 3.261 0 0 0 3.262 3.26h6.474A3.262 3.262 0 0 1 49 64.886v8.854c0 1.8-1.461 3.26-3.264 3.26h-6.474A3.262 3.262 0 0 1 36 73.74v-2.413M41.5 42v5M41.5 51v10M41.5 67v17" stroke="#FFD25F"/></g></svg>
  </div>
);

GoldDivisible.defaultProps = {};

export default GoldDivisible;
