/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable sort-imports */
/* eslint-disable sort-imports */
import React, {
  Fragment,
  PureComponent,
  Suspense,
  lazy,
} from 'react';
import PropTypes, { func } from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';

import Analytics from '@digix/analytics';
import {
  KYC_V2_USER_STATUS,
  SHOW_PUBLIC_NOTICE,
  WALLET_LOADER_TYPE,
} from '@digix/electron/utils/constants';
import { Loader, Snackbar } from '@digix/electron/shared';
import { breakpoints } from '@digix/electron/shared/style/breakpoints';
import { createKeystore } from '~/actions/keystore'; // eslint-disable-line
import { resetReduxState } from '~/actions/user'; // eslint-disable-line
import { getCookie } from '@digix/electron/utils/cookies';
import { setIsMobile } from '~/actions/resize'; // eslint-disable-line
import store from '~/store'; // eslint-disable-line
import { withCurrentUser } from '@digix/electron/api/queries/electron';
import {
  checkForInactivity,
  checkLoginSession,
  endUserSession,
  refreshUserToken,
} from '@digix/electron/utils/session-helpers';

// Styles
import Light from '@digix/electron/themes/light';
import AppContainer from '@digix/electron/styles';
import { GlobalStyle } from '@digix/electron/themes/global';

// Base
import NoRouteMatch from '@digix/electron/containers/404';
import SessionTimeout from '@digix/electron/containers/session-timeout';

// Portal
const Login = lazy(() => import('@digix/electron/pages/portal/login'));
const LoginVerification = lazy(() => import('@digix/electron/pages/portal/verify'));
const RegisterSelectCountry = lazy(() => import('@digix/electron/pages/portal/register/select-country'));
const RegisterForm = lazy(() => import('@digix/electron/pages/portal/register/registration-form'));
const RegisterFormV2 = lazy(() => import('@digix/electron/pages/portal/register'));
const ResetPassword = lazy(() => import('@digix/electron/pages/portal/reset-password/resetPassword'));
const ResetOtpPassword = lazy(() => import('@digix/electron/pages/portal/reset-password/resetOtpPassword'));
const ForgotPassword = lazy(() => import('@digix/electron/pages/portal/reset-password/forgotPassword'));

// Pages (guest)
const Homepage = lazy(() => import('@digix/electron/pages/homepage'));
const DigixStory = lazy(() => import('@digix/electron/pages/about'));
const Partnership = lazy(() => import('@digix/electron/pages/partners'));
const Ecosystem = lazy(() => import('@digix/electron/pages/ecosystem'));
const SellDgx = lazy(() => import('@digix/electron/pages/sell-dgx'));
const ReferralProgram = lazy(() => import('@digix/electron/pages/referral-program'));
const TermsAndConditionsPage = lazy(() => import('@digix/electron/pages/tnc'));
const AssetDetailsMobile = lazy(() => import('@digix/electron/pages/assets/details'));
const AssetsMobile = lazy(() => import('@digix/electron/pages/assets'));
const Promo = lazy(() => import('@digix/electron/pages/promo'));
const PublicNotice = lazy(() => import('@digix/electron/pages/public-notice'));

// Pages (logged in)
const Dashboard = lazy(() => import('@digix/electron/pages/dashboard'));
const PersonalDetails = lazy(() => import('@digix/electron/pages/personal-details'));
const Transactions = lazy(() => import('@digix/electron/pages/dashboard/transactions'));
const WalletPage = lazy(() => import('@digix/electron/pages/wallet'));
const KycWizard = lazy(() => import('@digix/electron/components/kyc'));

// Compliance (KYC Details)
const KycDetail = lazy(() => import('@digix/electron/components/compliance/details/details-v2'));

const MOBILE_BREAKPOINT = breakpoints.sm;

const history = createBrowserHistory();
history.listen(location => {
  Analytics.gaPageView(location);
  Analytics.fbPageView();
  const { keystore, user } = store.getState();
  const isWalletPage = location.hash.substr(2) === 'wallet';
  const walletLoader = get(keystore, 'showWalletLoader') || {};
  const walletLoaderType = walletLoader.type || null;
  if (walletLoader.show && (isWalletPage || walletLoaderType === WALLET_LOADER_TYPE.edit)) {
    store.dispatch(createKeystore({
      ethAddress: '',
      hdPath: null,
      showWalletLoader: { show: false },
      walletType: '',
    }));
  }
  if (user.accountAction === 'DEACTIVATE') {
    store.dispatch(resetReduxState());
    endUserSession({
      currentUser: true,
      history,
      onResetReduxState: resetReduxState,
    });
  }
});

const ROUTES = {
  dashboardPages: [
    {
      component: Dashboard,
      path: '/dashboard',
    },
    {
      component: PersonalDetails,
      path: '/personal-details',
    },
    {
      component: WalletPage,
      hasRestriction: true,
      path: '/wallet',
    },

    {
      component: Transactions,
      hasRestriction: true,
      path: '/transactions',
    },
  ],
  portalPages: [
    {
      component: Login,
      path: '/portal/login',
    },
    {
      component: LoginVerification,
      path: '/portal/verify/:challengeId',
    },
    {
      component: ResetPassword,
      path: '/portal/forgot-password/reset-form',
    },
    {
      component: ResetOtpPassword,
      path: '/portal/forgot-password/reset-otp-form',
    },
    {
      component: ForgotPassword,
      path: '/portal/forgot-password/',
    },
    {
      component: RegisterFormV2,
      path: '/portal/v2/register',
    },
  ],
  staticPages: [
    {
      component: DigixStory,
      path: '/about-us',
    },
    {
      component: Partnership,
      path: '/partners',
    },
    {
      component: Ecosystem,
      path: '/ecosystem',
    },
    {
      component: SellDgx,
      path: '/sell-dgx',
    },
    {
      component: ReferralProgram,
      path: '/referral-program',
    },
    {
      component: TermsAndConditionsPage,
      path: '/terms-and-conditions',
    },
    {
      component: AssetsMobile,
      path: '/assets',
    },
    {
      component: AssetDetailsMobile,
      path: '/assets/:assetId',
    },
    {
      component: Promo,
      path: '/promo/:PromoId',
    },
  ],
};
class ElectronApp extends PureComponent {
  componentDidMount() {
    this.renderKlaroScripts();

    history.listen(() => window.scrollTo(0, 0));
    window.addEventListener('click', this.loadAnalytics);
    window.addEventListener('resize', this.handleWindowResize);
    if (getCookie('digix')) {
      Analytics.initFacebook();
      Analytics.initGoogleAnalytics();
      Analytics.gaPageView(history.location);
      Analytics.fbPageView();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentUser } = this.props;
    if (!prevProps.currentUser && currentUser) {
      checkLoginSession({ listenToStorage: true });
      refreshUserToken(true);
      checkForInactivity(null, null, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.loadAnalytics);
    checkLoginSession({ listenToStorage: false });
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    const { isMobile, onResize } = this.props;
    if (window.innerWidth < MOBILE_BREAKPOINT && !isMobile) {
      onResize(true);
    }
    if (window.innerWidth >= MOBILE_BREAKPOINT && isMobile) {
      onResize(false);
    }
  };

  loadAnalytics = ({ target }) => {
    const elementId = target.id || '';
    if (['cm-accept', 'cn-accept'].includes(elementId)) {
      Analytics.initFacebook();
      Analytics.initGoogleAnalytics();
      Analytics.gaPageView(history.location);
      Analytics.fbPageView();
    }
  };

  renderKlaroScripts = () => {
    const scriptNames = ['klaro.config', 'klaro'];
    scriptNames.forEach(scriptName => {
      const script = document.createElement('script');
      script.defer = true;
      script.src = `${window.location.pathname}${scriptName}.js`;
      document.body.appendChild(script);
    });
  };

  renderDashboardPages = () => {
    const { currentUser } = this.props;
    const isUserPending = get(currentUser, 'status') === KYC_V2_USER_STATUS.pending;
    const routes = ROUTES.dashboardPages.filter(({
      hasRestriction,
    }) => (hasRestriction && !isUserPending && !SHOW_PUBLIC_NOTICE(get(currentUser, 'email')))
      || !hasRestriction);
    const filteredRoutes = routes.map(({ component, path }, i) => (
      <Route
        component={component}
        key={`dash-${i}`}
        path={path}
      />
    ));
    return filteredRoutes;
  };

  renderPortalPages = () => {
    const routes = ROUTES.portalPages.map(({ component, path }, i) => (
      <Route
        component={component}
        key={`portal-${i}`}
        path={path}
      />
    ));
    return routes;
  };

  renderStaticPages = () => {
    const { currentUser } = this.props;
    let routes = null;
    if (!SHOW_PUBLIC_NOTICE(get(currentUser, 'email'))) {
      routes = ROUTES.staticPages.map(({ component, path }, i) => (
        <Route
          component={component}
          key={`page-${i}`}
          path={path}
        />
      ));
    }
    return routes;
  };

  render() {
    const {
      currentUser,
      showSessionTimeoutWarning,
      snackbar: {
        visible,
        variant,
        duration,
        text,
        position,
      },
    } = this.props;
    const isKycOfficer = get(currentUser, 'isKycOfficer', false);
    const isUserPending = get(currentUser, 'status') === KYC_V2_USER_STATUS.pending;
    const showPublicNotice = SHOW_PUBLIC_NOTICE(get(currentUser, 'email'));
    return (
      <ThemeProvider theme={Light}>
        <Suspense fallback={(
          <Loader
            color="primary"
            kind="grid"
          />
        )}
        >
          <GlobalStyle />
          <AppContainer>
            <Snackbar
              duration={duration}
              history={history}
              position={position}
              text={text}
              variant={variant}
              visible={visible}
            />
            <SessionTimeout isVisible={showSessionTimeoutWarning} />
            <Switch>
              {/* Homepage */}
              <Route
                component={showPublicNotice ? PublicNotice : Homepage}
                exact
                path="/"
              />
              {this.renderStaticPages()}
              {this.renderPortalPages()}
              {this.renderDashboardPages()}
              {isKycOfficer && (
                <Route
                  component={KycDetail}
                  path="/kyc/:kycId"
                />
              )}
              <Route component={showPublicNotice ? PublicNotice : NoRouteMatch} />
            </Switch>
          </AppContainer>
        </Suspense>
      </ThemeProvider>
    );
  }
}

const { bool, object } = PropTypes;

ElectronApp.propTypes = {
  currentUser: object,
  isMobile: bool.isRequired,
  onResize: func.isRequired,
  showSessionTimeoutWarning: bool.isRequired,
  snackbar: object.isRequired,
};

ElectronApp.defaultProps = {
  currentUser: undefined,
};

const mapStateToProps = ({ resize, snackbar, user }) => ({
  isMobile: resize.isMobile,
  showSessionTimeoutWarning: user.showSessionTimeoutWarning,
  snackbar,
});

const mapDispatchToProps = dispatch => ({
  onResize(isMobile) {
    dispatch(setIsMobile(isMobile));
  },
});

export default withCurrentUser(
  connect(mapStateToProps, mapDispatchToProps)(ElectronApp),
);
