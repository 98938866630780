const BLUE_DARK = "#131f35";
const BLUE_LIGHT = "#243961";
const GOLD_DARK = "#e3c88e";
const GOLD_LIGHT = "#c2a059";

const BIG_STONE = '#121e34';
const SAN_MARINO = '#446bb2';
const BLUE_WATER = '#1f3a6c';

const CREAM_CAN = '#f5cd65';
const DARK_CREAM_CAN = '#e1bf67';

const PUERTO_RICO = '#3dc8c3';
const ELM = '#227c79';

const BIG_STONE_RGB_002 = '250, 251, 251'; // FAFBFB
const SAN_MARINO_RGB_007 = '241 244 249';
const TUNA = '#32363d';
const TUNA_RGB = '50, 54, 61';
const GRAY_RGB = '98, 104, 116'; // 626874
const GRAY_RGB_07 = '216, 216, 216';
const GRAY_RGB_06 = '225, 225, 226';
const GRAY_RGB_05 = '238, 240, 240';
const GRAY_RGB_04 = '247, 247, 247';
const GRAY_RGB_03 = '250, 250, 250';
const RED_RGB = '236, 19, 0';

const BLACK = '#000000';
const WHITE = '#ffffff';

const RED = '#ec1300';
const GREEN = '#67c62d';
const YELLOW = '#ffd054';

const BLUE = '#5875ff';
const BLUE_GREEN = '#0cb9bb';
const CYAN_BLUE_LIGHT = '#f4f6fa';

const BIG_STONE002_RGB = `rgb(${GRAY_RGB_04})`;

const TUNA60_RGBA = `rgba(${TUNA_RGB}, 0.6)`;
const TUNA30_RGBA = `rgba(${TUNA_RGB}, 0.3)`;
const GRAY100_RGBA = `rgba(${GRAY_RGB}, 1.0)`;
const GRAY80_RGBA = `rgba(${GRAY_RGB}, 0.8)`;
const GRAY70_RGBA = `rgba(${GRAY_RGB}, 0.7)`;
const GRAY60_RGBA = `rgba(${GRAY_RGB}, 0.6)`;
const GRAY50_RGBA = `rgba(${GRAY_RGB}, 0.5)`;
const GRAY40_RGBA = `rgba(${GRAY_RGB}, 0.4)`;
const GRAY30_RGBA = `rgba(${GRAY_RGB}, 0.3)`;
const GRAY20_RGBA = `rgba(${GRAY_RGB}, 0.2)`;
const GRAY15_RGBA = `rgba(${GRAY_RGB}, 0.15)`;
const GRAY10_RGBA = `rgba(${GRAY_RGB}, 0.1)`;
const GRAY07_RGB = `rgb(${GRAY_RGB_07})`;
const GRAY06_RGB = `rgb(${GRAY_RGB_06})`;
const GRAY05_RGBA = `rgba(${GRAY_RGB}, 0.05)`;
const GRAY05_RGB = `rgb(${GRAY_RGB_05})`;
const GRAY04_RGB = `rgb(${GRAY_RGB_04})`;
const GRAY03_RGBA = `rgba(${GRAY_RGB}, 0.03)`;
const GRAY03_RGB = `rgb(${GRAY_RGB_03})`;
const RED05_RGBA = `rgba(${RED_RGB}, 0.05)`;

export const colors = {
  brand: {
    color1: BLUE_DARK,
    color2: BLUE_LIGHT,
    color3: GOLD_DARK,
    color4: GOLD_LIGHT,
  },

  primary: {
    base: BIG_STONE,
    light: SAN_MARINO,
    lighter: BLUE_WATER,
    lightest: CYAN_BLUE_LIGHT,
  },
  secondary: {
    base: PUERTO_RICO,
    light: ELM,
  },
  accent: {
    dark: DARK_CREAM_CAN,
    base: CREAM_CAN,
  },
  neutral: {
    white: WHITE,
    black: BLACK,

    darkGray100: TUNA,
    darkGray60: TUNA60_RGBA,
    darkGray30: TUNA30_RGBA,
    gray100: GRAY100_RGBA,
    gray80: GRAY80_RGBA,
    gray70: GRAY70_RGBA,
    gray60: GRAY60_RGBA,
    gray50: GRAY50_RGBA,
    gray40: GRAY40_RGBA,
    gray30: GRAY30_RGBA,
    gray20: GRAY20_RGBA,
    gray15: GRAY15_RGBA,
    gray10: GRAY10_RGBA,
    gray07: GRAY07_RGB,
    gray06: GRAY06_RGB,
    gray05: GRAY05_RGBA,
    gray05_rgb: GRAY05_RGB,
    gray04: GRAY04_RGB,
    gray03: GRAY03_RGBA,
    gray03_rgb: GRAY03_RGB,

    lightGray002: BIG_STONE002_RGB,
  },
  semantic: {
    error: RED,
    warning: YELLOW,
    success: GREEN,
    info: GRAY100_RGBA,
    muted: TUNA60_RGBA,
    disabled: TUNA30_RGBA,
  },
  warning: {
    red05: RED05_RGBA,
  },

  // Start: DEV NOTE -- For Deletion, DO NOT USE.
  darkest: TUNA,
  darker: TUNA,
  dark: TUNA,
  base: GRAY100_RGBA,
  semi: GRAY100_RGBA,
  light: GRAY100_RGBA,

  status: {
    default: BLACK,
    info: GRAY100_RGBA,
    success: GREEN,
    warning: YELLOW,
    error: RED,
  },
  // End: DEV NOTE -- For Deletion, DO NOT USE.
};
