import { UI_SITE } from '@digix/electron/config/endpoints';
import { electronClient } from '@digix/electron/api/graphql';
import { getAuthErrorFromResponse } from '@digix/electron/utils/validation';
import { SESSION_TIMEOUT_EXPIRY, SESSION_TIMEOUT_EXPIRY_WARNING } from '@digix/electron/utils/constants';
import { getPurchaseConfig, refreshToken, signOut } from '@digix/electron/api/queries/electron';
// eslint-disable-next-line sort-imports
import { setAuthStatus } from '~/actions/auth-status';
import { showSessionTimeoutWarning } from '~/actions/user';
import store from '~/store';

// Add delay to refresh interval to avoid bug where refreshing the user token right after the page loads
// will cause a 401 error and log the user out because the page might be in the middle of sending requests
// to initialize the page using the old token e.g. fetching assets, pricefeed, etc.
const MIN_REFRESH_INTERVAL = 30 * 1000;

// Refresh user token 3 min before token expiration
const REFRESH_ADVANCE = 3 * 60 * 1000;

let REFRESH_USER_TOKEN_TIMEOUT;
let INACTIVITY_TIMEOUT;
let INACTIVITY_TIMEOUT_WARNING;

const getTimeUntilExpiry = (sessionExpiry) => {
  const timeUntilExpiry = sessionExpiry - Date.now() - REFRESH_ADVANCE;
  const timeUntilExpiryOverride = process.env.SESSION_EXPIRY_OVERRIDE;
  return timeUntilExpiryOverride || timeUntilExpiry;
};

export const checkPurchaseConfig = () => new Promise(resolve => electronClient
  .query({ query: getPurchaseConfig })
  .then(({ data }) => resolve(data.config))
  .catch(() => resolve({
    canPurchase: false,
  })));

export const refreshUserToken = (checkCurrentToken) => {
  let sessionExpiry;
  let timeUntilExpiry;

  // CASE: After login
  // Function is called twice: during login and during App::componentDidUpdate
  // We can skip the second call since the first one should already set the interval
  if (checkCurrentToken && REFRESH_USER_TOKEN_TIMEOUT !== undefined) {
    return;
  }

  // CASE: Refresh after login; Load page with an existing user token
  // Function is called once by App::componentDidUpdate
  // Set the interval, but do not make the request to refresh the token
  // Otherwise we will encounter the 401 bug related to MIN_REFRESH_INTERVAL (see above)
  if (checkCurrentToken && REFRESH_USER_TOKEN_TIMEOUT === undefined) {
    sessionExpiry = localStorage.getItem('token-expiry');
    timeUntilExpiry = getTimeUntilExpiry(sessionExpiry);
    let refreshTimeout = Math.max(timeUntilExpiry, MIN_REFRESH_INTERVAL);

    clearTimeout(INACTIVITY_TIMEOUT);
    clearTimeout(INACTIVITY_TIMEOUT_WARNING);

    if (!localStorage.getItem('last-reload')) {
      refreshTimeout = MIN_REFRESH_INTERVAL;
      localStorage.setItem('last-reload', new Date().toUTCString());
    }

    REFRESH_USER_TOKEN_TIMEOUT = setTimeout(() => refreshUserToken(false), refreshTimeout);

    return;
  }

  // CASE: When interval is already set by prior situations
  // Make request to refresh token + set next interval based on exp
  electronClient.mutate({ mutation: refreshToken })
    .then((response) => {
      const { exp, jwt, websocketToken } = response.data.refreshToken.authorization;

      sessionExpiry = exp * 1000;
      timeUntilExpiry = getTimeUntilExpiry(sessionExpiry);
      localStorage.setItem('user-token', jwt);
      localStorage.setItem('token-expiry', sessionExpiry);
      localStorage.setItem('websocket-token', websocketToken);

      clearTimeout(INACTIVITY_TIMEOUT);
      clearTimeout(INACTIVITY_TIMEOUT_WARNING);
      REFRESH_USER_TOKEN_TIMEOUT = setTimeout(() => {
        refreshUserToken(false);
      }, Math.max(timeUntilExpiry, MIN_REFRESH_INTERVAL));
    });
};

// Called during: login, update password, confirm password before editing personal details
export const setUserToken = ({ exp, jwt, websocketToken }) => {
  const sessionExpiry = exp * 1000;
  const timeUntilExpiry = getTimeUntilExpiry(sessionExpiry);

  localStorage.setItem('user-token', jwt);
  localStorage.setItem('token-expiry', sessionExpiry);
  localStorage.setItem('websocket-token', websocketToken);

  clearTimeout(REFRESH_USER_TOKEN_TIMEOUT);
  REFRESH_USER_TOKEN_TIMEOUT = setTimeout(() => {
    refreshUserToken(false);
  }, Math.max(timeUntilExpiry, MIN_REFRESH_INTERVAL));
};

export const resetUserToken = () => {
  localStorage.removeItem('last-reload');
  localStorage.removeItem('user-token');
  localStorage.removeItem('token-expiry');
  localStorage.removeItem('websocket-token');

  clearTimeout(INACTIVITY_TIMEOUT);
  clearTimeout(INACTIVITY_TIMEOUT_WARNING);
  clearTimeout(REFRESH_USER_TOKEN_TIMEOUT);
  INACTIVITY_TIMEOUT = setTimeout(() => {}, SESSION_TIMEOUT_EXPIRY);
  INACTIVITY_TIMEOUT_WARNING = setTimeout(() => {}, SESSION_TIMEOUT_EXPIRY_WARNING);

  window.onload = undefined;
  document.onmousemove = undefined;
  document.onkeypress = undefined;
};

export const endUserSession = ({
  currentUser,
  history,
  onResetReduxState,
  path,
}) => {
  if (!currentUser) {
    return;
  }

  const handleSignOut = () => {
    resetUserToken();
    store.dispatch(setAuthStatus());
    if (onResetReduxState) {
      onResetReduxState();
    }

    const isLogOut = [null, undefined].includes(path);
    let urlPath = isLogOut
      ? '/'
      : path;

    const isSessionExpired = urlPath.split('?')[urlPath.split('?').length - 1] === 'expired_session=true';

    if (isLogOut) {
      if (!document.hidden) {
        const hasUrlParams = window.location.href.indexOf('?') > 0;
        const currentUrl = hasUrlParams
          ? window.location.href.slice(0, window.location.href.indexOf('?'))
          : window.location.href;
        const currentPage = currentUrl.split('/')[currentUrl.split('/').length - 1];
        const staticPages = ['ecosystem', 'about-us', 'partners', 'terms-and-conditions'];

        if (staticPages.includes(currentPage)) {
          urlPath = `/${currentPage}`;
        }
      }

      history.replace({
        pathname: urlPath,
        state: {
          logout: isLogOut,
        },
      });

      clearTimeout(INACTIVITY_TIMEOUT);
      clearTimeout(INACTIVITY_TIMEOUT_WARNING);
      return;
    }

    if (isSessionExpired || history) {
      history.replace(urlPath);
      clearTimeout(INACTIVITY_TIMEOUT);
      clearTimeout(INACTIVITY_TIMEOUT_WARNING);

      return;
    }

    window.location.replace(`${UI_SITE}/#${urlPath}`);
    window.location.reload();
  };

  return electronClient
    .mutate({ mutation: signOut })
    .then(() => handleSignOut())
    .catch(error => {
      if (error && error.graphQLErrors) {
        const authError = getAuthErrorFromResponse(error.graphQLErrors);
        if (authError && !authError.isAuthenticated) {
          handleSignOut();
        }
      }
    });
};

export const checkForInactivity = (history, onResetReduxState, checkCurrentToken) => {
  // CASE: After login
  // Function is called twice: during login and during App::componentDidUpdate
  // We can skip the second call since the first one should already set the interval
  if (checkCurrentToken && INACTIVITY_TIMEOUT !== undefined && INACTIVITY_TIMEOUT_WARNING !== undefined) {
    return;
  }
  const expireSession = () => {
    endUserSession({
      currentUser: true,
      history,
      onResetReduxState,
      path: '/portal/login?expired_session=true',
    });
  };

  const warnUser = () => {
    const isLoggedIn = !!(localStorage.getItem('user-token'));
    store.dispatch(showSessionTimeoutWarning(isLoggedIn));
    if (isLoggedIn) {
      window.onload = undefined;
      document.onmousemove = undefined;
      document.onkeypress = undefined;
    }
  };

  const resetTimer = () => {
    if (!localStorage.getItem('user-token')) {
      resetUserToken();
    } else {
      clearTimeout(INACTIVITY_TIMEOUT);
      clearTimeout(INACTIVITY_TIMEOUT_WARNING);
      INACTIVITY_TIMEOUT = setTimeout(expireSession, SESSION_TIMEOUT_EXPIRY);
      INACTIVITY_TIMEOUT_WARNING = setTimeout(warnUser, SESSION_TIMEOUT_EXPIRY_WARNING);
    }
  };

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;

  if (history) {
    history.listen(resetTimer);
  }
};

export const checkLoginSession = ({ listenToStorage }) => {
  const { addEventListener, location, removeEventListener } = window;
  const onBeforeReload = () => {
    if (localStorage.getItem('last-reload')) {
      localStorage.removeItem('last-reload');
    }
  };
  if (listenToStorage) {
    addEventListener('beforeunload', onBeforeReload);
  } else {
    removeEventListener('beforeunload', onBeforeReload);
  }
};

export const authStatusHandler = () => {
  const { authStatus } = store.getState();
  const { isLoggedOut, hasNewSession } = authStatus;
  const hasUrlParams = window.location.href.indexOf('?') > 0;
  const currentUrl = hasUrlParams
    ? window.location.href.slice(0, window.location.href.indexOf('?'))
    : window.location.href;
  const currentPage = currentUrl.split('/')[currentUrl.split('/').length - 1];
  const staticPages = ['', 'ecosystem', 'about-us', 'partners', 'terms-and-conditions'];
  if (hasNewSession || (isLoggedOut && document.hidden)) {
    if (staticPages.includes(currentPage)) {
      window.location.reload();
      return;
    }
    if (currentPage !== 'login' && !hasUrlParams) {
      window.location.replace(`${window.location.origin}/#/portal/login?invalid_token=true`);
    }
    if (hasNewSession) {
      window.location.reload();
    }
  }
};
