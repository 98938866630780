import BigNumber from 'big-number';

/* eslint-disable sort-keys */
const isProduction = process.env.ENVIRONMENT === 'production';
const isStaging = process.env.ENVIRONMENT === 'kovan';
const isDevelopment = !isProduction && !isStaging;
const contractOverride = process.env.CONTRACT_OVERRIDE;

export const ONE_BILLION = 1000000000;
export const PRICE_RATIO = 1e15;
export const PRICE_RATIO_ERC20 = 1e9;
export const LOCAL_STORAGE_LNG_KEY = 'i18nextLng';
export const LEDGER_RELOAD_ERRORS = ['DEVICE_INELIGIBLE', 'TIMEOUT'];
export const LEDGER_TX_TIMEOUT = 120000; // 2 minutes
export const DEFAULT_GAS = '2000000';
export const DEFAULT_GAS_LIMIT = '2000000';
export const DEFAULT_GAS_LIMIT_APPROVAL = '300000';
export const DEFAULT_GAS_LIMIT_RECAST = '3000000';
export const DEFAULT_GAS_LIMIT_TOPUP = '1500000';
export const MAX_GAS_RECAST = '1500000';
export const BROADCAST_RETRIES = 0;
export const MAX_DECIMALS_FOR_BIG_NUMBER = 30;
export const MAX_DGX_DECIMALS = 9;
export const MAX_USDC_USDT_DECIMALS = 3;
export const MIN_PURCHASE_DGX = 0.11;
export const MIN_DGX_GOLD_REDEMPTION = 101;
export const TWO_FACTOR_CODE_LENGTH = 6;
export const INSUFFICIENT_FUNDS_REGEX = [/Insufficient funds/, /sender doesn't have enough funds/, /does not have enough funds/];
export const DEACTIVATED_ACCOUNT_REGEX_API_MESSAGE = /You have been deactivated/;
export const TERMINATED_ACCOUNT_REGEX_API_MESSAGE = /account has been terminated/;
export const INACTIVE_ACCOUNT_REGEX_API_MESSAGE = /email is inactive/;
export const ACCOUNT_BLOCKED_REGEX_API_MESSAGE = /^Try again after (\d+) milliseconds/;
export const ACCOUNT_LOCKED_REGEX_API_MESSAGE = /^Account is locked. Please check (\S+) to reset your password$/;
export const ACCOUNT_UNVERIFIED_REGEX_API_MESSAGE = /^Please check (\S+) to verify your digix account/;
export const RESEND_CODE_BLOCKED_REGEX_API_MESSAGE = /Challenge is renewed too many times/;
export const CAPTCHA_INVALID_ERROR = 'Captcha Invalid';
export const BLOCKED_COUNTRY_ERROR = 'While waiting for legal and regulatory clearance';
export const INVALID_CREDENTIALS_ERROR = 'Invalid username or password';
export const RESET_REQUIRED_ERROR = 'You are required to reset';
export const CHALLENGE_NOT_FOUND_ERROR = 'Challenge is no longer valid';
export const INVALID_CODE_ERROR = 'code is invalid';
export const ACTION_THROTTLED_ERROR = 'Action is throttled/limited';
export const NOT_AVAILABLE = 'N/A';
export const INVALID_TOKEN_API_ERROR_MESSAGE = 'token is invalid/expired/used';
export const COMPROMISED_API_ERROR_MESSAGES = [
  'password is compromised',
  'password cannot be reused',
];

export const DEFAULT_SMART_GAS = {
  fast: 20,
  slow: 10,
  standard: 15,
};

// eslint-disable-next-line newline-per-chained-call
export const MAX_INTEGER = (new BigNumber(2).pow(256).minus(1)).toString();
export const EMAIL_REGEX = /^([\w\\.%\\+\\-]+)@([\w\\-]+\.)+([\w]{2,})$/i;
export const PASSWORD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)/;
export const NON_EMPTY_STRING_REGEX = /^(?!\s*$).+/;
export const EXTRACT_EMAIL_REGEX = /([\w\\.%\\+\\-]+@[\w\\-]+\.[\w]{2,})/gi;
export const ETH_ADDRESS_REGEX = /0x[a-fA-F0-9]{40}/g;
export const TWO_FACTOR_CODE_REGEX = /^[0-9]*$/;
export const MIN_PWD_CHAR = 8;
export const MAX_PWD_CHAR = 40;

export const SESSION_TIMEOUT_EXPIRY = isDevelopment
  ? (process.env.SESSION_TIMEOUT_EXPIRY || 4 * 60 * 60 * 1000) // use env value or default to 4 hours
  : 30 * 60 * 1000; // 30 minutes
export const SESSION_TIMEOUT_EXPIRY_WARNING = SESSION_TIMEOUT_EXPIRY / 2;

export const ENABLE_KYC_V2 = process.env.ENABLE_KYC_V2;

export const ENABLE_CAPTCHA = isDevelopment || isStaging ? process.env.ENABLE_CAPTCHA : true;

export const EMAILS_WITH_ACCESS = process.env.EMAILS_WITH_ACCESS.split(',');

export const ENABLE_PUBLIC_NOTICE = process.env.ENABLE_PUBLIC_NOTICE;

export const SHOW_PUBLIC_NOTICE = (email = '') => ENABLE_PUBLIC_NOTICE && !EMAILS_WITH_ACCESS.includes(email);

export const CAPTCHA_API_KEY = (isProduction && '6LeQtfwUAAAAAG0g61EOKyBucNMWDSt_Cfl4amdf')
  || (isStaging && '6Le8FvYUAAAAAO60TFWUtOkJD3s1Bn37CotP0PT_')
  || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';

export const GUEST_ROUTE_PATHS = [
  'about-us',
  'assets',
  'ecosystem',
  'partners',
  'portal',
  'terms-and-conditions',
];

export const WALLET_CHANGE_CHECKS = {
  alreadyRequested: 'ALREADY_REQUESTED',
  actionLimited: 'ACTION_LIMITED',
  remintableAssets: 'ASSETS_REMINTABLE',
};

export const TWO_FACTOR_AUTHENTICATION_ACTIONS = {
  changeEmail: 'REQUEST_EMAIL_CHANGE',
  changePassword: 'REQUEST_PASSWORD_CHANGE',
  reactivateAccount: 'REACTIVATE_ACCOUNT',
  requestDeactivation: 'REQUEST_DEACTIVATION',
};

export const DEACTIVATION_REASONS = {
  cheaperOption: 'CHEAPER_OPTION',
  complicated: 'COMPLICATED',
  notEffective: 'NOT_EFFECTIVE',
  strictCompliance: 'STRICT_COMPLIANCE',
  unsatisfactory: 'UNSATISFACTORY_SUPPORT',
};

export const DEFAULT_CAPTCHA_CODE = 'en';

export const LANGUAGES = [
  {
    name: 'English',
    value: 'en',
    captchaCode: DEFAULT_CAPTCHA_CODE,
  },
  {
    name: '中文',
    value: 'cn',
    captchaCode: 'zh-CN',
  },
  {
    name: 'Melayu',
    value: 'ms',
    captchaCode: 'ms',
  },
  {
    name: 'Indonesian',
    value: 'id',
    captchaCode: 'id',
  },
];

export const ASSET_EVENTS = {
  1: 'Created Vendor Order',
  2: 'Created Transfer Order',
  3: 'Created Replacement Ord',
  4: 'Fulfilled Vendor Order',
  5: 'Fulfilled Transfer Order',
  6: 'Fulfilled Replacement Ord',
  7: 'Custodian Accepted, Minted',
  8: 'Custodian Accepted, Minted placement',
  9: 'Recasted',
  10: 'Redeemed',
  11: 'Audit Faid',
  12: 'Admin Failed',
  13: 'Reminted',
};

export const CSV_EXPORT_FIELDS = {
  address: 'ADDRESS',
  email: 'EMAIL',
  name: 'NAME',
  industry: 'INDUSTRY',
  occupation: 'OCCUPATION',
  incomeSource: 'INCOME_SOURCE',
  phoneNumber: 'PHONE_NUMBER',
  createdAt: 'CREATED_AT',
  birthday: 'BIRTHDATE',
  expirationDate: 'EXPIRATION_DATE',
  identificationNumber: 'IDENTIFICATION_NUMBER',
  nationality: 'NATIONALITY',
  refNumber: 'RECORD_ID',
  status: 'STATUS',
  ethAddress: 'ETH_ADDRESS',
};

export const KYC_ALLOWED_FILE_TYPES = ['image/gif', 'image/jpeg', 'image/png'];
export const KYC_ALLOWED_FILE_TYPES_LABEL = KYC_ALLOWED_FILE_TYPES.map(i => i.replace('image/', '*.')).join(', ');
export const KYC_ID_TYPES = ['IDENTITY_CARD', 'PASSPORT'];
export const KYC_MAX_FILE_SIZE = 10000000; // 10 MB
export const KYC_MAX_FILE_SIZE_LABEL = '10 MB';
export const KYC_MIN_FILE_SIZE = 0;

export const KYC_STATUS = {
  drafted: 'DRAFTED',
  submitted: 'SUBMITTED',
  approved: 'APPROVED',
  finalized: 'FINALIZED',
  expired: 'EXPIRED',
  rejected: 'REJECTED',
};

export const KYC_V2_STATUS = {
  new: 'NEW',
  // Status right after submitting KYC in Ares
  // Waiting to be marked as PENDING
  processing: 'PROCESSING',
  // Internal status that means KYC has just beem submitted to Artemis
  // From electron user's perspective, same as PENDING
  // draft: 'DRAFT',
  // KYC is ready for review in Artemis
  pending: 'PENDING',
  // Internal status signalling additional review for Artemis users
  // From electron user's perspective, same as PENDING
  // Electron user needs to re-submit form?
  reactivated: 'REACTIVATED',
  rejected: 'REJECTED',
  accepted: 'ACCEPTED',
  // Expired
  updateRequired: 'REQUIRE_UPDATE',
  requestChanges: 'REQUEST_CHANGES',
  expired: 'EXPIRED',
  expiring: 'EXPIRING',
  suspended: 'SUSPENDED',
  terminated: 'TERMINATED',
};

export const KYC_V2_USER_STATUS = {
  pending: 'PENDING',
  active: 'ACTIVE',
  reactivated: 'REACTIVATED',
  suspended: 'SUSPENDED',
  terminated: 'TERMINATED',
};

export const KYC_TIER = {
  tier1: 'TIER_1',
  tier2: 'TIER_2',
};

export const CHANGE_WALLET_ADDRESS_STATUS = {
  approved: 'APPROVED',
  broadcasted: 'BROADCASTED',
  finalized: 'FINALIZED',
  pending: 'PENDING',
  rejected: 'REJECTED',
  unconfirmed: 'UNCONFIRMED',
};

export const TRANSACTION_TYPES = {
  buyDgx: 'BUY_DGX',
  recast: 'RECAST',
  redeem: 'REDEEM',
  remint: 'REMINT',
};

export const TRANSACTION_STATUS = {
  none: 'NONE',
  pending: 'PENDING',
  success: 'SUCCESS',
  failure: 'FAILURE',
  dropped: 'DROPPED',
};

export const WALLET_TYPE = {
  json: 'JSON_KEYSTORE',
  metamask: 'METAMASK',
  ledger: 'LEDGER',
  trezor: 'TREZOR',
};

const {
  json,
  metamask,
  ledger,
  trezor,
} = WALLET_TYPE;

export const WALLET_TYPE_OPTIONS = [
  {
    name: 'Json',
    value: json,
  },
  {
    name: 'Metamask',
    value: metamask,
  },
  {
    name: 'Ledger',
    value: ledger,
  },
  {
    name: 'Trezor',
    value: trezor,
  },
];

export const BROWSER_TYPE = {
  opera: {
    name: 'OPERA',
    disabled_wallet: [trezor, metamask],
  },
  firefox: {
    name: 'FIREFOX',
    disabled_wallet: [ledger],
  },
  chrome: {
    name: 'CHROME',
    disabled_wallet: [],
  },
  safari: {
    name: 'SAFARI',
    disabled_wallet: [trezor, metamask, ledger],
  },
  unlisted: {
    name: 'UNLISTED',
    disabled_wallet: [],
  },
};

export const AUDIT_TRAIL_ACTIONS = [
  {
    code: 'ACCOUNT_ACTIVATED',
    key: 'activated',
  },
  {
    code: 'ACCOUNT_CONFIRMED',
    key: 'accountConfirmed',
  },
  {
    code: 'ACCOUNT_DEACTIVATED',
    key: 'deactivated',
  },
  {
    code: 'ACCOUNT_DEACTIVATION_REQUESTED',
    key: 'deactivationRequested',
  },
  {
    code: 'ACCOUNT_EMAIL_CHANGED',
    key: 'emailChanged',
  },
  {
    code: 'ACCOUNT_PASSWORD_CHANGED',
    key: 'passwordChanged',
  },
  {
    code: 'ACCOUNT_PASSWORD_RESET',
    key: 'passwordReset',
  },
  {
    code: 'ACCOUNT_REACTIVATED',
    key: 'reactivated',
  },
  {
    code: 'ACCOUNT_REGISTERED',
    key: 'signedUp',
  },
  {
    code: 'ACCOUNT_SIGNED_IN',
    key: 'signedIn',
  },
  {
    code: 'KYC_REGISTERED',
    key: 'kycRegistered',
  },
  {
    code: 'KYC_STATUS_UPDATED',
    key: 'kycStatusChanged',
  },
  {
    code: 'KYC_SUBMITTED',
    key: 'kycSubmitted',
  },
  {
    code: 'WALLET_CHANGE_APPROVED',
    key: 'walletApproved',
  },
  {
    code: 'WALLET_CHANGE_CONFIRMED',
    key: 'walletChangeConfirmed',
  },
  {
    code: 'WALLET_CHANGE_REJECTED',
    key: 'walletRejected',
  },
  {
    code: 'WALLET_CHANGE_REQUESTED',
    key: 'walletChangeRequested',
  },
];

export const WALLET_LOADER_TYPE = {
  edit: 'EDIT_WALLET',
  register: 'REGISTER_WALLET',
  load: 'LOAD_WALLET',
};

export const NETWORK_VERSIONS = ['1', '42', '3', '4', '5', 'LOCAL'];

export const NETWORK_VERSION = () => {
  switch (contractOverride) {
    case 'mainnet':
      return NETWORK_VERSIONS[0];
    case 'kovan':
      return NETWORK_VERSIONS[1];
    default:
      break;
  }

  if (isProduction) {
    return NETWORK_VERSIONS[0];
  }

  if (isStaging) {
    return NETWORK_VERSIONS[1];
  }

  return NETWORK_VERSIONS[NETWORK_VERSIONS.length - 1];
};

export const REJECTION_REASON_GROUPS = Object.freeze({
  idProof: 'ID_PROOF',
  other: 'OTHER',
  residenceProof: 'RESIDENCE_PROOF',
  webcamProof: 'POSE_PROOF',
});

export const CURRENCY_SYMBOLS = {
  erc20: 'ERC_20',
  eth: 'ETH',
  dgx: 'DGX',
  usdt: 'USDT',
  usdc: 'USDC',
  pax: 'PAX',
  xbt: 'XBT',
};

export const TOKEN_DECIMAL_PLACE = {
  ETH: 1e18,
  DAI: 1e18,
  DGX: 1e9,
  USDT: 1e6,
  USDC: 1e6,
  PAX: 1e18,
};

export const CURRENCIES = [
  {
    currency: CURRENCY_SYMBOLS.eth,
    disabled: false,
  },
  {
    currency: CURRENCY_SYMBOLS.usdt,
    disabled: false,
  },
  {
    currency: CURRENCY_SYMBOLS.usdc,
    disabled: false,
  },
  {
    currency: CURRENCY_SYMBOLS.pax,
    disabled: false,
  },
];

export const CONTRACTS = {
  MarketplaceControllerElectron: 'MarketplaceControllerElectron',
  MarketplaceElectron: 'MarketplaceElectron',
};

export const ASSET_STATES = Object.freeze({
  RECAST_REQUESTED: 'RECAST_REQUESTED',
  RECASTED: 'RECASTED',
  REDEEMED: 'REDEEMED',
  REMINT_REQUESTED: 'REMINT_REQUESTED',
  REMINTED: 'REMINTED',
});
