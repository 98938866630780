import { format } from 'date-fns';
import { enUS, id, ms, zhCN } from 'date-fns/locale';

const locales = {
  enUS,
  id,
  ms,
  zhCN,
};

const localizationAdapter = (locale) => {
  switch (locale) {
    case 'en':
      return locales.enUS;
    case 'cn':
      return locales.zhCN;
    case 'ms':
      return locales.ms;
    case 'id':
      return locales.id;
    default:
      return locales.enUS;
  }
};

// eslint-disable-next-line import/prefer-default-export
export const getAllMonths = (locale, isAbbreviated) => {
  const months = [];

  const monthNameFormat = isAbbreviated
    ? 'MMM'
    : 'MMMM';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 12; i++) {
    const date = format(
      new Date(1990, i, 1),
      monthNameFormat,
      {
        locale: localizationAdapter(locale),
      },
    );
    months.push(date);
  }

  return months
    .map((name, k) => ({
      name,
      value: k + 1,
    }));
};
