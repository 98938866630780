import styled, { css } from 'styled-components';
import { Button, color } from '@digix/electron/shared';

export const NavDropdown = {
  Container: styled.div`
    align-items: center;
    border: 1px solid ${props => props.theme.colors.neutral.gray50};
    display: flex;
    flex: 0 1 auto;
    padding: 1rem;
    position: relative;
  `,

  List: styled.ul`
    box-shadow: 0rem 0.3rem 1rem ${props => props.theme.colors.neutral.gray30};
    display: ${props => (props.show ? 'block' : 'none')};
    font-size: ${props => props.theme.typography.fontSize.sm};
    left: 0;
    position: absolute;
    top: 5rem;
    width: 100%;
    z-index: 10;
  `,

  Option: styled.li`
    background-color: ${props => color('inverted')};
    cursor: pointer;
    font-family: ${props => props.theme.typography.typeface.primary.regular};
    font-size: ${props => props.theme.typography.fontSize.sm};
    letter-spacing: ${props => props.theme.typography.letterSpacing.wide}rem;
    padding: 1.6rem 1.6rem 1.6rem 2.4rem;
    text-align: left;

    span {
      color: ${props => color('primary')};
    }

    div {
      margin: 0;

      input {
        width: 100%;
      }

      label {
        left: 3.5rem;
      }
    }

  `,

  SelectedOption: styled(Button)`
    align-items: center;
    color: ${props => (props.theme.language.normal.selectedText)};
    display: flex;
    font-family: ${props => props.theme.typography.typeface.primary.regular};
    font-size: ${props => props.theme.typography.fontSize.sm};
    height: 100%;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    position: relative;
    text-transform: none;
    width: 100%;

    & > div {
      height: 1.6rem;
      margin-left: 0.8rem;
      transform: rotate(90deg);
      width: 1.6rem;

      svg {
        fill: ${props => color('primary')};
        stroke: ${props => color('primary')};
      }
    }

    &:hover {
      color: ${props => (props.theme.language.normal.selectedText)};
      & > div {
        svg {
          fill: ${props => color('primary')};
          stroke: ${props => color('primary')};
        }
      }
    }
  `,
};
