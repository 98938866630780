import styled, { css } from 'styled-components';

const CommonHeadingStyles = css`
  --font-family: ${props =>
    (props.type === 'serif' && 'var(--font-serif)')
    || (props.type === 'sans' && 'var(--font-sans)')
    || 'var(--default-heading-font)'
  };
  --color: ${props =>
    (props.accent && 'var(--color-accent)')
    || (props.inverse && 'var(--color-inverse)')
    || (props.primary && 'var(--color-primary)')
  };
  --text-transform: ${props => props.uppercase ? 'uppercase' : 'inherit'};
  color: var(--color);
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  letter-spacing: var(--letter-spacing, var(--letter-spacing-base));
  line-height: var(--line-height);
  margin-bottom: var(--margin-bottom);
  text-transform: var(--text-transform);
`;

export const H1 = styled.h1`
  ${CommonHeadingStyles};
`;

export const H2 = styled.h2`
  ${CommonHeadingStyles};
`;

export const H3 = styled.h3`
  ${CommonHeadingStyles};
`;

export const H4 = styled.h4`
  ${CommonHeadingStyles};
`;

export const H5 = styled.h5`
  ${CommonHeadingStyles};
`;

export const H6 = styled.h6`
  ${CommonHeadingStyles};
`;
