import { actions } from '~/actions/snackbar';

const initialState = {
  duration: 'long',
  position: 'top',
  text: '',
  variant: 'success',
  visible: false,
};

const snackbar = (state = initialState, action) => {
  switch (action.type) {
    case actions.SHOW_SNACKBAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default snackbar;
