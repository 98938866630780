import React from 'react';

const GoldTransferable = props => (
  <div {...props}>
    <svg viewBox="0 0 140 110"><g fill="none" fillRule="evenodd"><path stroke="#091831" strokeWidth="1.5" d="M60.87 1l8.26 8.005L60.87 17"/><path d="M18 23c0-7.71 5.737-14 12.748-14H69M76.305 109l-8.61-8 8.61-8" stroke="#091831" strokeWidth="1.5"/><path d="M121 87c0 7.71-5.969 14-13.245 14H68" stroke="#091831" strokeWidth="1.5"/><path fill="#FFF" d="M136.18 76.72L130.154 63h-16.329L106 80.927l18.979.018h1.973l4.356.01L138 81z"/><path stroke="#FFD25F" strokeWidth="1.5" d="M136.18 76.72L130.154 63h-16.329L106 80.927l18.979.018h1.973l4.356.01L138 81z"/><path fill="#FFF" d="M32.18 43.72L26.144 30H9.826L2 47.927l25.299.027L34 48z"/><path stroke="#FFD25F" strokeWidth="1.5" d="M32.18 43.72L26.144 30H9.826L2 47.927l25.299.027L34 48z"/><path d="M71.5 85V26.5M101 56H43M62 27.5c-.08.107-6 7.578-6 26.654 0 19.232 6.44 29.278 6.5 29.346M80 27c4.667 6.51 7 16.257 7 29.244 0 19.48-6.5 28.256-6 27.256" stroke="#091831" strokeWidth="1.5"/><path d="M71.5 85C55.235 85 42 71.772 42 55.495 42 39.228 55.235 26 71.5 26S101 39.228 101 55.495C101 71.772 87.765 85 71.5 85z" stroke="#091831" strokeWidth="1.5"/><path d="M99.5 65.5c-.313.263-7.333 6.5-26.628 6.5S43.803 65.263 43.5 65M44 44.5c.304-.218 9.384-5.5 28.76-5.5 19.365 0 24.685 5.151 26.24 6" stroke="#091831" strokeWidth="1.5"/></g></svg>
  </div>
);

GoldTransferable.defaultProps = {};

export default GoldTransferable;
