import styled from 'styled-components';

const SessionTimeoutStyles = {
  Container: styled.div``,
  Cta: styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;

    & > button {
      margin: 0;
      margin-top: 2rem;
      min-width: 12.4rem;
      width: fit-content;

      :first-child {
        margin-right: 1.2rem;
      }
    }
  `,
};

export default SessionTimeoutStyles;
