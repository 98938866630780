// eslint-disable-next-line import/prefer-default-export
export const getCookie = cookieName => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${cookieName}=`);
  if (parts.length === 2) {
    return parts.pop()
      .split(';')
      .shift();
  }
};
