import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from '~/translations';

const namespaces = ['Header', 'Homepage', 'Portal'];

const options = {
  resources,
  fallbackLng: 'en',
  debug: process.env.ENVIRONMENT === 'development',
  ns: namespaces,
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false,
  },
};

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(options, () => {
    i18n.t('Header');
    i18n.t('Homepage');
    i18n.t('Portal');
  });

export default i18n;
