import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const DigixYellow = props => (
  <Container {...props}>
    <Icon viewBox="0 0 24 23">
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Menu-Opened-4" transform="translate(-1733.000000, -133.000000)" fill="#F9D167">
          <g id="Group-6" transform="translate(1733.000000, 133.000000)">
            <polygon id="Path" points="11.6366542 11.2561164 17.5967783 16.7891777 11.6366542 22.32282 5.67594894 16.7891777"></polygon>
            <polygon id="Path" points="11.6366542 -5.06261699e-14 23.2727273 11.3752257 19.0408997 15.5120939 11.6366542 8.27315551 4.2318276 15.5120939 3.26849658e-13 11.3763877"></polygon>
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

export default DigixYellow;
