import React from 'react';
import { Icon } from '@digix/electron/shared';
import { TextButton } from '@digix/electron/shared/button/text/styles';
import { object, string, node, oneOfType } from 'prop-types';

const Text = ({ kind, icon, text, ...props }) => (
  <TextButton icon={icon} kind={kind} {...props}>
    {props.children}
    {text && text}
    {icon && <Icon name={icon} kind={kind} size={icon === 'close' ? 'xsmall' : null} />}
  </TextButton>
);

Text.propTypes = {
  children: oneOfType([object, string, node]),
};

Text.defaultProps = {};

export default Text;
