import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Commision = props => (
  <Container {...props}>
    <Icon viewBox="0 0 103 121" >
      <g stroke="#F9D167" strokeWidth="2" fill="none" fillRule="evenodd">
        <path strokeLinejoin="round" d="M52 114l-7-7M64 110L52 97M74 104L60 89M28.1 113.697a4.22 4.22 0 006.01 0l3.776-3.812c1.606-1.622 1.194-4.274-.676-6.162-1.874-1.892-4.5-2.308-6.105-.686 1.604-1.622 1.194-4.272-.678-6.162-1.874-1.893-4.499-2.307-6.103-.685 1.604-1.622 1.194-4.271-.68-6.161-1.872-1.893-4.496-2.307-6.103-.687 1.607-1.62 1.194-4.272-.678-6.162-1.872-1.89-4.498-2.306-6.103-.684l-3.778 3.812a4.323 4.323 0 000 6.068L28.1 113.697z" />
        <path d="M35.835 111.952l1.323 1.354 3.247 3.278c4.958 5.003 9.584 1.322 11.97-2.828 4.54 2.362 9.614-.249 11.418-3.812 5.744 2.864 10.774-3.064 10.362-6.098 6.205-.369 10.376-7.491 6.118-13.248M1.53 74.786l4.538 4.75c.483 1.673 1.6 3.471 2.767 4.904M30.435 61.544l-7.648-7.723M17.542 89.343l-6.675 6.956M17.542 89.343l-6.675 6.956M24.323 96.19l-6.674 6.956M31.105 103.037l-6.675 6.956" strokeLinejoin="round" />
        <path d="M12.154 51.186h-8.5V1.84h97.75v49.346H78.03s-7.658 6.921-8.455 7.046c-2.318.362-12.718-4.57-14.82-5.096-2.1-.526-23.056 6.192-24.612 8.738-1.555 2.551 4.633 8.572 10.594 7.806 5.958-.764 14.81-3.965 17.388-1.36 4.182 4.222 16.382 17.142 22.147 22.278C85.106 94.908 99.28 72.64 99.28 72.64" />
        <path d="M78.03 42.604h8.5c0-3.555 2.853-6.436 6.374-6.436v-19.31c-3.52 0-6.375-2.88-6.375-6.436h-68c0 3.555-2.125 6.437-6.375 6.437v19.309c3.521 0 6.375 2.881 6.375 6.436h17" />
        <path strokeLinejoin="round" d="M27.029 27.586h4.25M71.654 27.586h4.25M42.7 31.872a11.84 11.84 0 01-.797-4.297c.005-5.923 4.29-10.723 9.571-10.72 5.285.001 9.563 4.807 9.56 10.73a11.806 11.806 0 01-.798 4.292" />
      </g>
    </Icon>
  </Container>
);

export default Commision;
