import React from 'react'
import ReactModal from 'react-responsive-modal';
import { bool, func } from 'prop-types';

const Modal = ({
  isActive,
  center,
  children,
  handleClose,
}) => (
  <ReactModal
    open={isActive}
    onClose={handleClose}
    center={center}
    styles={{
      overlay: {
        padding: 0,
      },
      modal: {
        padding: '3.2rem',
        maxWidth: '60rem',
        minWidth: '38rem',
        width: '100%',
      },
    }}
  >
    {children}
  </ReactModal>
);


Modal.propTypes = {
  isActive: bool.isRequired,
  handleClose: func.isRequired,
  center: bool,
};

Modal.defaultProps = {
  center: false,
};

export default Modal;
