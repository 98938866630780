import styled, { css } from 'styled-components';
import { color } from '@digix/electron/shared';

const Svg = css`
  fill: ${props => props.theme.icon.inactive};
  height: 100%;
  min-width: 1rem;
  transition: fill ${props => props.theme.transition};
  width: 100%;

  svg {
    fill:  ${props => color(props.kind)};
    stroke: ${props => color(props.kind)};
  }
`;

export const Container = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin: 0.4rem;
  margin-left: 0.8rem;
  outline: 0;
  padding: 0;
  transition: all ${props => props.theme.transition};

  ${props => props.customSize ? css`
    height: ${props.customSize.height};
    width: ${props.customSize.width};
  ` : css`
    height: ${props =>
      (props.size === 'xsmall' && props.theme.icon.size.xsmall)
      || (props.size === 'small' && props.theme.icon.size.small)
      || (props.size === 'medium' && props.theme.icon.size.medium)
      || (props.size === 'large' && props.theme.icon.size.large)
      || (props.size === 'xlarge' && props.theme.icon.size.xlarge)
      || props.theme.icon.size.small}rem;
    width: ${props =>
      (props.size === 'xsmall' && props.theme.icon.size.xsmall)
      || (props.size === 'small' && props.theme.icon.size.small)
      || (props.size === 'medium' && props.theme.icon.size.medium)
      || (props.size === 'large' && props.theme.icon.size.large)
      || (props.size === 'xlarge' && props.theme.icon.size.xlarge)
      || props.theme.icon.size.small}rem;
    `}

  svg {
    fill:  ${props => color(props.kind)};
    stroke: ${props => color(props.kind)};

    /* stylelint-disable-next-line */
    ${props => props.disabled && css`
      fill: var(--text-muted);
      stroke: var(--text-muted);
    `}
  }
`;

export const Icon = styled.svg`
  ${Svg};
  width: 100%;
`;
