import { TRANSACTION_STATUS } from '@digix/electron/utils/constants';
import { actions } from '~/actions/transaction';

const initialState = {
  isPurchasingDgx: false,
  txStatus: TRANSACTION_STATUS.none,
};

const transaction = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_TXSTATUS:
      return {
        ...state,
        txStatus: action.payload,
      };
    case actions.TOGGLE_IS_PURCHASING:
      return {
        ...state,
        isPurchasingDgx: action.payload,
      };
    default:
      return state;
  }
};

export default transaction;
