
import { css } from 'styled-components';
import { typography } from '@digix/electron/themes/base/typography';
import { spacing } from '@digix/electron/themes/base/spacing';

/* 1. Defining in Global Scope */

export const globalCss = css`

  :root, [data-theme="default"] {
    ${spacing};

    ${typography};

    --body-font-regular: FuturaPT-Book, Helvetica, sans-serif ;
    --body-font-medium: FuturaPT-Medium, Helvetica, sans-serif;
    --body-font-semibold: FuturaPT-Demi, Helvetica, sans-serif;
    --body-font-bold: FuturaPT-Heavy, Helvetica, sans-serif;
    --heading-font: "Playfair Display", "Times New Roman", serif;

    --breakpoint-mobile: 576;
    --breakpoint-tablet: 768;
    --breakpoint-tablet-large: 0;
    --breakpoint-standard: 992;
    --breakpoint-wide: 1200;

    --hue: 219;
    --hue-secondary: 43;
    --saturation: 10%;
    --lightness: 100%;
    --alpha: 1;

    --brand-color-1: hsl(var(--hue), 49%, 14%);
    --brand-color-2: hsl(var(--hue), 46%, 26%);
    --brand-color-3: hsl(41, 60%, 72%);
    --brand-color-4: hsl(41, 46%, 55%);

    --color-neutral-0: hsl(var(--hue), var(--saturation), calc(var(--lightness) * 1));
    --color-neutral-1: hsl(var(--hue), var(--saturation), 10%);
    --color-neutral-2: hsl(var(--hue), var(--saturation), 20%);
    --color-neutral-3: hsl(var(--hue), var(--saturation), 30%);
    --color-neutral-4: hsl(var(--hue), var(--saturation), 40%);
    --color-neutral-5: hsl(var(--hue), var(--saturation), 50%);
    --color-neutral-6: hsl(var(--hue), var(--saturation), 60%);
    --color-neutral-7: hsl(var(--hue), var(--saturation), 70%);
    --color-neutral-8: hsl(var(--hue), var(--saturation), 80%);
    --color-neutral-9: hsl(var(--hue), var(--saturation), 90%);
    --color-neutral-10: hsl(var(--hue), var(--saturation), 100%);

    --color-primary: hsl(var(--hue), 49%, 14%);
    --color-secondary: hsl(var(--hue), 45%, 48%);
    --color-accent: hsl(var(--hue-secondary), 88%, 68%);
    --color-inverse: var(--color-neutral-0);

    /* Accents & States */
    --disabled: hsl(var(--hue), var(--saturation), 69%);
    --error: hsl(5, 100%, 46%);
    --info: hsl(var(--hue), 10%, 22%);
    --success: hsl(var(--hue), 10%, 22%);
    --warning: hsl(var(--hue-secondary), 100%, 66%);

    --hover: var(--color-secondary);
    --focus: var(--color-secondary);
    --active: var(--color-secondary);

    --border-radius: 0;
    --gradient-from: var(--brand-color-2);
    --gradient-to: var(--color-primary);
    --linear-gradient: linear-gradient(135deg, var(--gradient-from) 0%, var(--gradient-to) 60%);

    --grayscale-dark: hsl(var(--hue), var(--saturation), 22%);

    --box-shadow: hsl(var(--hue), var(--saturation), 92%);
    --disabled: hsl(var(--hue), var(--saturation), 78%);
    --transition: .2s ease-in-out;
    
    --color: var(--color-neutral-2);
    --text-muted: var(--color-neutral-9);

    --default-body-font: var(--body-font-regular);
    --default-heading-font: var(--body-font-semibold);

    --padding: 1.6rem;
    --margin: 1.6rem;
    --border-width: 0.1rem;
    --border-color: var(--color-neutral-9);

    /* stylelint-disable-next-line */
    color: var(--color, hsl(219, '10%', '22%'));
  }
`;
