import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Photo = (props) => (
  <Container {...props}>
    <Icon viewBox="0 0 126 77">
      <g fill="none" fillRule="evenodd" opacity=".6">
        <g transform="translate(-1002 -510)" stroke={props.stroke}>
          <g transform="translate(1003 511)">
            <path d="m53.733 17.567c0-4.0298-3.3076-7.2333-7.2341-7.2333-4.0294 0-7.2326 3.2035-7.2326 7.2333 0 4.0298 3.2032 7.2333 7.2326 7.2333s7.2341-3.0992 7.2341-7.2333z" />
            <polyline points="62 36.633 55.397 27.905 42.066 45.467 16.023 11.367 6.2 24.295" />
            <polyline points="49.709 54.767 -7.1054e-15 54.767 0.19535 -1.0658e-14 77.5 -1.0658e-14 77.5 25.365" />
            <polyline points="81.953 20.667 124 20.667 124 75.433 41.333 75.433 41.333 59.03" />
            <path d="m70.267 49.083c0-5.3494 3.2525-9.94 7.888-11.902 1.5466-0.65311 3.2438-1.0137 5.0279-1.0137 7.135 0 12.917 5.7824 12.917 12.916 0 7.135-5.7824 12.917-12.917 12.917-7.1335 0-12.916-5.7824-12.916-12.917z" />
            <path d="m102.3 30.483h10.333" />
          </g>
        </g>
      </g>
    </Icon>
  </Container >
);

export default Photo;
