import styled, { css } from 'styled-components';
import { Link as StyledLink } from 'react-router-dom';

export const show = css`
  display: none;
  visibility: hidden;
`;

export const hide = css`
  display: block;
  visibility: visible;
`;

export const Alert = styled.div`
  color: ${props => props.theme.notification.info.fontColor};
  font-size: ${props => props.theme.typography.fontSize.xs};
  line-height: ${props => props.theme.typography.lineHeight.xxs}rem;
  margin: 0.4rem 0;
  padding: 0;
  text-align: left;

  ${props =>
    props.kind === 'info' &&
    css`
      color: ${props => props.theme.notification.info.fontColor};
    `};

  ${props =>
    props.kind === 'error' &&
    css`
      color: ${props => props.theme.notification.error.fontColor};
    `};

  ${props =>
    props.kind === 'success' &&
    css`
      color: ${props => props.theme.notification.success.fontColor};
    `};

  ${props =>
    props.kind === 'warning' &&
    css`
      color: ${props => props.theme.notification.warning.fontColor};
    `};
`;

export const Caption = styled.small`
  color: ${props => (props.kind === 'muted' && props.theme.label.muted) 
  || props.theme.label.normal};
  font-size: ${props => props.theme.typography.fontSize.xs};
  line-height: ${props => props.theme.typography.lineHeight.xs};
  margin: 0.4rem 0;
`;

export const CtaButton = styled.div`
  margin-top: 3rem;
`;

export const Divider = styled.hr`
  border: 0;
  border-bottom: 1px solid ${props => props.theme.divider.normal};
  margin: 2.4rem 0;
`;

export const Form = styled.div`
  margin-top: 3rem;
`;

export const FormGroup = styled.div``;

export const FieldGroup = styled.div``;

export const FieldItem = styled.div`
  margin-bottom: 4rem;
  text-align: ${props => (props.align === 'right' ? 'right' : 'left')};
  transition: margin-bottom 0.25s ease-in-out;

  ${props => props.password && css`
    margin-bottom: 5.6rem;
  `}
`;

export const Label = styled.label`
  color: ${props => (props.kind === 'muted' && props.theme.label.muted) 
    || props.theme.label.normal};
  display: block;
  font-family: ${props => props.theme.typography.typeface.regular};
  font-size: ${props => (props.size === 'small' && props.theme.typography.fontSize.xs) 
  || props.theme.typography.fontSize.md};
  line-height: ${props => (props.size === 'small' && props.theme.typography.lineHeight.xs) 
  || props.theme.typography.lineHeight.md}rem;
  margin-bottom: ${props => (props.size === 'small' && 0.8) || 1.6 }rem;
`;

export const Link = styled(StyledLink)`
  align-items: ${props => (props.alignItems ? props.alignItems : 'unset')};
  color: ${props =>
    (props.kind === 'primary' && props.theme.link.textColor.primary.base) ||
    (props.kind === 'secondary' && props.theme.link.textColor.secondary.base) ||
    (props.kind === 'inverted' && props.theme.link.textColor.neutral.white) ||
    props.theme.link.textColor.primary.light};
  display: ${props => (props.display ? props.display : 'inline-block')};
  font-size: ${props =>
    props.size === 'small'
      ? props.theme.typography.fontSize.xs
      : props.theme.typography.fontSize.sm};
  font-family: ${props =>
    (props.weight === 'thin' && props.theme.typography.typeface.regular) ||
    (props.weight === 'bold' && props.theme.typography.typeface.bold) ||
    props.theme.typography.typeface.regular};
  margin: 0.25rem 0;
`;

export const List = styled.ul``;
export const ListItem = styled.li``;

export const Svg = css`
  fill: ${props => props.theme.icon.inactive};
  height: 100%;
  transition: fill ${props => props.theme.transition};
  width: 100%;
`;
