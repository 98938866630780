/*
 * This stylesheet exports the Sidenav styled component.
 *
 * =============================================================================
 * The hierarchy for each component is as follows:
 *
 * Wrapper (nav)
 *   IconWrapper (div)
 *     Icon
 *   List (ul)
 *     Item (li)
 *       HyperLink/Route
 *
 * =============================================================================
 * The following are the available props for certain components:
 *
 * Wrapper
 *   isExpanded: boolean; if true, expands the sidenav width
 *
 * IconWrapper
 *   isExpanded: boolean; if true, aligns icon to the left
 *
 * Item
 *   isExpanded: boolean; if true, shows the link
 *
 */
import { Link } from 'react-router-dom';
import React from 'react';
import styled, { css } from 'styled-components';
import { zindex, color, fontWeight, Icon } from '@digix/electron/shared';

const NavLink = props => {
  const customProps = ['active', 'isDashboard', 'isExpanded'];
  const linkProps = {};
  Object.keys(props).filter(key => {
    if (!customProps.includes(key)) {
      linkProps[key] = props[key];
    }
  });
  return <Link {...linkProps} />
};

const LinkStyles = css`
  align-items: center;
  display: flex;
  font-family: ${fontWeight('semibold')};
  opacity: ${props => props.isExpanded ? 1 : 0};
  position: relative;
  visibility: ${props => props.isExpanded ? 'visible' : 'hidden'};

  & > div {
    margin: 0 2.4rem 0 0;
  }
`;

const ItemLink = styled.a`
  color: ${color('primary')};
  margin-left: 0.8rem;
  ${LinkStyles}
`;

const ItemRoute = styled(NavLink)`
  ${LinkStyles}
`;

const Sidenav = {
  SidebarWrapper: styled.nav`
    background: ${color('primary')};
    bottom: 0;
    box-shadow: ${props => props.theme.boxShadow};
    cursor: pointer;
    height: 100%;
    max-width: 24rem;
    overflow-y: none;
    padding: 0rem 1.6rem 4rem 3.2rem;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(${props => props.isExpanded ? 0 : props.noDesktopSidebar ? 100 : 65}%);
    transition: transform 0.25s;
    width: 100%;
    z-index: ${zindex('sidebar')};

    ul {
      margin-left: 0rem;
      margin-top: 4.8rem;
    }

    li > a {
      color: ${color('primary')};
      font-family: ${fontWeight('normal')};
      font-size: 1.6rem;
      line-height: 6.4rem;
      margin-left: 0rem;
      transition: margin-left 0.25s ease-in-out, color 0.3s ease-in;
    }
  `,

  MenubarHeader: styled.div`
    margin-left: ${props => props.isExpanded ? 0 : -0.8}rem;
    margin-top: 6rem;
    text-align: left;
    transition: margin-left 0.1s ease-in-out;
  `,

  AvatarIcon: styled(Icon)`
    height: 3.2rem;
    margin: 0.4rem 0rem;
    width: 3.2rem;
    
    svg {
      stroke: ${color('inverted')};
    }
  `,

  Item: styled.li`
    padding: 0 1rem;
    padding-left: 0;
    position: relative;
    transition: color 0.5s ease-in;

    /* stylelint-disable-next-line */
    ${ItemLink}, ${ItemRoute}, ${ItemLink}:hover, ${ItemRoute}:hover {
      color: ${props => color(props.active ? 'secondary' : 'primary')};

      div svg {
        fill: ${props => color(props.active ? 'secondary' : 'primary')};
        stroke: ${props => color(props.active ? 'secondary' : 'primary')};
      }
    }
  `,

  SidebarItem: styled.li`
    padding: 0 1rem;
    padding-left: 0;
    position: relative;
    transition: color 0.5s ease-in;

    /* stylelint-disable-next-line */
    ${ItemLink}, ${ItemRoute}, ${ItemLink}:hover, ${ItemRoute}:hover {
      color: ${props => color(props.active ? 'accent' : 'inverted')};

      div svg {
        fill: ${props => color(props.active ? 'accent' : 'inverted')};
        stroke: ${props => color(props.active ? 'accent' : 'inverted')};
      }
    }
  `,

  RouterLink: styled(ItemRoute)`
    &[data-icon="brand"], &[data-icon="brand"]:hover {

      div svg {
        fill: ${color('accent')};
        stroke: ${color('accent')};
      }
    }
  `,

  HyperLink: styled(ItemLink)``,

  List: styled.ul`
    margin-top: 2rem;
  `,
};

export default Sidenav;