import React from 'react';
import { StyledCheckbox } from '@digix/electron/shared/checkbox/styles';
import { bool, string } from 'prop-types';

const { Input, Label, Wrapper, Box } = StyledCheckbox;

const CheckBox = ({
  center,
  corner,
  id,
  isChecked,
  isDisabled,
  isInvalid,
  isMultiOptions,
  fontSize,
  onChange,
  text,
  value,
  ...props
}) => (
  <Wrapper
    center={center}
    key={id}
    isMultiOptions={isMultiOptions}
  >
    <Input
      checked={isChecked}
      corner={corner}
      disabled={isDisabled}
      id={id}
      isMultiOptions={isMultiOptions}
      onChange={onChange}
      type="checkbox"
      value={value}
      {...props}
    />
    <Box
      isDisabled={isDisabled}
      isInvalid={isInvalid}
    />
    <Label
      checked={isChecked}
      disabled={isDisabled}
      fontSize={fontSize}
      htmlFor={id}
      isMultiOptions={isMultiOptions}
    >
      {text || value}
    </Label>
  </Wrapper>
);

CheckBox.propTypes = {
  isDisabled: bool,
  isInvalid: bool,
  isMultiOptions: bool,
  text: string,
};

CheckBox.defaultProps = {
  isDisabled: false,
  isInvalid: false,
  isMultiOptions: false,
};

export default CheckBox;
