export const actions = {
  SAVE_KYC_DASHBOARD_FILTERS: 'SAVE_KYC_DASHBOARD_FILTERS',
  SET_USER_ID: 'SET_USER_ID',
};

export const saveKycDashboardFilters = (filters) => ({
  payload: filters,
  type: actions.SAVE_KYC_DASHBOARD_FILTERS,
});

export const setUserId = userId => ({
  payload: userId,
  type: actions.SET_USER_ID,
});
