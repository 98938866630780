import { Button, media, zindex } from '@digix/electron/shared';
import styled, { css } from 'styled-components';

const handleBackgroundVariant = variant => {
  const backgroundVariant = ['error', 'success', 'warning'].includes(variant)
    ? variant : 'success';
  return props => props.theme.snackbar[backgroundVariant].background;
};

const handleColorVariant = variant => {
  const colorVariant = ['error', 'success', 'warning'].includes(variant)
    ? variant : 'success';
  return props => props.theme.snackbar[colorVariant].color;
};

const CloseButton = styled(Button)`
  cursor: pointer;
  margin-left: auto;

  ${media.xxs`
    padding: 0;
  `}
`;

const Text = styled.span`
  a {
    color: ${props => handleColorVariant(props.variant)};
    text-decoration: underline;
  }
`;

const Inner = styled.div`
  color: blue;
`;

const Wrapper = styled.div`
  height: auto;
  min-height: ${props => props.position === 'top' ? '4.8rem' : 'auto'};

  & ${Inner} {
    align-items: center;
    background-color: ${props => handleBackgroundVariant(props.variant)};
    display: flex;
    height: auto;
    justify-content: center;
    min-height: ${props => props.position === 'top' ? '4.8rem' : 'auto'};
    padding: 0 1.6rem;
    position: fixed;
    width: 100%;
    z-index: ${zindex('snackbar')};
    ${props => props.position}: 0;

    & ${Text} {
      color: ${props => handleColorVariant(props.variant)};
      margin-left: ${props => props.variant === 'warning' ? 'auto' : 'initial'};
    }
  }
`;

export const SnackbarStyles = {
  CloseButton,
  Inner,
  Text,
  Wrapper,
};
