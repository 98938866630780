import React from 'react';

const GoldTransferable = props => (
  <div {...props}>
    <svg viewBox="0 0 100 101"><g strokeWidth="1.5" fill="none" fillRule="evenodd"><path stroke="#FFD25F" d="M50.077 72.258L2 36.655 50.077 1 98 36.655z"/><path stroke="#091831" d="M93.052 55.466L51.04 86.113 8.928 54.731l4.282-3.257"/><path stroke="#091831" d="M93.052 69.361L51.156 99.97 8.928 68.833l4.384-3.503"/></g></svg>
  </div>
);

GoldTransferable.defaultProps = {};

export default GoldTransferable;
