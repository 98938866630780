import styled, { css } from 'styled-components';
import { media } from '@digix/electron/shared';
import { Icon } from '@digix/electron/components/common';

const CheckIcon = styled(Icon)`
  display: none;
  height: 2rem;
  margin: 0;
  width: 2rem;
`;

const RadioButton = styled.input`
  align-items: center;
  appearance: none;
  cursor: pointer;
  display: flex;
  height: 2.4rem;
  justify-content: center;
  margin: 0;
  outline: none;
  position: relative;
  transition: all 0.3s ease-out;
  width: 2.4rem;

  &::after {
    background: ${props => props.theme.radio.active.background};
    border: 1px solid ${props => props.theme.radio.active.borderColor};
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 2.4rem;
    text-align: center;
    width: 2.4rem;
  }

  &:checked::before {
    background: ${props => props.theme.radio.selected.background};
    border: 1px solid ${props => props.theme.radio.selected.borderColor};
    border-radius: 50%;
    content: '';
    height: 1.2rem;
    position: absolute;
    width: 1.2rem;
  }
  &:active {
    transform: scale(.75); 
  }
`;

export const OptionButton = {
  Wrapper: styled.div`
    align-items: center;
    display: ${props => props.isInline ? 'inline-block' : 'flex'};
    margin-bottom: 2rem;
    position: relative;

    & + & {
      ${props => props.type === 'search-filter' ? css`
        margin-left: 0;

        ${media.xxs`
          margin-left: 1.6rem;
        `}
    
        ${media.sm`
          margin-left: 0;
        `}
    
        ${media.md`
          margin-left: 5rem;
        `}
      ` : css`
        margin-left: ${props => !props.isInline && 'auto'
          || props.isNarrow && '0.8rem'
          || '5rem;'}
      `}
    }
  `,

  Label: styled.label`
    align-items: center;
    display: inline-flex;
    flex-direction: ${props => props.isLabelFirst
      ? 'row-reverse'
      : 'row'};
  `,

  Input: RadioButton,

  Text: styled.span`
    font-family: ${props => (props.selected ? props.theme.typography.typeface.regular : 'inherit')};
    font-size: ${props => props.theme.typography.fontSize.md};

    ${props => props.isMaxContent ? css`
      width: max-content;
      margin-right: 1.2rem;
    ` : css`
      margin-left: ${props => props.isNarrow ? '0.8rem' : '1.6rem'};
      margin-top: 0.25rem;
    `}
  `,
  Icon: CheckIcon,
};
