import NotFoundIcon from '@digix/electron/assets/icons/404';
import React from 'react';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Layout, Title } from '@digix/electron/containers/404/style';
import { Link, Paragraph } from '@digix/electron/shared';

const NoRouteMatch = ({ t }) => (
  <Container>
    <Layout>
      <NotFoundIcon />
      <Title
        color="primary"
        level="3"
        type="primary"
      >
        {t('404.title')}
      </Title>
      <Paragraph>{t('404.description')}</Paragraph>
      <Link
        icon="arrow"
        kind="secondary"
        role="link"
        text={t('404.linkText')}
        to="/"
        weight="normal"
      />
    </Layout>
  </Container>
);

NoRouteMatch.propTypes = {
  t: func.isRequired,
};

export default withTranslation('NotFound')(NoRouteMatch);
