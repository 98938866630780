import PDF from 'react-pdf-js';
import { get } from 'lodash';
import React, {
  Fragment,
  createRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ReactWebcam from 'react-webcam';
import StyledUploader from '@digix/electron/shared/file-uploader/styles';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from '@digix/electron/shared';
import {
  KYC_ALLOWED_FILE_TYPES,
  KYC_ALLOWED_FILE_TYPES_LABEL,
  KYC_MAX_FILE_SIZE,
  KYC_MAX_FILE_SIZE_LABEL,
  KYC_MIN_FILE_SIZE,
} from '@digix/electron/utils/constants';

import {
  any,
  arrayOf,
  bool,
  func,
  oneOf,
  number,
  shape,
  string,
} from 'prop-types';

const {
  BaseButton,
  ButtonGroup,
  ButtonLabel,
  ButtonWrapper,
  CloseButton,
  ErrorMessage,
  Instruction,
  Label,
  Preview,
  Wrapper,
  WebCamWrapper,
  FullScreenWebCamControlWrapper,
  RetakePicButton
} = StyledUploader;

const DELAY_BEFORE_CAPTURE = 3; // In seconds

const base64toBlob = (base64Data, type = '', sliceSize = 512) => {
  const byteCharacters = atob(base64Data);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, { type });;
};

const fileToBase64 = file => new Promise(resolve => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => resolve(fileReader.result);
  fileReader.onerror = () => resolve(null);
});

const hasMediaDevice = () => !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);

const FileUploader = ({
  allowedFileTypes,
  captureButtonLabel,
  error,
  errorMessage,
  instruction,
  isDisabled,
  isLoading,
  minSize,
  maxSize,
  noValidation,
  onSelectFile,
  selectedFile,
  t,
  title,
  type,
  uploadButtonLabel,
  webCamButtonLabel,
  withPreview,
}) => {
  const [showWebCam, setWebCamVisibility] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);
  const [countdownTimer, setCountdown] = useState(null);
  const [camPic, setCamPic] = useState(null);

  const inputFileRef = createRef();
  const previewRef = createRef();
  const takePicButtonRef = createRef();
  const webCamRef = createRef();

  const isInvalidSize = get(error, 'invalidSize', false);
  const isInvalidType = get(error, 'invalidType', false);
  const isEmpty = get(error, 'isEmpty', false);
  const hasError = isInvalidSize || isInvalidType || isEmpty;
  const isInitial = (!camPic && !selectedFile && (!showWebCam || !isFullscreen));
  const withWebCam = ['all', 'web-cam'].includes(type);

  useEffect(() => {
    const isFull = !!(document.fullscreenElement
      || document.webkitIsFullScreen
      || document.mozFullScreen
      || document.msFullscreenElement);
    if (countdownTimer === 0 && isFull) {
      handleImageCapture(webCamRef.current.getScreenshot());
    }
  });

  useEffect(() => {
    const events = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange'];
    events.forEach(event => document.addEventListener(event, handleFullScreenChange));
    return () => events.forEach(event => document.removeEventListener(event, handleFullScreenChange));
  }, []);

  const handleFullScreenChange = useCallback(() => {
    const isFull = !!(document.fullscreenElement
      || document.webkitIsFullScreen
      || document.mozFullScreen
      || document.msFullscreenElement);
    if (!isFull) {
      setWebCamVisibility(false);
      setFullscreen(false);
      setCountdown(null);
      handleFullScreenExit();
    }
  });

  const handleFileSelect = (file, withBase64) => {
    const isTypeValid = allowedFileTypes.includes(file.type);
    const isSizeValid = file.size > minSize && file.size <= maxSize;
    const isFileValid = isSizeValid && isTypeValid;
    const error = {
      invalidSize: noValidation ? false : !isSizeValid,
      invalidType: noValidation ? false : !isTypeValid,
    };
    if (!withBase64) {
      return fileToBase64(file).then(base64 => {
        const selectedFile = isFileValid || noValidation
          ? Object.assign(file, {
            dataUrl: base64,
          }) : null;
        onSelectFile({
          file: selectedFile,
          error,
        });
      });
    }
    onSelectFile({
      file,
      error,
    });
  };

  const handleFullScreenExit = () => {
    const isFull = !!(document.fullscreenElement
      || document.webkitIsFullScreen
      || document.mozFullScreen
      || document.msFullscreenElement);
    if (isFull) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setFullscreen(false);
  };

  const handleFullScreenRequest = () => {
    const isFull = !!(document.fullscreenElement
      || document.webkitIsFullScreen
      || document.mozFullScreen
      || document.msFullscreenElement);
    if (!isFull && previewRef.current) {
      const targetElement = previewRef.current;
      const permissionTimeout = () => {
        setWebCamVisibility(false);
        alert(t('FileUploader.Error.permissionTimeout'));
      };
      if (targetElement.requestFullscreen) {
        targetElement.requestFullscreen()
          .then(() => setFullscreen(true))
          .catch(permissionTimeout);
      } else if (targetElement.webkitRequestFullScreen) {
        targetElement.webkitRequestFullScreen()
          .then(() => setFullscreen(true))
          .catch(permissionTimeout);
      } else if (targetElement.mozRequestFullScreen) {
        targetElement.mozRequestFullScreen()
          .then(() => setFullscreen(true))
          .catch(permissionTimeout);
      } else if (targetElement.msRequestFullscreen) {
        targetElement.msRequestFullscreen()
          .then(() => setFullscreen(true))
          .catch(permissionTimeout);
      }
    }
  };

  const handleImageCapture = base64Image => {
    const block = base64Image.split(';');
    const contentType = get(block[0].split(':'), 1, null);
    const base64Data = get(block[1].split(','), 1, null);
    const file = Object.assign(base64toBlob(base64Data, contentType), {
      dataUrl: base64Image
    });
    setCamPic(file);
    setWebCamVisibility(false);
  };

  const handleMediaError = error => {
    setWebCamVisibility(false);
    handleFullScreenExit();
    if (typeof error === 'string' && error === 'getUserMedia not supported') {
      return alert(t('FileUploader.Error.notSupported'));
    }
    if (typeof error === 'object' && error.name === 'NotAllowedError') {
      alert(t('FileUploader.Error.permissionDenied'));
    }
  };

  const handleOpenCamera = () => {
    if (!hasMediaDevice()) {
      return alert(t('FileUploader.Error.notSupported'));
    }
    setWebCamVisibility(true);
  };

  const handleTakeScreenshotClick = (e, isRetake) => {
    if (e) {
      e.stopPropagation();
    }
    if (!!countdownTimer) {
      return;
    }
    if (isRetake) {
      setWebCamVisibility(true);
    }
    setCamPic(null);
    if (showWebCam || isRetake) {
      let n = 0;
      setCountdown(DELAY_BEFORE_CAPTURE);
      const timer = setInterval(() => {
        ++n;
        setCountdown(DELAY_BEFORE_CAPTURE - n);
        const isFull = !!(document.fullscreenElement
          || document.webkitIsFullScreen
          || document.mozFullScreen
          || document.msFullscreenElement);
        if (!isFull || n === DELAY_BEFORE_CAPTURE) {
          clearInterval(timer);
          setCountdown(null);
        }
      }, 1000);
    } else {
      setWebCamVisibility(false);
    }
  };

  const handleUserMediaCallback = () => {
    if (showWebCam) {
      return handleFullScreenRequest();
    }
    handleFullScreenRequest();
    handleTakeScreenshotClick();
  };

  const handleFullScreenConfirmClick = e => {
    e.stopPropagation();
    if (camPic) {
      handleFileSelect(camPic, true);
      inputFileRef.current.value = '';
    }
    exitFullscreen();
  };

  const exitFullscreen = () => {
    setWebCamVisibility(false);
    handleFullScreenExit();
  };

  const renderPreviewContent = () => {
    const isPdf = selectedFile && selectedFile.type === 'application/pdf';
    const fullScreenStyle = {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    };
    if (showWebCam || isFullscreen) {
      return (
        <Fragment>
          {(camPic && (!showWebCam || !isFullscreen)) && (
            <img
              alt='File'
              src={camPic.dataUrl}
              style={fullScreenStyle}
            />
          )}
          <WebCamWrapper isVisible={showWebCam && isFullscreen}>
            <ReactWebcam
              audio={false}
              ref={webCamRef}
              screenshotFormat='image/jpeg'
              onUserMedia={handleUserMediaCallback}
              onUserMediaError={handleMediaError}
              style={fullScreenStyle}
            />
          </WebCamWrapper>
        </Fragment>
      );
    }
    if (selectedFile) {
      return (
        <Fragment>
          {isPdf ? (
            <PDF
              file={selectedFile.dataUrl}
              page={1}
              scale={0.8}
            />
          ) : (
            <img
              alt='File'
              src={selectedFile.dataUrl}
              style={fullScreenStyle}
            />
          )}
        </Fragment>
      );
    }
    return null;
  };

  const fullScreenControllers = () => {
    if (!isFullscreen) {
      return null;
    }
    const conditionalTakePicButtonText = countdownTimer
      ? countdownTimer
      : showWebCam
        ? captureButtonLabel
        : t('FileUploader.Label.retake');
    return (
      <div>
        <CloseButton
          disabled={isDisabled || isLoading}
          icon="close"
          variant="contained"
          onClick={exitFullscreen}
          size="large"
          style={{
            marginLeft: '2rem',
            position: 'fixed',
            right: 0,
            top: 0,
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
            zIndex: 1,
            width: '13.7rem',
            height: '6.2rem',
            padding: 0
          }}
          text={t('FileUploader.Label.close')}
        />
        <FullScreenWebCamControlWrapper>
          <ButtonWrapper>
            {showWebCam && countdownTimer !== 'number' && (
              <BaseButton
                ref={takePicButtonRef}
                disabled={isDisabled || isLoading}
                variant="contained"
                onClick={handleTakeScreenshotClick}
                size="large"
                text={conditionalTakePicButtonText}
                style={{
                  justifyContent: 'center',
                  fontSize: countdownTimer
                    ? '2rem'
                    : '1.1rem'
                }}
              />
            )}
            {!showWebCam && (
              <RetakePicButton
                ref={takePicButtonRef}
                disabled={isDisabled || isLoading}
                variant="outlined"
                onClick={e => handleTakeScreenshotClick(e, true)}
                size="large"
                text={conditionalTakePicButtonText}
              />
            )}
          </ButtonWrapper>
          {!showWebCam && <ButtonWrapper>
            <Button
              disabled={isDisabled || isLoading}
              variant="contained"
              onClick={handleFullScreenConfirmClick}
              size="large"
              text={t('FileUploader.Label.confirm')}
              style={{
                marginLeft: '2rem'
              }}
            />
          </ButtonWrapper>
          }
        </FullScreenWebCamControlWrapper>
      </div>
    );
  };

  return (
    <Wrapper>
      {title && (
        <Label>{title}</Label>
      )}
      {(withPreview || withWebCam) && (
        <Preview
          hasError={hasError}
          isEmpty={isInitial}
          ref={previewRef}
        >
          {fullScreenControllers()}
          {renderPreviewContent()}
          {isInitial && (
            <Instruction>{instruction || t('FileUploader.Label.instruction')}</Instruction>
          )}
        </Preview>
      )}
      <input
        accept={allowedFileTypes.toString()}
        data-digix="Choose-File"
        onChange={e => handleFileSelect(e.target.files[0])}
        ref={inputFileRef}
        type="file"
      />
      {hasError && (
        <ErrorMessage
          kind="error"
          role="alert"
        >
          {isInvalidType && (errorMessage.type || t('FileUploader.Error.invalidFileType', {
            type: KYC_ALLOWED_FILE_TYPES_LABEL,
          }))}
          {isInvalidSize && (errorMessage.size || t('FileUploader.Error.invalidFileSize', {
            size: KYC_MAX_FILE_SIZE_LABEL,
          }))}
          {isEmpty && (errorMessage.empty || t('FileUploader.Error.required'))}
        </ErrorMessage>
      )}
      <ButtonGroup>
        {type !== 'web-cam' && (
          <ButtonWrapper>
            <Button
              disabled={isDisabled || isLoading}
              variant="outlined"
              onClick={() => inputFileRef.current.click()}
              size="large"
              text={uploadButtonLabel || t('FileUploader.Label.upload')}
            >
              {isLoading && (
                <ButtonLabel>
                  <Icon
                    name="spinner"
                    size="small"
                  />
                </ButtonLabel>
              )}
            </Button>
          </ButtonWrapper>
        )}
        {withWebCam && (
          <ButtonWrapper>
            <Button
              disabled={isDisabled || isLoading}
              variant="outlined"
              onClick={handleOpenCamera}
              size="large"
              text={captureButtonLabel || t('FileUploader.Label.capture')}
            />
          </ButtonWrapper>
        )}
      </ButtonGroup>
    </Wrapper >
  );
};

FileUploader.propTypes = {
  allowedFileTypes: arrayOf(oneOf(KYC_ALLOWED_FILE_TYPES)).isRequired,
  captureButtonLabel: string,
  error: shape({
    invalidSize: bool,
    invalidType: bool,
  }),
  errorMessage: shape({
    empty: string,
    size: string,
    type: string,
  }),
  instruction: string,
  typeErrMsg: string,
  isDisabled: bool,
  isLoading: bool,
  maxSize: number,
  minSize: number,
  noValidation: bool,
  onSelectFile: func.isRequired,
  selectedFile: any,
  t: func.isRequired,
  title: string,
  type: oneOf(['all', 'upload', 'web-cam']),
  uploadButtonLabel: string,
  webCamButtonLabel: string,
  withPreview: bool,
};

FileUploader.defaultProps = {
  captureButtonLabel: '',
  error: {
    invalidSize: false,
    invalidType: false,
  },
  errorMessage: {
    empty: '',
    size: '',
    type: '',
  },
  instruction: '',
  isDisabled: false,
  isLoading: false,
  maxSize: KYC_MAX_FILE_SIZE,
  minSize: KYC_MIN_FILE_SIZE,
  noValidation: false,
  selectedFile: null,
  title: '',
  type: 'upload',
  uploadButtonLabel: '',
  webCamButtonLabel: '',
  withPreview: false,
};

export default withTranslation('FileUploader')(FileUploader);
