import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const App = (props) => (
  <Container {...props}>
    <Icon viewBox="0 0 96 57">
      <g id="Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Change-Wallet--Restricted-01---Change-wallet-address-Copy-2" transform="translate(-872.000000, -572.000000)" strokeWidth="0.7">
          <g id="Group-76" transform="translate(873.000000, 573.000000)">
            <g id="Group-73">
              <polygon id="Stroke-198" stroke="#091831" points="60.2609409 51.0229918 59.2903189 51.0229918 58.5095233 51.5928741 57.2201362 52.5260565 37.0555539 52.5260565 35.7697484 51.5928741 34.9853712 51.0229918 34.0147492 51.0229918 2.97633533 51.0229918 0 51.0229918 0 53.9828182 0 55.3362888 94.2756901 55.3362888 94.2756901 53.9828182 94.2756901 51.0229918 91.2993548 51.0229918" />
              <polyline id="Stroke-199" stroke="#091831" points="86.9380027 46.7446 86.9380027 -1.80611082e-12 7.33983632 -1.80611082e-12 7.33983632 46.7446" />
              <polygon id="Stroke-200" stroke="#091831" points="16.1875393 19.4361938 77.6089286 19.4361938 77.6089286 7.73935853 16.1875393 7.73935853" />
              <polygon id="Stroke-201" stroke="#091831" points="16.0084577 40.4729665 30.1129204 40.4729665 30.1129204 27.6648606 16.0084577 27.6648606" />
              <polygon id="Stroke-202" stroke="#FFD25F" points="39.6973649 40.0611585 53.8018276 40.0611585 53.8018276 27.2530526 39.6973649 27.2530526" />
              <polygon id="Stroke-203" stroke="#FFD25F" points="63.3826904 40.0611585 77.4871531 40.0611585 77.4871531 27.2530526 63.3826904 27.2530526" />
              <polyline id="Stroke-204" stroke="#FFD25F" points="67.1935458 33.5976241 69.5287691 35.9163329 74.0165527 31.4570035" />
              <polyline id="Stroke-205" stroke="#FFD25F" points="43.6697518 33.5976241 46.0049752 35.9163329 50.4927588 31.4570035" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

export default App;
