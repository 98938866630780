import Carousel from '@digix/electron/components/carousel';
import { TABLE_GRID_TEMPLATES } from '@digix/electron/utils/styleConstants';
import TableStyles from '@digix/electron/shared/table/styles';
import { withTranslation } from 'react-i18next';
import { Loader, Pagination } from '@digix/electron/shared';
import React, { Fragment } from 'react';
import {
  any,
  array,
  arrayOf,
  bool,
  func,
  number,
  shape,
  string,
} from 'prop-types';

const {
  Container,
  Data,
  ErrorMessage,
  Head,
  Item,
} = TableStyles;

const Table = ({
  currentPage,
  dataDigix,
  emptyErrorMessage,
  fetchErrorMessage,
  gridTemplateColumns,
  hasError,
  head,
  isLoading,
  items,
  onClickRow,
  onPageChange,
  totalPages,
  t,
  pagination
}) => {
  const renderItems = () => {
    const renderContent = () => {
      if (isLoading) {
        return (
          <Loader
            color="primary"
            kind="simple"
          />
        );
      }
      if (hasError) {
        if (fetchErrorMessage) {
          return fetchErrorMessage;
        }
        return t('Table.unableToFetch');
      }
      if (emptyErrorMessage) {
        return (
          <ErrorMessage>
            {emptyErrorMessage}
          </ErrorMessage>
        );
      }
      return t('Table.noItems');
    };
    if (isLoading || hasError || !items.length) {
      return (
        <Item>
          <Data data-digix="Table-StatusInfo">
            {renderContent()}
          </Data>
        </Item>
      );
    }
    const handleRowClick = ({ target }, rowKey) => {
      const isCheckboxColumn = target.type && target.type === 'checkbox';
      if (onClickRow && !isCheckboxColumn) {
        onClickRow(rowKey);
      }
    };

    return (
      <Carousel>
        {items.map((row, i) => {
          const keyItem = row.find(i => i.name === 'key') || {};
          return (
            <Item
              gridTemplateColumns={gridTemplateColumns}
              key={`${keyItem.value || ''}.${i}`}
              onClick={e => handleRowClick(e, keyItem.value)}
              isClickEnabled={!!onClickRow}
            >
              {row.filter(i => i.name !== 'key').map(({
                dataDigix,
                name,
                value,
              }) => {
                const cellKey = typeof name === 'object' && name.key
                  ? name.key
                  : `${name}.${i}`;
                return (
                  <Data
                    key={cellKey}
                    data-content={name}
                    data-digix={dataDigix || name}
                  >
                    {value}
                  </Data>
                );
              })}
            </Item>
          );
        })}
      </Carousel>
    );
  };

  const renderPagination = () => (
    <Pagination
      current={currentPage}
      onPageChange={onPageChange}
      totalPages={totalPages}
      version="v2"
    />
  );

  return (
    <Fragment>
      <Container data-digix={dataDigix}>
        <Item
          gridTemplateColumns={gridTemplateColumns}
          head
        >
          {head.map((item, i) => (
            <Head
              key={typeof item === 'object' && item.key
                ? item.key
                : item || i}
            >
              {item}
            </Head>
          ))}
        </Item>
        {renderItems()}
      </Container>
      {pagination && renderPagination()}
    </Fragment>
  );
}

Table.propTypes = {
  currentPage: number,
  dataDigix: string.isRequired,
  emptyErrorMessage: string,
  fetchErrorMessage: string,
  gridTemplateColumns: string,
  hasError: bool,
  head: array,
  items: arrayOf(
    arrayOf(
      shape({
        name: any,
        value: any,
      }),
    )
  ),
  onClickRow: func,
  onPageChange: func.isRequired,
  t: func.isRequired,
  totalPages: number.isRequired,
  pagination: bool,
};

Table.defaultProps = {
  currentPage: 1,
  emptyErrorMessage: '',
  fetchErrorMessage: '',
  gridTemplateColumns: TABLE_GRID_TEMPLATES.transactions,
  hasError: false,
  head: [],
  items: [],
  pagination: true
};

export default withTranslation('Transactions')(Table);
