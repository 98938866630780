import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Email = props => (
  <Container {...props}>
    <Icon viewBox="0 0 33.3 23.8">
      <path fill="none" strokeWidth="1.3" d="M.7 5.2V.6h32v22.6H.7V9.6" />
      <path fill="none" strokeWidth="1.3" d="M21 12.3l10.6 11" />
      <path fill="none" strokeWidth="1.3" d="M.7 23.3l11.1-10.9" />
      <path fill="none" strokeWidth="1.3" d="M26.7 7.8l-9.9 9.4-6.7-6.7" />
    </Icon>
  </Container>
);

export default Email;
