export const getMilisecondsByDuration = (duration) => {
  switch (duration) {
    case 'long':
      return 10000;
    case 'indefinite':
      return null;
    default:
      return 5000;
  }
};
