import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Logout = props => (
  <Container {...props}>
    <Icon viewBox="0 0 28.5 28.5">
      <path fill="none" stoke-width="2" d="M15.9 1H1v26.5h25.8" />
      <path fill="none" stoke-width="2" d="M19.4 7.9l7.6 6.4-7.6 6.6" />
      <path fill="#fff" stroke="none" d="M16.3 8.5h6.9V7h-6.9z" />
      <path fill="#fff" stroke="none" d="M16.3 21.6h6.9v-1.5h-6.9z" />
      <path fill="none" stoke-width="2" d="M27 14.2H10.2" />
    </Icon>
  </Container >
);

export default Logout;
