import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Globe = props => (
  <Container {...props}>
    <Icon viewBox="0 0 140 110">
      <polygon
        fill="#ffffff"
        points="136.2,76.7 130.2,63 113.8,63 106,80.9 125,80.9 127,80.9 131.3,81 138,81 "
      />
      <path
        fill="#ffffff"
        stroke="#f5cd65"
        d="M32.2,43.7l-6-13.7H9.8L2,47.9l25.3,0l6.7,0L32.2,43.7z M136.2,76.7l-6-13.7h-16.3L106,80.9l19,0h2l4.4,0l6.7,0
	L136.2,76.7z M32.2,43.7l-6-13.7H9.8L2,47.9l25.3,0l6.7,0L32.2,43.7z"
      />
      <path
        fill="none"
        strokeWidth="1.5"
        stroke="#121e34"
        d="M60.9,1l8.3,8l-8.3,8 M18,23c0-7.7,5.7-14,12.7-14H69 M76.3,109l-8.6-8l8.6-8 M121,87c0,7.7-6,14-13.2,14H68
	 M71.5,85V26.5 M101,56H43 M62,27.5c-0.1,0.1-6,7.6-6,26.7c0,19.2,6.4,29.3,6.5,29.3 M80,27c4.7,6.5,7,16.3,7,29.2
	c0,19.5-6.5,28.3-6,27.3 M71.5,85C55.2,85,42,71.8,42,55.5C42,39.2,55.2,26,71.5,26S101,39.2,101,55.5C101,71.8,87.8,85,71.5,85z
	 M99.5,65.5c-0.3,0.3-7.3,6.5-26.6,6.5s-29.1-6.7-29.4-7 M44,44.5c0.3-0.2,9.4-5.5,28.8-5.5c19.4,0,24.7,5.2,26.2,6"
      />
      <path fill="none" strokeWidth="1.5" stroke="#f5cd65" d="M2,1" />
    </Icon>
  </Container>
);

Globe.defaultProps = {
  width: '2rem',
  height: '2rem',
};

export default Globe;
