import ContainedButton from '@digix/electron/shared/button/contained';
import IconButton from '@digix/electron/shared/button/icon-button';
import OutlinedButton from '@digix/electron/shared/button/outlined';
import React from 'react';
import TextButton from '@digix/electron/shared/button/text';
import Tooltip from '@digix/electron/shared/tooltip';
import { shape, string, bool } from 'prop-types';

const buttons = {
  contained: ContainedButton,
  outlined: OutlinedButton,
  text: TextButton,
  icon: IconButton,
};

const Button = props => {
  const ButtonComponent = buttons[props.variant];
  if (props.tooltip && !props.disabled) {
    const buttonProps = {};
    Object.keys(props)
      .filter(prop => prop !== 'tooltip')
      .forEach(buttonProp => buttonProps[buttonProp] = props[buttonProp]);
    return (
      <Tooltip {...props.tooltip}>
        <ButtonComponent {...buttonProps} />
      </Tooltip>
    );
  }
  return <ButtonComponent {...props} />;
};

Button.propTypes = {
  variant: string,
  tooltip: shape({
    placement: string,
    text: string,
    withArrow: bool,
  }),
};

Button.defaultProps = {
  variant: 'contained',
  tooltip: null,
};

export default Button;
