import styled, { css } from 'styled-components';
import {
  Heading,
  media,
} from '@digix/electron/shared';

export const DashSection = {
  SectionContainer: styled.section`
    background: var(--background, var(--color-inverse));
    color: var(--color);
    padding: 4.8rem 3.2rem;
    position: relative;

    ${media.sm`
      border: 1px solid var(--border-color);
      box-shadow: 0rem 0.05rem 0.75rem var(--box-shadow);
      margin-bottom: 2.4rem;
      padding: 4rem 4.8rem;
    `}

    ${media.md`
      min-height: 21.6rem;
      padding: 2.4rem 3rem;
    `}

    ${media.lg`
      min-height: 26.5rem;
      padding: 3.2rem 4.8rem;
    `}
  `,

  SectionTitle: styled(Heading)`
    font-family: var(--body-font-semibold);

    ${media.xxs`
      font-size: 2.6rem;
      line-height: 3rem;
      margin-bottom: 1.6rem;
    `}

    ${media.xs`
      font-size: 3.2rem;
      line-height: 4rem;
    `}

    ${media.md`
      font-size: 2rem;
      line-height: 3.6rem;
      margin-bottom: 2rem;
    `}

    ${media.lg`
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin-bottom: 2.4rem;
    `}
  `,

  SectionContent: styled.div``,

  Container: styled.div`
    &::-webkit-scrollbar {
      width: .6rem;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      overflow:visible;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,.2);
    }

    ${props => props.body && css`
      height: 26rem;
      overflow-y: scroll;
    `}

    ${props => props.isBalance ? css`
        display: block;
    ` : css`
        ${media.xxs`
          display: grid;
          grid-gap: 0;
          grid-template-columns: 1.5fr 1fr;
        `}

        ${media.md`
          grid-template-columns: 1.5fr 1fr;
        `}

        ${media.lg`
          grid-template-columns: 1fr 1fr;
        `}
    `}
  `,


};
