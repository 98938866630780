import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Filter = props => (
  <Container {...props}>
    <Icon viewBox="0 0 18 16">
      <g stroke="#446BB2" fill="none" fillRule="evenodd"><path d="M1 3.96h3.36V1.72H1zM6.6 11.8h3.36V9.56H6.6zM13.32 7.32h3.36V5.08h-3.36zM2.68 1.37V.95M2.68 15.16V5.08M9.4 8.44V.6M15 3.96V.6M15 15.16V8.44M9.326 15.24V13"/></g>
    </Icon>
  </Container>
);

Filter.defaultProps = {
  width: '2.25rem',
  height: '2.25rem',
};

export default Filter;
