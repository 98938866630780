import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Close = props => (
  <Container {...props}>
    <Icon viewBox="0 0 17 15" width="100%" height="100%">
      <path
        d="M16,13.3c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3l-6-5.9l-6.2,5.9
	C2.2,14.9,1.9,15,1.7,15c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l6.2-5.9L1.3,1.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0
	l5.9,5.8l6-5.8c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-6,5.7L16,13.3z" stroke="none"
      />
    </Icon>
  </Container>
);

Close.defaultProps = {
  width: '1.6rem',
  height: '1.6rem',
};

export default Close;
