import styled, { css } from 'styled-components';
import { lineHeight, Heading, Icon, Link, media } from '@digix/electron/shared';

const DataItem = styled.div`
  align-items: space-evenly;
  display: flex;
  flex: 1 1 8rem;
  flex-direction: column;
  justify-content: center;
  padding: 1.4rem 3.4rem 1rem 2rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }

  span {
    color: ${props => props.tier && 'var(--error)'};
    font-family: var(--body-font-regular);
    font-size: var(--font-size-base);

    ${props => lineHeight(props.theme.typography.lineHeight.md)};
  }

  & > * {
    display: flex;
    flex-direction: column;
  }
`;

export const PriceTable = {
  ToggleLink: styled(Link)`
    --font-family: var(--body-font-semibold);
    margin-bottom: 3rem;

    > div {
      transform: rotate(90deg);

      ${props => props.isInlineActive && css`
        transform: rotate(270deg);
      `};
    }
  `,

  Panel: styled.div`
    position: relative;
  `,

  Promo: styled.div`
    background-color: ${props => props.theme.colors.neutral.darkGray100};
    color: var(--color-inverse);
    padding: 1rem 1rem;
    text-align: center;
  `,

  TableContainer: styled.div`
    background: var(--background);
    display: ${props => props.isInlineActive ? 'grid' : 'none'};
    grid-template-columns: 1fr;
    margin-bottom: 10rem;
    position: relative;

    ${media.sm`
      margin-bottom: 4.8rem;
      padding-top: 1rem;
      border: 1px solid var(--border-color);
      box-shadow: 4px 4px 20px -3px var(--box-shadow);
      max-width: ${props => props.fullWidth ? '100%' : '56rem'};
    `};

    ${media.md`

      // Lines below were only used when the 7th March Promo Essentials was released.
      // Should've been removed when the promo was taken down/removed. Lines below were supposed to target the promo component.
      // Uncomment the lines below whenever promo component will be used again.

      // & :nth-child(1) { order: 1; }
      // & :nth-child(2) { order: 2; }
      // & :nth-child(3) { order: 4; }
      // & :nth-child(4) { order: 3; }
    `}
  `,

  Heading: styled(Heading)`
    display: none;
    font-size: 2rem;
    letter-spacing: 0.06rem;
    margin: 2.4rem 0rem 2.4rem 4rem;
    margin-bottom: 2.4rem;

    ${media.sm`
      display: block;
    `}
  `,

  Content: styled.div`
    border: 1px solid transparent;
    display: grid;
    grid-auto-flow: column dense;
    grid-template-columns: repeat(7, minmax(auto, 1fr));
    overflow-x: auto;
    max-width: 100%;
    
    ${media.md`
      margin: 0rem 4rem 4rem 4rem;
    `}
  `,

  BaseCurrency: styled.div`
    display: flex;
    flex-direction: column;
  `,

  QuoteCurrency: styled.div`
    display: flex;
    flex-direction: column;

    ${DataItem} {
      border-left: 1px solid var(--border-color);
    }
  `,

  DataItem: styled(DataItem)`
    white-space: nowrap;
  `,

  Head: styled(DataItem)`
    background: hsl(var(--hue), var(--saturation), 97%);
    border-bottom: 1px solid var(--border-color);
    flex: 1 0 4rem;
    font-family: var(--body-font-semibold);
    text-align: center;
  `,

  CloseIcon: styled(Icon)`
    cursor: pointer;
    display: none;
    position: absolute;
    right: 4rem;
    top: 3rem;
    ${media.sm`
      display: block;
    `}
  `,
};
