import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Loader } from '@digix/electron/shared';
import { Contained } from '@digix/electron/shared/button/contained/styles';

const ContainedButton = ({ hasIcon, isLoading, kind, text, ...props }) => (
  <Contained kind={kind} {...props} isLoading={isLoading}>
    {isLoading && <Loader kind="simple" color="inverted" />}
    {!isLoading && props.children}
    {!isLoading && (text && text)}
    {!isLoading && (hasIcon && <Icon name="arrow" />)}
  </Contained>
);

const { object, string, node, oneOfType } = PropTypes;

ContainedButton.propTypes = {
  children: oneOfType([object, string, node]),
};

export default ContainedButton;
