import { Heading } from '@digix/electron/shared';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 75vh;
`;

export const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: fill-available;
  justify-content: center;
  padding: 0 4rem;
  text-align: center;
`;

export const Title = styled(Heading)`
  margin-bottom: 2rem;
  margin-top: 4.5rem;
`;
