/*
 * A generic dropdown component with basic accessibility functions. Sample usage
 * of this can be found in src/electron/components/portal/register/index.jsx
 */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Label, StyledSelect, AlertMessage } from '@digix/electron/shared/select/styles';

const Select = ({
  autoFocus,
  "data-digix": dataDigix,
  defaultValue,
  disabled,
  errorMessage,
  hasError,
  label,
  name,
  noPlaceholder,
  noBorder,
  onChange,
  options,
  placeholder,
  width,
  value,
}) => {

  const handleChange = e => onChange && onChange(e);

  return (
    <Fragment>
      {label.length > 0 && (
        <Label hasError={hasError} id={name}>
          {label}
        </Label>
      )}
      <StyledSelect
        autoFocus={autoFocus}
        disabled={disabled}
        hasError={hasError}
        name={name}
        noBorder={noBorder}
        onChange={handleChange}
        value={value}
        width={width}
        data-digix={dataDigix}
      >
        {placeholder && !noPlaceholder &&(
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option
            disabled={option.disabled || false}
            key={`${option.name}.${option.value}`}
            name={option.name}
            value={option.value}
          >
            {option.name}
          </option>
        ))}
      </StyledSelect>
      {errorMessage && (
        <AlertMessage kind="error">{errorMessage}</AlertMessage>
      )}
    </Fragment>
  );
}

const { array, bool, func, number, object, oneOfType, string } = PropTypes;

Select.propTypes = {
  autoFocus: bool,
  "data-digix": string,
  defaultValue: oneOfType([number, string]),
  disabled: bool,
  errorMessage: oneOfType([object, string]),
  hasError: bool, // if true, shows red border around wrapper
  label: string,
  name: oneOfType([number, string]).isRequired,
  noPlaceholder: bool,
  noBorder: bool,
  onBlur: func,
  onChange: func,
  options: array.isRequired, // in the format of: [{ name, value }]
  placeholder: string,
  value: string,
  width: string,
};

Select.defaultProps = {
  autoFocus: false,
  "data-digix": undefined,
  defaultValue: undefined,
  disabled: false,
  errorMessage: undefined,
  hasError: false,
  label: '',
  noBorder: false,
  noPlaceholder: false,
  onBlur: () => { },
  onChange: () => { },
  placeholder: 'Please select an option',
  value: undefined,
  width: '25rem',
};

export default Select;
