import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Copy = props => (
  <Container {...props}>
    <Icon viewBox="0 0 15 16">
      <g stroke="none" strokeWdth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-745.000000, -228.000000)" fill="#32363D" fillRule="nonzero">
          <g transform="translate(347.000000, 224.000000)">
            <g transform="translate(398.000000, 4.000000)">
              <path d="M12.7058824,16 L4.97832817,16 C4.09094737,16 3.36842105,15.2448 3.36842105,14.3157895 L3.36842105,4.21052632 C3.36842105,3.28151579 4.09094737,2.52631579 4.97832817,2.52631579 L12.7058824,2.52631579 C13.5932632,2.52631579 14.3157895,3.28151579 14.3157895,4.21052632 L14.3157895,14.3157895 C14.3157895,15.2448 13.5932632,16 12.7058824,16 Z M4.97832817,3.2 C4.4457709,3.2 4.0123839,3.65338947 4.0123839,4.21052632 L4.0123839,14.3157895 C4.0123839,14.8729263 4.4457709,15.3263158 4.97832817,15.3263158 L12.7058824,15.3263158 C13.2384396,15.3263158 13.6718266,14.8729263 13.6718266,14.3157895 L13.6718266,4.21052632 C13.6718266,3.65338947 13.2384396,3.2 12.7058824,3.2 L4.97832817,3.2 Z" />
              <path d="M2.25386997,14.3157895 L1.60990712,14.3157895 C0.722526316,14.3157895 0,13.551599 0,12.6115288 L0,1.70426065 C0,0.764190476 0.722526316,0 1.60990712,0 L9.3374613,0 C10.2248421,0 10.9473684,0.764190476 10.9473684,1.70426065 C10.9473684,1.89241103 10.8031207,2.04511278 10.625387,2.04511278 C10.4476533,2.04511278 10.3034056,1.89241103 10.3034056,1.70426065 C10.3034056,1.14049123 9.87001858,0.681704261 9.3374613,0.681704261 L1.60990712,0.681704261 C1.07734985,0.681704261 0.643962848,1.14049123 0.643962848,1.70426065 L0.643962848,12.6115288 C0.643962848,13.1752982 1.07734985,13.6340852 1.60990712,13.6340852 L2.25386997,13.6340852 C2.43160372,13.6340852 2.57585139,13.786787 2.57585139,13.9749373 C2.57585139,14.1630877 2.43160372,14.3157895 2.25386997,14.3157895 Z" />
              <path d="M11.4619883,10.1052632 L6.22222222,10.1052632 C6.04145029,10.1052632 5.89473684,9.91663158 5.89473684,9.68421053 C5.89473684,9.45178947 6.04145029,9.26315789 6.22222222,9.26315789 L11.4619883,9.26315789 C11.6427602,9.26315789 11.7894737,9.45178947 11.7894737,9.68421053 C11.7894737,9.91663158 11.6427602,10.1052632 11.4619883,10.1052632 Z" />
              <path d="M11.4619883,13.4736842 L6.22222222,13.4736842 C6.04145029,13.4736842 5.89473684,13.2850526 5.89473684,13.0526316 C5.89473684,12.8202105 6.04145029,12.6315789 6.22222222,12.6315789 L11.4619883,12.6315789 C11.6427602,12.6315789 11.7894737,12.8202105 11.7894737,13.0526316 C11.7894737,13.2850526 11.6427602,13.4736842 11.4619883,13.4736842 Z" />
              <path d="M11.4619883,6.73684211 L6.22222222,6.73684211 C6.04145029,6.73684211 5.89473684,6.54821053 5.89473684,6.31578947 C5.89473684,6.08336842 6.04145029,5.89473684 6.22222222,5.89473684 L11.4619883,5.89473684 C11.6427602,5.89473684 11.7894737,6.08336842 11.7894737,6.31578947 C11.7894737,6.54821053 11.6427602,6.73684211 11.4619883,6.73684211 Z" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

Copy.defaultProps = {
  width: '',
  height: '',
};

export default Copy;
