/* eslint-disable import/no-unresolved */
import { combineReducers } from 'redux';
// eslint-disable-next-line sort-imports
import authStatus from '~/reducers/auth-status';
import browser from '~/reducers/browser';
import compliance from '~/reducers/compliance';
import keystore from '~/reducers/keystore';
import recastRemint from '~/reducers/recast-remint';
import resize from '~/reducers/resize';
import snackbar from '~/reducers/snackbar';
import transaction from '~/reducers/transaction';
import user from '~/reducers/user';
import web3 from '~/reducers/web3';

const appReducer = combineReducers({
  authStatus,
  browser,
  compliance,
  keystore,
  recastRemint,
  resize,
  snackbar,
  transaction,
  user,
  web3,
});

const reducers = (newState, action) => {
  const state = action.type === 'LOGOUT'
    ? undefined
    : newState;

  return appReducer(state, action);
};

export default reducers;
