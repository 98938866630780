import styled from 'styled-components';
import { Alert, Label } from '@digix/electron/shared';

const StyledDatePicker = {
  DateWrapper: styled.div`
    column-gap: 1rem;
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;

    & > select {
      font-size: calc(1.5 * 1rem);
      max-width: ${props => props.width};
      min-width: 8rem;
      padding: 0 1.8rem;
      width: 100%;
    }
  `,
  ErrorMessage: styled(Alert)`
    background: transparent;
    margin-top: 0.5rem;
    padding: 0;
  `,
  Label: styled(Label)`
    font-size: ${props => props.theme.typography.fontSize.sm};
    line-height: ${props => props.theme.typography.lineHeight.sm}rem;
    margin-bottom: 0;
  `,
};

export default StyledDatePicker;
