import { SHOW_PUBLIC_NOTICE } from '@digix/electron/utils/constants';
import { PriceTable } from '@digix/electron/components/pricing-table/styles'
import {
  Button,
  Heading,
  MainWrapper,
  Tag,
  fontWeight,
  media,
  SubSection,
} from '@digix/electron/shared';
import styled, { css } from 'styled-components';

import { DashSection as DashboardSection } from '@digix/electron/components/dashboard/styles';

const { SectionContainer } = DashboardSection;

export const LinkButton = styled(Button)`
  line-height: inherit;
  margin: 0;
  padding: 0;
`;

export const DashboardStyles = {
  DashWrapper: styled(MainWrapper)`
    background: var(--color-inverse);
    grid-template-columns: 12fr;

    ${media.md`
      background-color: ${props => props.theme.colors.neutral.lightGray002};
    `}

    &&& header {
      background: transparent;
      margin-top: 2.4rem;
      padding: 3.2rem 4rem 1.2rem 4rem;
      position: absolute;
      width: 100%;
      z-index: 1;

      ${media.sm`
        padding-left: 0rem;
        position: relative;

        svg {
          stroke: var(--color-primary); 
        }
      `}
    }
  `,
  DashContainer: styled.div`
    background: transparent;
    display: flex;
    flex-direction: column;
    grid-column: content;
    margin: 0rem;
    padding: 0;
    padding-top: ${props => props.initial ? 16 : 0}rem;

    ${PriceTable.TableContainer} {
      margin-left: -2.4rem;
      margin-right: -2.4rem;
    }

    ${media.sm`
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2.4rem;
      padding-top: 0;
      margin: 6.4rem 0;
      
      ${PriceTable.TableContainer} {
        margin-left: 0rem;
        margin-right: 0rem;
      }

      ${props => SHOW_PUBLIC_NOTICE(props.email)
      ? css`
        grid-template-columns: 
          [status-start] 
            repeat(12, 1fr)
          [status-end];
        grid-template-rows: 
          [status-start]
            auto
          [status-end];
      `
      : css`
        grid-template-columns: 
          [status-start wallet-start buydgx-start support-start initialWallet-start loadWallet-start txn-start assets-start] 
            repeat(var(--column-one, 6), 1fr)
          [status-end key-start wallet-end] 
            repeat(var(--column-two, 6), 1fr)
          [buydgx-end support-end initialWallet-end loadWallet-end key-end txn-end assets-end];
        grid-template-rows: 
          [status-start key-start support-start]
            auto
          [status-end key-end support-end initialWallet-start wallet-start loadWallet-start buydgx-start ]
            auto
          [wallet-end initialWallet-end loadWallet-end buydgx-end txn-start]
          auto
          [txn-end assets-start]
          auto
          [txn-end assets-end]
          ;
      `}
    `}

    ${media.md`
      margin: 4.8rem 0rem;
      padding: 10rem 0rem 0rem 0rem;
    `}

    ${media.lg`
      margin: 9.6rem 0rem;
    `}

  `
};

export const RegisterWalletStyles = {
  Wrapper: styled.div`
    background: var(--color-inverse);
    border: 1px solid ${props => props.theme.colors.neutral.gray15};
    box-shadow: 0rem 0.05rem 0.75rem ${props => props.theme.colors.neutral.gray10};
    display: grid;
    grid-column: import;
    grid-row: import;
    grid-template-columns: 1fr;
    margin: 15rem 0;
    padding: 4.8rem;

    ${media.md`
      margin: 10rem 0;
    `}
  `,
  Container: styled(SectionContainer)`
    background-color: var(--color-inverse);
    display: grid;
    grid-column: initialWallet;
    grid-row: initialWallet;
    padding: 4.8rem;

    ${media.sm`
    border: 1px solid var(--border-color);
      box-shadow: 0rem 0.05rem 0.75rem ${props => props.theme.colors.neutral.gray10};
    `}

    ${media.md`
      display: flex;
      flex-direction: column;
      order: 2;
      padding: 4.8rem;
      min-height: 20rem;
    `};
  `,
  FAQForm: styled.div`
    & > p {
      margin-bottom: ${props => props.isRejected ? 2.4 : 1}rem;
    }
  `,
  FormColumn: styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: column;

    :first-child {
      margin-right: 1.2rem;
    }
  `,
  FormContainer: styled.div`
    display: flex;
  `,
  Greetings: styled(Heading)`
    font-size: 2.4rem;
    letter-spacing: 0;
    margin-bottom: ${props => props.isRejected ? 2.4 : 0}rem;
    margin-right: 0.5rem;
    width: fit-content;
  `,
  Heading: styled(Heading)`
    font-size: 1.8rem;
    letter-spacing: 0;
    margin-bottom: 0.8rem;
  `,
  HeaderContainer: styled.div`
    align-items: baseline;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    margin-bottom: 2.4rem;

    ${media.sm`
      flex-direction: ${props => props.direction};
    `}
  `,
  LinkRow: styled.div``,
  Link: styled.a`
    margin-left: 0.8rem;
    text-decoration: underline;
  `,
  Footer: styled.div`
    margin-top: 1.2rem;
  `
};

export const Container = styled.div`
  background: transparent;
  margin: 0rem;
  padding: 0rem;

  ${PriceTable.TableContainer} {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
  }

  ${media.sm`
    display: grid;
    grid-column: content-start;
    grid-template-columns: 1fr;
    padding: 0rem;
    margin: 6.4rem 0;
    
    ${PriceTable.TableContainer} {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  `}

  ${media.md`
    grid-template-columns: repeat(${props => props.theme.grid.column.large},
      1fr
    );
    grid-gap: 0 ${props => props.theme.grid.gap.large}rem;
    margin: 4.8rem 0rem;
    padding: 0rem 0rem;
  `}

  ${media.lg`
    margin: 9.6rem 0rem;
    padding: 0rem 1.6rem;
  `}
`;

export const BackButton = styled.div`
  font-size: var(--font-size-base);
  margin-bottom: 1.6rem;
  margin-top: 15rem;

  a {
    svg {
      transform: rotate(180deg);
    }
  }

  ${media.sm`
    margin-top: 0rem;
    top: 5rem;
    right: 5rem;
  `};
`;

// Jan 10 2020 Update - Please refer to /components/dashboard/styles for Dashboard Section styles moving forward.
export const DashSection = styled(SubSection)`
  border: 1px solid ${props => props.theme.colors.neutral.gray15};
  box-shadow: 0rem 0.05rem 0.75rem ${props => props.theme.colors.neutral.gray10};
  display: ${props => props.isMobile ? 'none' : 'grid'};
  margin-bottom: 2.4rem;
  padding: 2.4rem;
  
  position: relative;

  ${media.sm`
    display: grid;
    padding: 4rem 4.8rem;
  `}

  ${media.md`
    padding: 2.4rem 3rem;
    min-height: 21.6rem;
  `}

  ${media.lg`
    min-height: 26.5rem;
    padding: 3.2rem 4.8rem;
  `}
`;

export const DashboardCard = styled.div`

`;

export const Content = styled.div`
  display: block;
  grid-column: 1 / 13;
  position: relative;
`;

// Jan 10 2020 Update - Please refer to /components/dashboard/styles for Dashboard Heading styles moving forward.
export const Title = styled(Heading)`
  font-family: ${fontWeight('semibold')};
  letter-spacing: 0.045rem;

  ${media.xxs`
    font-size: 2.6rem;
    line-height: 3rem;
    margin-bottom: 1.6rem;
  `}

  ${media.xs`
    font-size: 3.2rem;
    line-height: 4rem;
  `}

  ${media.md`
    font-size: 2rem;
    line-height: 3.6rem;
    margin-bottom: 2rem;
  `}

  ${media.lg`
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 4rem;
  `}
`;

export const Subtitle = styled(Heading)`
  font-family: ${props => props.theme.typography.typeface.primary.regular};
  margin-bottom: 3rem;

  a {
    color: ${props => props.theme.heading.secondary};
  }

  ${Tag} {

  }
`;

export const WalletList = {
  Container: styled.div`
    width: 100%;
    
    &::-webkit-scrollbar {
      width: .6rem;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      overflow:visible;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0,0,0,.2);
    }

    ${props => props.body && css`
      height: 26rem;
      overflow-y: scroll;
    `}

    ${props => props.isBalance ? css`
        display: block;
    ` : css`
        ${media.xxs`
          display: grid;
          grid-gap: 0;
          grid-template-columns: 1.5fr 1fr;
        `}

        ${media.md`
          grid-template-columns: 1.5fr 1fr;
        `}

        ${media.lg`
          grid-template-columns: 1fr 1fr;
        `}
    `}
  `,

  Content: styled(Content)`
    padding-bottom: ${props => props.isRecasting ? 0 : 2.4}rem;
    padding-top: 3rem;
  `,

  Error: styled.p`
    bottom: 0;
    color: ${props => props.theme.colors.semantic.error};
    font-size: 1.2rem;
    left: 7.4rem;
    line-height: inherit;
    margin-bottom: 0;
  `,

  ErrorWrapper: styled.div`
    bottom: 0;
    padding: 0 3rem 0.8rem;
  `,

  Header: styled.span`
    align-items: center;
    background: ${props => props.theme.table.header.backgroundSecondary};
    display: flex;
    padding: 1.6rem 2.4rem ;

    img {
      cursor: pointer;
      margin-left: 0.5rem;
    }

    span {
      font-family: ${props => props.theme.typography.typeface.primary.bold};

      ${props => props.end && css`
        flex: 1;
        text-align: right;
      `}
    }
  `,

  RowContainer: styled.div`
    border-bottom: 1px solid ${props => props.theme.table.borderColor};
    position: relative;
  `,

  RowContent: styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;

    & > span {
      padding-bottom: 1rem;
    }
  `,

  Token: styled.span`
    align-items: center;
    display: flex;
    padding: 1.6rem 2.4rem 1.6rem 1.6rem;
    position: relative;

    ${media.md`
      padding: 1.6rem 1.6rem ;
    `}

    ${media.lg`
      padding: 1.6rem 2.4rem ;
    `}
    
    img {
      margin-right: 1rem;
      width: 4rem;

      ${media.md`
        width: 2.5rem;
      `}

      ${media.lg`
        width: 4rem;
      `}
    }

    span {
      font-family: ${props => props.theme.typography.typeface.primary.bold};
    }
  `,

  TokenWrapper: styled.div`
    align-items: center;
    display: flex;
  `,

  Qty: styled.span`
    align-items: center;
    display: flex;
    padding: 1.6rem 2.4rem ;

    span {
      ${props =>
      props.end &&
      css`
          flex: 1;
          text-align: right;
      `}
    }
  `,

  NoTokens: styled.div`
    background: ${props => props.theme.colors.neutral.gray10};
    margin-top: 2.4rem;
    padding: 1.6rem 2.4rem;
    width: 100%;
  `,
};
