/* eslint-disable */

import OfflinePlugin from 'offline-plugin/runtime';

OfflinePlugin.install({
  onUpdateReady: () => {
    console.log('SW Event:', 'onUpdateReady');
    if (window.confirm('A new version of the app has been pushed. Would you like to reload?')) {
      OfflinePlugin.applyUpdate();
    }
  },

  onUpdated: () => {
    console.log('SW Event:', 'onUpdated');
    window.location.reload();
  },

  onUpdateFailed: () => {
    console.log('SW Event:', 'onUpdateFailed');
  }
});

