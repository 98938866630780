import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyledProgress } from '@digix/electron/shared/progress-step/styles';
const { Label, Bar } = StyledProgress;

const ProgressStep = ({ percent, subtext, title }) => (
  <Fragment>
    <Label>
      {title && <span>{title}</span>}
      {subtext && <span>{subtext}</span>}
    </Label>
    <Bar>
      <div style={{ width: `${percent}%` }} />
      <p>{`${percent}%`}</p>
    </Bar>
  </Fragment>
);

ProgressStep.propTypes = {
  percent: PropTypes.number,
  subtext: PropTypes.string,
  title: PropTypes.string,
};

PropTypes.defaultProps = {
  percent: 0,
};

export default ProgressStep;
