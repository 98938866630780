import FuturaPTBook from '@digix/electron/assets/fonts/FuturaPTBook.woff2';
import FuturaPTMedium from '@digix/electron/assets/fonts/FuturaPTMedium.woff2';
import FuturaPTDemi from '@digix/electron/assets/fonts/FuturaPTDemi.woff2';
import FuturaPTHeavy from '@digix/electron/assets/fonts/FuturaPT-Heavy.woff2';
import PlayfairDisplayBold from '@digix/electron/assets/fonts/Playfair-Display-Bold.otf';

import { media } from '@digix/electron/shared';
import { css } from 'styled-components';


export const fonts = css`
  @font-face {
    font-display: auto;
    font-family: "FuturaPT-Book";
    font-style: normal;
    font-weight: 300;
    src: local("FuturaPT-Book"),
      url(${FuturaPTBook}) format('woff2');
  }

  @font-face {
    font-display: auto;
    font-family: "FuturaPT-Medium";
    font-style: normal;
    src: local("FuturaPT-Medium"),
      url(${FuturaPTMedium}) format('woff2');
  }

  @font-face {
    font-display: auto;
    font-family: "FuturaPT-Demi";
    font-style: normal;
    src: local("FuturaPT-Demi"),
      url(${FuturaPTDemi}) format('woff2');
  }

  @font-face {
    font-display: auto;
    font-family: "FuturaPT-Heavy";
    font-style: normal;
    src: local("FuturaPT-Heavy"),
      url(${FuturaPTHeavy}) format('woff2');
  }

  @font-face {
    font-display: auto;
    font-family: "Playfair Display";
    font-style: normal;
    src: local("Playfair Display"),
      url(${PlayfairDisplayBold}) format('opentype');
  }
`;

const fontSize = css`
  --font-scale: 1.2;
  --font-size-1: calc(var(--font-scale) * var(--font-size-2));
  --font-size-2: calc(var(--font-scale) * var(--font-size-3));
  --font-size-3: calc(var(--font-scale) * var(--font-size-4));
  --font-size-4: 1rem;

  --text-base-size: 1rem;
  --font-size-2xs: calc(var(--text-base-size) * 1.1);
  --font-size-xs: calc(var(--text-base-size) * 1.2);
  --font-size-sm: calc(var(--text-base-size) * 1.4);
  --font-size-base: calc(var(--text-base-size) * 1.6);
  
  --font-size-md: calc(var(--text-base-size) * 1.6);
  --font-size-lg:calc(var(--text-base-size) * 2.5);
  --font-size-xl: calc(var(--text-base-size) * 3.2);
  --font-size-2xl: calc(var(--text-base-size) * 3.2);
  --font-size-3xl: calc(var(--text-base-size) * 4);

  ${media.md`
    --font-size-md: calc(var(--text-base-size) * 2);
    --font-size-lg:calc(var(--text-base-size) * 2);
    --font-size-xl: calc(var(--text-base-size) * 2.5);
    --font-size-2xl: calc(var(--text-base-size) * 3.2);
    --font-size-3xl: calc(var(--text-base-size) * 4.6);
  `}

  ${media.xl`
    --font-size-lg:calc(var(--text-base-size) * 2.5);
    --font-size-xl: calc(var(--text-base-size) * 3.2);
    --font-size-2xl: calc(var(--text-base-size) * 4.6);
    --font-size-3xl: calc(var(--text-base-size) * 5.8);
  `}
`;

const lineHeight = css`
  --body-line-height: 2;
  --default-line-height: 1.5;
  --heading-line-height: 1.25;
`;

const letterSpacing = css`
  --letter-spacing-sm: calc(var(--text-base-size) * -0.062);
  --letter-spacing-base: calc(var(--text-base-size) * -0.038);
  --letter-spacing-md: calc(var(--text-base-size) * 0.075);
  --letter-spacing-lg: calc(var(--text-base-size) * 0.1);
  --letter-spacing-xl: calc(var(--text-base-size) * 0.023);
  --letter-spacing-2xl: calc(var(--text-base-size) * 0.16);
`;

export const typography = css`
  ${fontSize};
  ${lineHeight};
  ${letterSpacing};
`;