import styled, { css } from 'styled-components';
import { Button, color, Icon } from '@digix/electron/shared';

export const NavDropdown = {
  Container: styled.div`
      align-items: center;
      display: flex;
      flex: 0 1 auto;
      position: relative;
    `,

  List: styled.ul`
      box-shadow: 0rem 0.3rem 1rem ${props => props.theme.colors.neutral.gray30};
      display: ${props => (props.show ? 'block' : 'none')};
      font-size: ${props => props.theme.typography.fontSize.sm};
      position: absolute;
      width: 14rem;
      z-index: 10;

      ${props => (props.position === 'bottom') && css`
        bottom: ${props => props.showOn === 'footer' ? 5 : 4}rem;
        left: ${props => props.showOn === 'footer' ? 0 : 2.4}rem;

        &:after {
          border-top: 1rem solid ${props =>
      (props.kind === 'inverted'
        ? color('inverted')
        : color('primary'))};
        }
      `}

      ${props => (props.position === 'top') && css`
        left: inherit;
        top: 4rem;

        &:after {
          border-bottom: 1rem solid ${props =>
      (props.kind === 'inverted'
        ? color('inverted')
        : color('primary'))};
        }
      `}
  
      &:after {
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;

        content: '';
        height: 0;
        position: absolute;
        right: 2.6rem;
        top: ${props =>
    (props.position === 'bottom'
      ? 'inherit'
      : '-1rem')};
        width: 0;
      }
    `,

  Option: styled.li`
      background-color: ${props =>
    (props.kind === 'inverted'
      ? color('inverted')
      : color('primary'))
    };
      cursor: pointer;
      font-family: ${props => props.selected
      ? props.theme.typography.typeface.primary.semibold
      : props.theme.typography.typeface.primary.regular
    };
      font-size: ${props => props.theme.typography.fontSize.sm};
      letter-spacing: ${props => props.theme.typography.letterSpacing.wide}rem;
      padding: 1.6rem 1.6rem 1.6rem 2.4rem;
      text-align: left;
  
      span {
        color: ${props =>
    (props.kind === 'inverted'
      ? color('primary')
      : color('inverted'))
    };
      }
  
      div {
        margin: 0;
        margin-left: 1.6rem;
  
        svg {
          fill: ${props =>
    (props.kind === 'inverted'
      ? color('primary')
      : color('inverted'))};
          stroke: ${props =>
    (props.kind === 'inverted'
      ? color('primary')
      : color('inverted'))};
        }
      }
  
      &:hover {
        div {
          svg {
            fill: ${props => props.theme.language.inverted.icon};
            stroke: ${props => props.theme.language.inverted.icon};
          }
        }
      }
    `,

  SelectedOption: styled(Button)`
      --text-decoration: none;
      align-items: center;
      color: ${props =>
    (props.kind === 'inverted'
      ? color('inverted')
      : props.theme.language.normal.selectedText)
    };
      display: flex;
      font-family: ${props => props.theme.typography.typeface.primary.semibold};
      font-size: ${props => props.theme.typography.fontSize.sm};
      height: 100%;
      letter-spacing: 0;
      margin: 0;
      padding: 0;
      position: relative;
      text-transform: none;
  
      & > div {
        height: 1.6rem;
        margin-left: 0.8rem;
        transform: rotate(90deg);
        width: 1.6rem;

        svg {
          fill: ${props => color(props.kind)};
          stroke: ${props => color(props.kind)};
        }
      }
  
      

      &:hover {
        color: ${props =>
    (props.kind === 'inverted'
      ? color('inverted')
      : color('primary'))};

        svg {
          fill: ${props =>
    (props.kind === 'inverted'
      ? color('inverted')
      : color('primary'))};
        stroke: ${props =>
    (props.kind === 'inverted'
      ? color('inverted')
      : color('primary'))};
        }
      }
    `,

  Icon: styled(Icon)`
      --stroke: ${props =>
      (props.primary && 'var(--color-primary)')
      || (props.inverse && 'var(--color-inverse)')};
    `,
};
