import { css } from 'styled-components';

export const spacing = css`
  --space-unit:  0.4rem;
  --space-4xs:   calc(0.5 * var(--space-unit));   /* 0.2rem */
  --space-3xs:   var(--space-unit);               /* 0.4rem */
  --space-2xs:   calc(2 * var(--space-unit));     /* 0.8rem */
  --space-xs:    calc(3 * var(--space-unit));     /* 1.2rem */
  --space-base:  calc(4 * var(--space-unit));     /* 1.6rem */
  --space-sm:    calc(5 * var(--space-unit));     /* 2.0rem */
  --space-md:    calc(6 * var(--space-unit));     /* 2.4rem */
  --space-lg:    calc(7 * var(--space-unit));     /* 2.8rem */
  --space-xl:    calc(8 * var(--space-unit));     /* 3.2rem */
  --space-2xl:   calc(9 * var(--space-unit));     /* 3.6rem */
  --space-3xl:   calc(10 * var(--space-unit));    /* 4.0rem */
  --space-4xl:   calc(11 * var(--space-unit));    /* 4.4rem */
`;
