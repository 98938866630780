import { LOCAL_STORAGE_LNG_KEY } from '@digix/electron/utils/constants';
import styled from 'styled-components';

const language = localStorage.getItem(LOCAL_STORAGE_LNG_KEY);

const Tag = styled.span`
  align-items: center;
  background: ${props =>
    (props.success && props.theme.status.success)
    || (props.failed && props.theme.status.error)
    || (props.pending && props.theme.status.warning)
    || (props.primary && props.theme.status.button)
    || (props.button && props.theme.status.button)
    || (props.dropped && props.theme.status.dropped)
    || props.theme.status.info};
  color: ${props => props.theme.status.textColor};
  cursor: ${props => props.button ? 'pointer' : 'auto'};
  display: flex;
  font-family: var(--body-font-semibold);
  font-size: var(--font-size-2xs);
  height: 2.4rem;
  justify-content: center;
  letter-spacing: ${props => props.theme.typography.letterSpacing.wide}rem;
  margin-right: 0.8rem;
  padding: 0 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
  width: ${props => ['id', 'ms'].includes(language) ? "10.8rem" : "auto"};
  
`;

export default Tag;
