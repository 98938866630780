import gql from 'graphql-tag';

const KycApplicationGQL = {
  mutation: {
    addNote: gql`
      mutation addNote($kycId: ID!, $body: String!) {
        addKycV2Note(input: { kycId: $kycId, body: $body }) {
          kycV2Note {
            id
            body
            kycV2 {
              id
            }
            officer {
              id
              email
            }
          }
          errors {
            field
            message
          }
        }
      }
    `,
    amendKyc: gql`
      mutation(
        $applicationId: ID!,
        $expirationDate: Date!,
      ) {
        ammendTier2Kyc(
          input: {
            applicationId: $applicationId,
            expirationDate: $expirationDate
          }
        ) {
          applyingKyc {
            id
            expirationDate
          }
          errors {
            field
            message
          }
        }
      }
    `,
    approveKyc: gql`
      mutation(
        $applicationId: ID!,
        $expirationDate: Date!,
        $identificationProofExpirationDate: Date
      ) {
        approveTier2Kyc(
          input: {
            applicationId: $applicationId,
            expirationDate: $expirationDate,
            identificationProofExpirationDate: $identificationProofExpirationDate
          }
        ) {
          applyingKyc {
            id
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    deleteNote: gql`
      mutation deleteNote($kycNoteId: ID!) {
        deleteKycV2Note(input: { kycNoteId: $kycNoteId }) {
          errors {
            field
            message
          }
        }
      }
    `,
    markNote: gql`
      mutation markNote($kycNoteId: ID!) {
        markKycV2Note(input: { kycNoteId: $kycNoteId }) {
          kycV2Note {
            id
            isMarked
          }
          errors {
            field
            message
          }
        }
      }
    `,
    rejectKyc: gql`
      mutation(
        $applicationId: ID!,
        $rejectionReasons: [RejectionReasonValue!]!
      ) {
        rejectTier2Kyc(
          input: {
            applicationId: $applicationId,
            rejectionReasons: $rejectionReasons
          }
        ) {
          applyingKyc {
            id
            status
          }
          errors {
            field
            message
          }
        }
      }
    `,
    unmarkNote: gql`
      mutation unmarkNote($kycNoteId: ID!) {
        unmarkKycV2Note(input: { kycNoteId: $kycNoteId }) {
          kycV2Note {
            id
            isMarked
          }
          errors {
            field
            message
          }
        }
      }
    `,
  },
  query: {
    fetchAuditTrails: gql`
      query fetchAuditTrails($kycId: ID!,
        $limit: Int!,
        $offset: Int!,
        $startDate: Date,
        $endDate: Date,
        $actions: [AuditTrailAction!],
      ) {
        kycV2(id: $kycId) {
          id
          auditTrails(
            first: $limit
            offset: $offset
            startDate: $startDate
            endDate: $endDate
            actions: $actions  
          ) {
            edges {
              node {
                id
                action
                status 
                createdAt
              }
            }
            totalCount
          }
        }
      }
    `,
    fetchDetails: gql`
      query fetchKycV2Details($id: ID!) {
        kycV2(id: $id) {
          id
          artemisUrl
          aresUrl
          status
          acceptedAt
          rejectedAt
          updatedAt
          record {
            id
            address
            age
            birthCountry
            birthdate
            email
            gender
            identificationNumber
            incomeSource
            industry
            name
            nationality
            occupation
            residenceCountry
            riskRating
            status
            title
          }
          user {
            id
            email
            ethAddress
            wasReactivated
            walletChangeRequest {
              id
              comment
              ethAddress
              wallet
              hdPath
              status
              approvedAt
              createdAt
              rejectedAt
              expiresAt
            }
            deactivationRequest {
              reasons
              longReason
              createdAt
            }
          }
        }
      }
    `,
    fetchNotes: gql`
      query fetchNotes($kycId: ID!, $limit: Int! $offset: Int!, $isMarked: Boolean) {
        kycV2(id: $kycId) {
          id
          notes(first: $limit, offset: $offset, isMarked: $isMarked) {
            edges {
              node {
                id
                body
                canDelete
                isMarked
                kycV2 {
                  id
                }
                officer {
                  id
                  email
                }
                updatedAt
                createdAt
              }
            }
            totalCount
          }
        }
      }
    `,
  },
  subscription: {
    auditTrailSubscription: gql`
      subscription kycV2AuditTrailSubscription ($kycId: ID!) {
        kycV2AuditTrailLogged(kycId: $kycId) {
          id
          action
          status
        }
      }
    `,
    notesAdded: gql`
      subscription($kycId: ID) {
        kycV2NoteAdded(kycId: $kycId) {
          id
        }
      }
    `,
    notesDeleted: gql`
      subscription($kycId: ID) {
        kycV2NoteDeleted(kycId: $kycId)
      }
    `,
    notesMarked: gql`
      subscription($kycId: ID) {
        kycV2NoteMarked(kycId: $kycId) {
          id
          isMarked
        }
      }
    `,
    notesUnmarked: gql`
      subscription($kycId: ID) {
        kycV2NoteUnmarked(kycId: $kycId) {
          id
          isMarked
        }
      }
    `,
  },
};

export default KycApplicationGQL;
