import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Refresh = props => (
	<Container {...props}>
		<Icon viewBox="0 0 17 16.9">
			<path fill="none" strokeWidth="1.3" d="M4.6 12.5H1.4v3.1" />
			<path fill="none" strokeWidth="1.3" d="M1.4 12.4L3 14c3 3 8 3 11.1 0 1.5-1.5 2.3-3.4 2.3-5.5m-.8-4L14 2.9c-3-3-8-3-11.1 0C1.5 4.4.6 6.4.6 8.5" />
			<path fill="none" strokeWidth="1.3" d="M12.4 4.5h3.2V1.3" />
		</Icon>
	</Container>
);

export default Refresh;
