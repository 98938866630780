import { DEFAULT_CAPTCHA_CODE, LANGUAGES, LOCAL_STORAGE_LNG_KEY } from '@digix/electron/utils/constants';

const isProduction = process.env.ENVIRONMENT === 'production';
const isStaging = process.env.ENVIRONMENT === 'kovan';

const language = LANGUAGES.find(L => L.value === localStorage.getItem(LOCAL_STORAGE_LNG_KEY));
const hl = language ? language.captchaCode : DEFAULT_CAPTCHA_CODE;

const FAQ_LINK = 'https://digix.zendesk.com/hc/en-us';
const BLOG_LINK = 'https://medium.com/digix';
const CONTACT_LINK = 'https://digix.zendesk.com/hc/en-us/requests/new';
const RECAST_FAQ_LINK = 'https://digix.zendesk.com/hc/en-us';
const RECAPTCHA_V2_LINK = `https://www.google.com/recaptcha/api.js?hl=${hl}`;

const UI_SITE = (isProduction && 'https://digix.global')
  || (isStaging && 'https://www-kovan.digixdev.com')
  || 'https://localhost:5000';

const IPFS_ENDPOINT = (isProduction && 'https://explorer-api.digix.global/ipfs')
  || (isStaging && 'https://assets-explorer-staging.digixdev.com/ipfs')
  || 'https://localhost:25002/ipfs';

const ASSETS_EXPLORER_SERVER = () => {
  const http = (isProduction && 'https://explorer-api.digix.global/api')
    || (isStaging && 'https://assets-explorer-staging.digixdev.com/api')
    || 'https://localhost:25002/api';

  const webSocket = (isProduction && 'wss://explorer-api.digix.global/socket')
    || (isStaging && 'wss://assets-explorer-staging.digixdev.com/socket')
    || 'wss://localhost:25002/socket';

  return {
    http,
    webSocket,
  };
};

const ELECTRON_ROOT_SERVER = () => {
  const http = (isProduction && 'https://core-api.digix.global/graphql')
    || (isStaging && 'https://electron-root-staging.digixdev.com/graphql')
    || 'https://localhost:22002/graphql';

  const webSocket = (isProduction && 'wss://core-api.digix.global/socket')
    || (isStaging && 'wss://electron-root-staging.digixdev.com/socket')
    || 'wss://localhost:22002/socket';

  return {
    http,
    webSocket,
  };
};

const PRICE_FEED_SERVER = () => {
  const http = (isProduction && 'https://pricefeed-api.digix.global/graphql')
    || (isStaging && 'https://central-pricefeed-staging.digixdev.com/graphql')
    || 'http://localhost:24001/graphql';

  const webSocket = (isProduction && 'wss://pricefeed-api.digix.global/socket')
    || (isStaging && 'wss://central-pricefeed-staging.digixdev.com/socket')
    || 'ws://localhost:24001/socket';

  return {
    http,
    webSocket,
  };
};

const ETHERSCAN = () => {
  const http = (isProduction && 'https://etherscan.io')
    || (isStaging && 'https://kovan.etherscan.io')
    || 'https://kovan.etherscan.io';

  return {
    http,
  };
};

const WEB3PROVIDER = () => {
  const http = (isProduction && 'https://mainnet.infura.io/v3/cc67a7ce67994f56b884d24179943047')
    || (isStaging && 'https://kovan.infura.io/v3/cc67a7ce67994f56b884d24179943047')
    || 'http://localhost:8545';

  return {
    http,
  };
};

const ZENDESK_DIGIX_OFFICIAL_CHANNELS = 'https://digix.zendesk.com/hc/en-us/articles/360047657512-Digix-Official-Channels';

const ZENDESK_NEW_REQUEST = 'https://digix.zendesk.com/hc/en-us/requests/new';

const ZENDESK_DISABLED_PURCHASE_ARTICLE = 'https://digix.zendesk.com/hc/en-us/articles/360059531592-Why-am-I-unable-to-purchase-DGX-';

export {
  ASSETS_EXPLORER_SERVER,
  BLOG_LINK,
  CONTACT_LINK,
  ELECTRON_ROOT_SERVER,
  ETHERSCAN,
  FAQ_LINK,
  IPFS_ENDPOINT,
  PRICE_FEED_SERVER,
  RECAPTCHA_V2_LINK,
  RECAST_FAQ_LINK,
  UI_SITE,
  WEB3PROVIDER,
  ZENDESK_DIGIX_OFFICIAL_CHANNELS,
  ZENDESK_DISABLED_PURCHASE_ARTICLE,
  ZENDESK_NEW_REQUEST,
};
