import styled, { css } from 'styled-components';
import { cornerAccent } from '@digix/electron/shared/';

const Label = styled.label`
  color: ${props =>
    props.disabled ? props.theme.checkbox.label.disabled :
      props.isMultiOptions ? props.theme.checkbox.label.base :
        props.checked ? props.theme.checkbox.label.active :
          props.theme.checkbox.label.default
  };
  cursor: ${props => (props.disabled && 'not-allowed') || 'pointer'};
  font-family: ${props => props.theme.typography.typeface.regular};
  font-size: ${props => props.fontSize || props.theme.typography.fontSize.sm};
  position: relative;
`;

const Box = styled.div`
  ${props => cornerAccent(props.isInvalid ? 'error' : 'secondary', 'xsmall')};
  background-color: transparent;
  border: 1.2px solid ${({ isDisabled, isInvalid, theme }) =>
    isDisabled
      ? theme.checkbox.disabled.borderColor
      : isInvalid
        ? theme.colors.semantic.error
        : theme.checkbox.unselected.borderColor};
  height: 2.4rem;
  margin-left: -4rem;
  position: absolute;
  transition: all ${props => props.theme.transition};
  width: 2.4rem;

`;

export const StyledCheckbox = {
  Wrapper: styled.div`
    line-height: var(--default-line-height);
    margin-bottom: ${props => props.isMultiOptions && '1.6rem'};
    margin-left: 4rem;
    
    ${props => props.center && css`
      align-items: center;
      display: flex;
      margin-bottom: 0;
    `};
  `,
  Input: styled.input`
    appearance: none;
    cursor: pointer;
    display: flex;
    height: 2.4rem;
    margin-bottom: 0;
    margin-left: -4rem;
    position: absolute;
    width: 2.4rem;
    z-index: 999;
    
    &:checked + ${Box} {
      background: ${props => props.theme.checkbox.selected.background};
      border-color: ${props => props.theme.checkbox.selected.background};
      transition: all ${props => props.theme.transition};
    }

    &:checked + ${Box}:before {
      background: ${props => props.theme.checkbox.selected.background};
    }
    
    &:checked + ${Box}:after {
      background: transparent;
      border: solid ${props => props.theme.checkbox.selected.borderColor};
      border-width: 0 0.2rem 0.2rem 0;
      content: '';
      display: block;
      height: 1.4rem;
      left: 0.8rem;
      margin-left: 0;
      padding: 0;
      position: absolute;
      top: 0.25rem;
      transform: rotate(45deg);
      width: 0.7rem;
    }

  `,
  Box: Box,
  Label: Label,
};
