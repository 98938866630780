import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Dashboard = props => (
  <Container {...props}>
    <Icon viewBox="0 0 24 15">
      <path d="M2.34 12.776V1.182h19.607v11.582m1.94 1.108H0"/>
      <path d="M12 3.5l4 3.9-1.455 1.422L12 6.344 9.455 8.832 8 7.41l4-3.91zm0 3.87l2.05 1.902L12 11.173 9.95 9.27 12 7.37z"/>
    </Icon>
  </Container>
);

export default Dashboard;
