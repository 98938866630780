import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Announcement = props => (
  <Container {...props}>
    <Icon viewBox="0 0 752 752">
      <path fill="#436AB1" stroke="#436AB1" d="M533.3 476.2a2.774 2.774 0 0 1 0-5.547h12.562c10.137-.02 18.387-8.27 18.406-18.398v-244.66c-.02-10.129-8.27-18.387-18.406-18.406h-219.76a2.774 2.774 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774h219.76c13.199.008 23.949 10.758 23.957 23.957v244.65c-.008 13.191-10.758 23.938-23.957 23.95zm-18.5 0a2.777 2.777 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774 2.782 2.782 0 0 1 2.773 2.774 2.771 2.771 0 0 1-2.773 2.773zm-18.5 0H206.11c-13.199-.012-23.949-10.758-23.957-23.949v-.285a2.782 2.782 0 0 1 2.773-2.774 2.782 2.782 0 0 1 2.774 2.774v.285c.02 10.129 8.277 18.379 18.406 18.398h290.19a2.782 2.782 0 0 1 2.773 2.773 2.767 2.767 0 0 1-2.77 2.778zm-311.37-39.957a2.782 2.782 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774 2.782 2.782 0 0 1 2.773 2.774 2.784 2.784 0 0 1-2.773 2.773zm0-18.5a2.782 2.782 0 0 1-2.773-2.773l-.004-207.37c.008-13.2 10.758-23.95 23.957-23.957h83.035a2.782 2.782 0 0 1 2.773 2.773 2.776 2.776 0 0 1-2.773 2.773l-83.035.004c-10.13.02-18.387 8.278-18.406 18.406v207.37a2.785 2.785 0 0 1-2.774 2.778zm122.68-228.55a2.774 2.774 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774 2.776 2.776 0 0 1 2.773 2.774 2.764 2.764 0 0 1-2.773 2.773z" />
      <path fill="#436AB1" stroke="#436AB1"d="M421.26 410.43a2.777 2.777 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774h121.28l-.004-196.91.008-.008a2.775 2.775 0 0 1 2.773-2.765 2.775 2.775 0 0 1 2.766 2.773v199.68c0 .738-.285 1.445-.805 1.961a2.75 2.75 0 0 1-1.96.813zm-18.5 0a2.777 2.777 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774 2.776 2.776 0 0 1 2.773 2.774 2.772 2.772 0 0 1-2.773 2.773zm-18.5 0H205.85a2.78 2.78 0 0 1-1.96-.813 2.784 2.784 0 0 1-.813-1.96l-.004-145.32a2.774 2.774 0 0 1 2.773-2.774 2.782 2.782 0 0 1 2.773 2.774v142.55h175.63a2.776 2.776 0 0 1 2.774 2.773 2.767 2.767 0 0 1-2.77 2.777zM205.85 246.61a2.774 2.774 0 0 1 0-5.547 2.782 2.782 0 0 1 2.773 2.774 2.78 2.78 0 0 1-2.773 2.773zm0-18.5a2.769 2.769 0 0 1-2.773-2.773l-.004-17.363a2.8 2.8 0 0 1 .812-1.961 2.75 2.75 0 0 1 1.961-.813h306.09a2.776 2.776 0 0 1 2.773 2.774 2.769 2.769 0 0 1-2.773 2.773h-303.31v14.586c0 1.54-1.25 2.777-2.773 2.777zm324.57-17.363a2.774 2.774 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774 2.776 2.776 0 0 1 2.773 2.774 2.771 2.771 0 0 1-2.773 2.773zM437.64 545.2H314.36a2.768 2.768 0 0 1-2.203-1.082 2.772 2.772 0 0 1-.488-2.394l10.71-40.746c.387-1.442 1.934-2.36 3.395-1.981a2.79 2.79 0 0 1 1.98 3.395l-9.796 37.258h116.08l-16.707-63.441h-84.848a2.774 2.774 0 0 1-2.773-2.774 2.782 2.782 0 0 1 2.773-2.773l86.988-.004a2.76 2.76 0 0 1 2.68 2.07l18.176 68.992c.21.832.035 1.711-.488 2.395a2.76 2.76 0 0 1-2.203 1.085zm-107.87-58.633a2.56 2.56 0 0 1-.703-.094 2.777 2.777 0 0 1-1.98-3.386c.378-1.454 1.925-2.36 3.386-1.98a2.72 2.72 0 0 1 1.691 1.284 2.779 2.779 0 0 1-2.395 4.176z" />
      <path fill="#436AB1" stroke="#436AB1" d="M417.5 561.98a2.769 2.769 0 0 1-2.773-2.773 2.777 2.777 0 0 1 2.773-2.774h71.859v-11.242h-226.75v11.238h117.89a2.784 2.784 0 0 1 2.773 2.774 2.776 2.776 0 0 1-2.773 2.773l-120.67.004a2.78 2.78 0 0 1-1.96-.813 2.784 2.784 0 0 1-.813-1.96l-.004-16.79c0-.73.297-1.445.812-1.96a2.763 2.763 0 0 1 1.961-.813h232.3c.73 0 1.445.297 1.969.813.508.52.805 1.23.805 1.96v16.79c0 .742-.286 1.445-.805 1.96a2.769 2.769 0 0 1-1.969.813zm-18.5 0a2.769 2.769 0 0 1-2.773-2.773 2.777 2.777 0 0 1 2.773-2.774 2.784 2.784 0 0 1 2.773 2.774A2.774 2.774 0 0 1 399 561.98zM376 451.1c-5.938-.008-10.777-4.828-10.785-10.758.008-5.937 4.836-10.773 10.785-10.773 5.918 0 10.75 4.836 10.75 10.773-.012 5.922-4.828 10.75-10.75 10.758zm0-15.992c-2.875.012-5.227 2.36-5.234 5.234.007 2.868 2.359 5.2 5.234 5.207a5.228 5.228 0 0 0 5.207-5.207c-.008-2.875-2.34-5.222-5.207-5.234zM277.08 237.38h-50.367a2.772 2.772 0 0 1-2.773-2.766 2.787 2.787 0 0 1 2.773-2.785h50.363a2.779 2.779 0 0 1 2.773 2.785 2.764 2.764 0 0 1-2.77 2.766zM327.44 256.24H226.71a2.782 2.782 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774h100.73a2.776 2.776 0 0 1 2.773 2.774 2.77 2.77 0 0 1-2.77 2.773zM327.44 275.09H226.71a2.774 2.774 0 0 1-2.773-2.773 2.775 2.775 0 0 1 2.773-2.766h100.73a2.767 2.767 0 0 1 2.773 2.766 2.766 2.766 0 0 1-2.77 2.773zM163.26 297.13a2.774 2.774 0 0 1-2.773-2.773l-.004-104.84c.008-14.523 11.84-26.352 26.37-26.363h60.235a2.774 2.774 0 0 1 0 5.547l-60.234.003c-11.461.02-20.793 9.364-20.82 20.812v104.84a2.769 2.769 0 0 1-2.774 2.774zM588.74 236.61a2.776 2.776 0 0 1-2.773-2.773V189.52c-.008-5.524-2.176-10.75-6.125-14.7-3.95-3.94-9.168-6.113-14.7-6.113a2.782 2.782 0 0 1-2.773-2.773 2.776 2.776 0 0 1 2.774-2.774c14.53.008 26.352 11.84 26.363 26.363l.011 44.312a2.785 2.785 0 0 1-2.777 2.774z" />
      <path fill="#F9D167" stroke="#F9D167" d="M435.03 387.91c-14.262 0-28.266-3.8-40.504-10.988a80.437 80.437 0 0 1-28.738-28.84 2.696 2.696 0 0 1-.285-2.102 2.72 2.72 0 0 1 1.285-1.691c.426-.25.906-.379 1.398-.379.98 0 1.907.535 2.395 1.379 13.328 22.863 38.023 37.074 64.44 37.074 19.915-.012 38.638-7.762 52.716-21.828 14.078-14.086 21.828-32.809 21.828-52.715 0-19.895-7.75-38.609-21.828-52.695-14.078-14.086-32.801-21.84-52.703-21.84-19.906 0-38.625 7.75-52.703 21.84-14.078 14.086-21.828 32.801-21.828 52.695 0 1.21.027 2.422.082 3.605v.141a2.772 2.772 0 0 1-2.637 2.766 2.764 2.764 0 0 1-2.906-2.645 75.361 75.361 0 0 1-.094-3.867c0-10.805 2.118-21.293 6.29-31.16a79.615 79.615 0 0 1 17.167-25.453 79.66 79.66 0 0 1 25.457-17.168c9.872-4.18 20.36-6.3 31.172-6.3s21.301 2.116 31.172 6.3c9.54 4.031 18.102 9.805 25.465 17.168a79.777 79.777 0 0 1 17.156 25.453c4.172 9.871 6.29 20.359 6.29 31.16 0 10.812-2.118 21.301-6.29 31.18a79.776 79.776 0 0 1-17.156 25.453c-7.363 7.352-15.918 13.125-25.465 17.156-9.87 4.184-20.363 6.3-31.176 6.3zm0-24.797c-8.332 0-15.113-6.781-15.113-15.113.012-4.043 1.574-7.844 4.422-10.703a14.971 14.971 0 0 1 10.69-4.441c8.352 0 15.142 6.789 15.142 15.14 0 8.337-6.797 15.118-15.141 15.118zm0-24.707c-5.27.008-9.563 4.309-9.563 9.594.012 5.261 4.301 9.554 9.563 9.562 5.281-.008 9.582-4.3 9.594-9.562-.012-5.285-4.313-9.586-9.594-9.594zm-74.164-5.883a2.79 2.79 0 0 1-2.656-1.988c-.211-.711-.13-1.461.222-2.11a2.75 2.75 0 0 1 1.657-1.34c1.433-.425 3.023.454 3.441 1.88a2.777 2.777 0 0 1-1.879 3.449c-.246.07-.515.11-.785.11zm79.418-4.05h-10.48c-5.457-.008-9.906-4.45-9.914-9.887v-56.137c.008-5.45 4.457-9.887 9.914-9.887h10.48c5.438 0 9.879 4.441 9.887 9.887v56.137c-.016 5.437-4.446 9.867-9.887 9.886zm-10.48-70.372c-2.406.008-4.367 1.961-4.367 4.348v56.137c0 2.348 2.008 4.34 4.367 4.34h10.48c2.348 0 4.336-1.988 4.336-4.34V262.45c0-2.387-1.95-4.34-4.336-4.348z" />
      <path fill="#F9D167" stroke="#F9D167" d="M440.29 328.47h-10.48c-5.457-.008-9.906-4.45-9.914-9.887v-56.137c.008-5.449 4.457-9.886 9.914-9.886h10.48c5.438 0 9.879 4.441 9.887 9.886v56.137c-.016 5.438-4.446 9.867-9.887 9.887zm-10.48-70.371c-2.406.008-4.367 1.96-4.367 4.348v56.137c0 2.347 2.008 4.34 4.367 4.34h10.48c2.348 0 4.336-1.989 4.336-4.34v-56.137c0-2.387-1.95-4.34-4.336-4.348z" />
      <path fill="#F9D167" stroke="#F9D167" d="M435.03 363.11c-8.332 0-15.113-6.781-15.113-15.113a15.155 15.155 0 0 1 4.422-10.703 14.991 14.991 0 0 1 10.69-4.441c8.352 0 15.142 6.789 15.142 15.14 0 8.337-6.797 15.118-15.141 15.118zm0-24.707c-5.27.008-9.563 4.309-9.563 9.594.012 5.261 4.301 9.554 9.563 9.562 5.281-.008 9.582-4.3 9.594-9.562-.012-5.285-4.313-9.586-9.594-9.594z" />
      <path fill="#436AB1" stroke="#436AB1" d="M294.84 347.15h-68.133a2.782 2.782 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774h68.133a2.782 2.782 0 0 1 2.773 2.774 2.78 2.78 0 0 1-2.773 2.773zM362.94 366H226.71a2.774 2.774 0 0 1-2.773-2.773 2.782 2.782 0 0 1 2.773-2.774h136.23a2.776 2.776 0 0 1 2.773 2.774A2.772 2.772 0 0 1 362.94 366zM362.94 384.86H226.71a2.782 2.782 0 0 1-2.773-2.773 2.776 2.776 0 0 1 2.773-2.774h136.23a2.769 2.769 0 0 1 2.773 2.774 2.777 2.777 0 0 1-2.773 2.773z" />
      <path fill="#436AB1" stroke="#436AB1" d="M431.88 575.93H188.86c-1.535 0-2.773-1.25-2.773-2.773s1.238-2.774 2.773-2.774h243.02a2.784 2.784 0 0 1 2.773 2.774 2.78 2.78 0 0 1-2.773 2.773zM499.32 588.84H331.15a2.769 2.769 0 0 1-2.773-2.773 2.777 2.777 0 0 1 2.773-2.774h168.17a2.782 2.782 0 0 1 2.773 2.774 2.774 2.774 0 0 1-2.773 2.773z" />
      <path fill="#436AB1" stroke="#436AB1" d="M537.55 588.84h-21.016a2.777 2.777 0 0 1-2.773-2.773 2.784 2.784 0 0 1 2.773-2.774h21.016a2.782 2.782 0 0 1 2.773 2.774 2.774 2.774 0 0 1-2.773 2.773zM258.98 588.45h-79.695a2.776 2.776 0 0 1-2.773-2.773 2.769 2.769 0 0 1 2.773-2.774h79.695a2.774 2.774 0 0 1 2.773 2.774 2.782 2.782 0 0 1-2.773 2.773z" />
    </Icon>
  </Container>
);

export default Announcement;
