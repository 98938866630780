import Swiper from 'swiper';
import { breakpoints } from '@digix/electron/shared/style/breakpoints';
import React, { Component } from 'react';
import {
  bool, node, object, oneOfType, string,
} from 'prop-types';
import '@digix/electron/assets/css/swiper.min.css';

class SwiperComponent extends Component {
  constructor(props) {
    super(props);
    this.swiperRef = React.createRef();
    this.state = {
      swiperEnabled: false,
    };
    this.swiperInstance = null;
    this.mobileBreakpoint = window.matchMedia(`(max-width: ${this.getMobileBreakpoint()}px)`);

    this.initMobileSwiper = this.initMobileSwiper.bind(this);
    this.SwiperContainer = this.SwiperContainer.bind(this);
    this.SwiperWrapper = this.SwiperWrapper.bind(this);
    this.SwiperSlide = this.SwiperSlide.bind(this);
  }

  componentDidMount() {
    this.integrateSwiper();
  }

  componentDidUpdate(newProps) {
    const { loading } = this.props;
    if (loading !== newProps.loading) {
      this.disableSwiper();
      this.integrateSwiper();
    }
  }

  componentWillUnmount() {
    this.disableSwiper();
    this.mobileBreakpoint.removeListener(this.initMobileSwiper);
  }

  // eslint-disable-next-line class-methods-use-this
  getMobileBreakpoint() {
    return breakpoints.sm - 1;
  }

  enableSwiper() {
    const { swiperConfig } = this.props;
    const init = () => {
      if (!this.swiperRef.current) {
        return;
      }

      const swiperDomEl = this.swiperRef.current;
      const swiperSlideLength = swiperDomEl
        .querySelector('.swiper-wrapper')
        .children
        .length;

      return new Swiper(swiperDomEl, {
        loopAdditionalSlides: swiperSlideLength,
        ...swiperConfig,
      });
    };

    this.setState({
      swiperEnabled: true,
    });

    setTimeout(() => {
      this.swiperInstance = init();
    }, 0);
  }

  disableSwiper() {
    if (!this.swiperInstance) {
      return;
    }

    this.swiperInstance.destroy(true, true);
    this.setState({
      swiperEnabled: false,
    });
  }

  initMobileSwiper() {
    const isMobile = () => this.mobileBreakpoint.matches;
    const { children } = this.props;
    if (isMobile() && children.length > 1) {
      this.enableSwiper();
    } else {
      this.disableSwiper();
    }
  }

  integrateSwiper() {
    this.initMobileSwiper();
    this.mobileBreakpoint.addListener(this.initMobileSwiper);
  }

  SwiperContainer({ children }) {
    const { swiperEnabled } = this.state;
    if (swiperEnabled) {
      return (
        <div
          className="swiper-container"
          ref={this.swiperRef}
        >
          {children}
        </div>
      );
    }

    return children;
  }

  SwiperWrapper({ children }) {
    const { swiperEnabled } = this.state;
    if (swiperEnabled) {
      return (
        <div className="swiper-wrapper">
          {children}
        </div>
      );
    }

    return children;
  }

  SwiperSlide({ children }) {
    const { swiperEnabled } = this.state;
    if (swiperEnabled) {
      return (
        <div className="swiper-slide">
          {children}
        </div>
      );
    }

    return children;
  }

  render() {
    const { children } = this.props;
    return (
      <this.SwiperContainer>
        <this.SwiperWrapper>
          {children.map((child, index) => (
            <this.SwiperSlide key={index}>{child}</this.SwiperSlide>
          ))}
        </this.SwiperWrapper>
      </this.SwiperContainer>
    );
  }
}

SwiperComponent.propTypes = {
  children: oneOfType([object, string, node]),
  loading: bool,
  swiperConfig: object,
};

SwiperComponent.defaultProps = {
  children: [],
  loading: false,
  swiperConfig: {
    centeredSlides: true,
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1.26,
    spaceBetween: 24,
  },
};


export default SwiperComponent;
