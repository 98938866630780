import { Icon } from '@digix/electron/shared';
import React from 'react';
import { SnackbarStyles } from '@digix/electron/shared/snackbar/styles';
import { connect } from 'react-redux';
import { getMilisecondsByDuration } from '@digix/electron/shared/snackbar/helper';
import { showSnackbar } from '~/actions/snackbar';
import {
  any,
  bool,
  func,
  oneOf,
} from 'prop-types';

const {
  CloseButton,
  Inner,
  Text,
  Wrapper,
} = SnackbarStyles;

const filterValidDOMProps = props => {
  const filteredProps = {};
  const invalidDOMProps = ['onShowSnackbar'];
  Object.keys(props)
    .filter(prop => !invalidDOMProps.includes(prop))
    .forEach(key => filteredProps[key] = props[key]);
  return filteredProps;
};

let timer;

const Snackbar = props => {
  const {
    duration,
    history,
    onShowSnackbar,
    text,
    variant,
    visible,
  } = props;

  const getDuration = () => {
    if (variant !== 'warning') {
      if (duration === 'indefinite') {
        return 'short';
      }
      return duration;
    }
    return duration || 'indefinite';
  };

  const handleClose = () => {
    const { location } = window;
    const hrefQuery = location.href.split('?')[1];
    const hrefParams = hrefQuery ? hrefQuery.split('&') : [];
    const hasInvalidToken = hrefParams.includes('invalid_token=true');
    if (hasInvalidToken) {
      const paramIndex = location.href.indexOf('?');
      let currentUrl = location.href.replace('?invalid_token=true', '');
      if (currentUrl[paramIndex] && currentUrl[paramIndex] === '&') {
        currentUrl = `${currentUrl.substr(0, paramIndex)}?${currentUrl.substr(paramIndex + 1)}`
      }
      location.href = currentUrl;
    }
    resetState();
  };

  const resetState = () => {
    onShowSnackbar({
      duration: 'long',
      position: 'top',
      text: '',
      variant: 'success',
      visible: false,
    });
  };

  if (!visible) {
    return null;
  }

  history.listen(() => {
    window.clearTimeout(timer);
    // NOTE: for login, history might be updated twice because of goToLogin and 401 error handling
    // which will cause the snackbar to reset prematurely
    const path = history.location.hash.split('?')[0];
    const isLogin = path === '#/portal/login' && duration === 'indefinite';
    if (!isLogin) {
      resetState();
    }
  });

  if (getDuration() !== 'indefinite') {
    timer = window.setTimeout(() =>
      resetState(), getMilisecondsByDuration(getDuration()));
  }

  return (
    <Wrapper
      data-digix={`SnackBar-${variant === 'success' ? 'Success' : 'Failed'}`}
      {...filterValidDOMProps(props)}
    >
      <Inner>
        <Text>{text}</Text>
        {variant === 'warning' && (
          <CloseButton
            icon="close"
            inverse
            onClick={handleClose}
            size="medium"
            variant="icon"
          />
        )}
      </Inner>
    </Wrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  onShowSnackbar(snackbar) {
    dispatch(showSnackbar(snackbar));
  },
});

Snackbar.propTypes = {
  duration: oneOf(['short', 'long', 'indefinite']),
  onShowSnackbar: func.isRequired,
  position: oneOf(['top', 'bottom']),
  text: any.isRequired,
  variant: oneOf(['error', 'success', 'warning']),
  visible: bool,
};

Snackbar.defaultProps = {
  duration: 'short',
  position: 'top',
  variant: 'success',
  visible: false,
};

export default connect(null, mapDispatchToProps)(Snackbar);
