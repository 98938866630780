/*
 * This stylesheet exports the Nav and NavDropdown styled components, which are
 * used for the Header component and other such components that uses a topbar
 * for the menu.
 *
 * =============================================================================
 * The hierarchy for each component is as follows:
 *
 * Nav.Wrapper (Section, uses css-grid)
 *   Nav.Container (nav, uses flexbox)
 *     Nav.Section (ul)
 *       Nav.Item (li)
 *
 * NavDropdown.Container (article, uses flexbox)
 *   NavDropdown.SelectedOption (Button)
 *   NavDropdown.List (ul)
 *     NavDropdown.Option (li)
 *
 * =============================================================================
 * The following are the available props for certain components:
 *
 * Nav.Container
 *   alignContent: align-content (for flexbox); defaults to 'center'
 *
 * Nav.Section
 *   flexGrow: flex-grow (for flexbox); defaults to '1'
 *   textAlign: text-align; defaults to 'left'
 *
 * NavDropdown.List (position is absolute)
 *   right: for positioning; defaults to 0.5rem
 *   show: boolean; if true, it will display the dropdown list
 *   top: for positioning; defaults to 4rem
 *   width: width of the list container; defaults to calc(100% + 3rem)
 *
 */

import styled, { css } from 'styled-components';
import { color, fontWeight } from '@digix/electron/shared';
import { Link } from 'react-router-dom';

const StyledLink = css`
  color: ${props => props.color ? color(props.color) : color('primary')};
  font-family: ${props => fontWeight(props.fontWeight)};
`;

export const Menu = {
  Container: styled.ul``,

  Item: styled.li`
    outline: none;
  `,

  HyperLink: styled.a`
    ${StyledLink}
    cursor: pointer;
  `,

  NavLink: styled(Link)`
    ${StyledLink}
  `,
};


