import { actions } from '~/actions/resize';
import { breakpoints } from '@digix/electron/shared/style/breakpoints';

const MOBILE_BREAKPOINT = breakpoints.sm;

const initialState = {
  isMobile: window.innerWidth < MOBILE_BREAKPOINT,
};

const resize = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
};

export default resize;
