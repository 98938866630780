export const actions = {
  TOGGLE_IS_REMINTING: 'TOGGLE_IS_REMINTING',
  UPDATE_DGX_LEFT: 'UPDATE_DGX_LEFT',
  UPDATE_SELECTED_ASSETS: 'UPDATE_SELECTED_ASSETS',
};

export const toggleIsReminting = (isReminting) => ({
  payload: isReminting,
  type: actions.TOGGLE_IS_REMINTING,
});

export const updateDgxLeft = (dgxLeft) => ({
  payload: Number(dgxLeft),
  type: actions.UPDATE_DGX_LEFT,
});

export const updateSelectedAssets = (asset) => ({
  payload: asset,
  type: actions.UPDATE_SELECTED_ASSETS,
});
