import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Globe = props => (
  <Container {...props}>
    <Icon viewBox="0 0 17 17">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-1219 -67)">
          <g transform="translate(1220 68)">
            <path d="m7.4 15.387v-15.244" />
            <path d="m15.239 8h-14.993" />
            <path d="m5.1341 0.92943c-0.021106 0.027574-1.4727 1.9581-1.4727 6.8129 0 4.8912 1.4577 6.7939 1.4723 6.8123 0 0 0.25362 0.40545 0.38332 0.57226" />
            <path d="m9.2815 0.14352 0.28289 0.48238c0.06434 0.081702 1.5779 2.0562 1.5779 7.1163 0 5.0604-1.8342 7.6071-1.8342 7.6071" />
            <path d="m7.7423 15.485c-4.2693 0-7.7423-3.473-7.7423-7.7423 0-4.2689 3.473-7.7423 7.7423-7.7423 4.1593 0 7.5632 3.2967 7.7355 7.4145 0.0044256 0.1086 0.0068085 0.21821 0.0068085 0.32783 0 0.11098-0.0023829 0.22128-0.0071489 0.33089-0.17396 4.1164-3.5772 7.4114-7.7351 7.4114z" />
            <path d="m14.819 10.736c-0.081702 0.06434-2.0562 1.5779-7.1163 1.5779-5.0618 0-6.9913-1.5152-7.0713-1.5796" />
            <path d="m0.64123 4.7306c0.079319-0.062638 2.001-1.5384 7.0614-1.5384 5.0601 0 7.0349 1.5139 7.1163 1.5782" />
            <path d="m5.113 0.957 0.45991-0.64647" />
          </g>
        </g>
      </g>
    </Icon>
  </Container>
);

Globe.defaultProps = {
  width: '2rem',
  height: '2rem',
};

export default Globe;
