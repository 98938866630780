import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';
import styled, { keyframes } from 'styled-components';

const rotateKeyframe = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`
const IconWithAnimation = styled(Icon)`
  animation: ${rotateKeyframe} 1s infinite steps(8);
`;

const Arrow = (props, size) => (
  <Container {...props}>
    <IconWithAnimation viewBox="0 0 512 512" size={size}>
      <path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path>
    </IconWithAnimation>
  </Container>
);

Arrow.defaultProps = {
  width: '',
  height: '',
};

export default Arrow;
