import React from 'react';
import PropTypes from 'prop-types';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Help = ({ ...props }) => (
  <Container {...props}>
    <Icon viewBox="0 0 18 18">
      <g fillRule="evenodd" strokeWidth="1.25">
        <path d="M1 9a8 8 0 1 1 16 0A8 8 0 0 1 1 9z" fill="none" />
        <path d="M7.08 7.33S7.297 5.4 9.036 5.4c1.308 0 2.215 1.204 1.625 2.335-.5.958-1.668 1.13-1.668 2.37" fill="none" />
        <path d="M8.162 11.988a.8.8 0 1 1 1.6 0 .8.8 0 0 1-1.6 0" stroke="none" />
      </g>
    </Icon>
  </Container>
);

const { object, string, node, oneOfType } = PropTypes;

Help.propTypes = {
  children: oneOfType([object, string, node]),
};

export default Help;
