import styled, { css } from 'styled-components';

const margin = css`
  ${props => props.size === 'small' ? 'var(--space-2xs)' : 'var(--space-md)'}
`;

export const Button = styled.button`
  align-items: center;
  background: ${props => props.disabled ? 'var(--disabled)' : 'var(--background)'};
  border: solid 1px ${props => props.disabled ? 'var(--disabled)' : 'var(--border-color)'};
  border-radius: var(--radius);
  color: ${props => props.disabled && props.variant === 'outlined' ? 'var(--text-muted)' : 'var(--color)'};
  cursor: ${props => (props.isLoading || props.disabled) ? 'not-allowed' : 'pointer'};
  display: inline-flex;
  font-family: var(--font-family);
  font-size: var(--font-size);
  height: var(--height, ${props => props.size === 'xsmall' ? 2.4 : 4.8}rem);
  justify-content: ${props => props.isLoading ? 'center' : 'space-between'};
  letter-spacing: ${props => props.size === 'xsmall' ? 'var(--letter-spacing-md)' : 'var(--letter-spacing, var(--letter-spacing-2xl))'};
  margin: 
    var(--margin-top, 0) 
    var(--margin-right, ${margin})
    var(--margin-bottom, 0) 
    var(--margin-left, ${props => props.isFirst ? 0 : margin})
  ;
  min-width: var(--min-width, ${props =>
    (props.size === 'xlarge' && 22.4)
    || (props.size === 'large' && 15.2)
    || (props.size === 'medium' && 12.4)
    || (props.size === 'small' && 9.6)
    || (props.size === 'xsmall' && 7.2)}rem);
  outline: none;
  padding:
    var(--padding-top, 0)  
    var(--padding-right, ${props => props.size === ('xlarge' || 'large') ? 'var(--space-md)' : 'var(--space-base)'})
    var(--padding-bottom, 0) 
    var(--padding-left, ${props => props.size === 'xsmall' ? 'var(--space-xs)' : 'var(--space-base)'})
  ;
  text-decoration: var(--text-decoration, none);
  text-transform: var(--text-transform, uppercase);
  transition: all var(--transition);
  white-space: nowrap;
  width: var(--width, fit-content);

  div svg {
    fill: ${props => props.disabled ? 'var(--disabled)' : 'var(--fill)'};
    stroke: ${props => props.disabled ? 'var(--disabled)' : 'var(--stroke)'};
  }
`;
