export const actions = {
  TOGGLE_IS_PURCHASING: 'TOGGLE_IS_PURCHASING',
  UPDATE_TXSTATUS: 'UPDATE_TXSTATUS',
};

export const toggleIsPurchasingDgx = (isPurchasingDgx) => ({
  payload: isPurchasingDgx,
  type: actions.TOGGLE_IS_PURCHASING,
});

export const updateTxStatus = (status) => ({
  payload: status,
  type: actions.UPDATE_TXSTATUS,
});
