import { OptionButton } from '@digix/electron/shared/radio/styles';
import React from 'react';

const {
  Input,
  Label,
  Text,
  Wrapper,
} = OptionButton;

const RadioButton = ({
  id,
  isChecked,
  isInline,
  isLabelFirst,
  isNarrow,
  name,
  label,
  onChange,
  type,
  value,
  ...props
}) => (
  <Wrapper
    isInline={isInline}
    isNarrow={isNarrow}
    type={type}
  >
    <Label
      htmlFor={name}
      isLabelFirst={isLabelFirst}
    >
      <Input
        checked={isChecked}
        id={id}
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
        {...props}
      />
      <Text
        isMaxContent={isLabelFirst}
        isNarrow={isNarrow}
      >
        {label}
      </Text>
    </Label>
  </Wrapper>
);

export default RadioButton;
