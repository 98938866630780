import { css } from 'styled-components';

export const boxShadow = (...shadows) => `
    -moz-box-shadow: ${shadows};
    -webkit-box-shadow: ${shadows};
    box-shadow: ${shadows};
`;


export const lineHeight = lineHeight => css`
  line-height: ${lineHeight}rem;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    margin: 0;
  }
`;

export const accentDivider = size => css`
  margin-left: ${props =>
    (size === 'large' && 13.6) || (size === 'small' && 7.2) || 7.2}rem;
  position: relative;

  &::before {
    border-top: 0.4rem solid ${props => props.theme.divider.accent};
    content: '';
    margin-left: -${props => (size === 'large' && 13.6) || (size === 'small' && 7.2) || 7.2}rem;
    position: absolute;
    top: 1.25rem;
    width: ${(size === 'large' && 10.5) || (size === 'small' && 4.8) || 6.4}rem;
  }
`;

export const cornerAccent = (kind, size) => css`
  clip-path: polygon(
    100% 0,
    calc(100% + 0.1rem)
    calc(100% -
    ${(size === 'xsmall' && 0.6)
  || (size === 'small' && 0.8)
  || (size === 'medium' && 1.2)
  || (size === 'large' && 1.6)
  || 1.2}rem
  ),
  calc(
    100% -
    ${(size === 'xsmall' && 0.6)
  || (size === 'small' && 0.8)
  || (size === 'medium' && 1.2)
  || (size === 'large' && 1.6) ||
  1.2}rem
  )
  calc(100% + 0.1rem),
  0% calc(100% + 0.1rem),
  0 100%,
  0% 0%
);

  position: relative;

  &::before {
    --background: ${(kind === 'primary' && 'var(--color-primary)')
  || (kind === 'secondary' && 'var(--color-secondary)')
  || (kind === 'accent' && 'var(--color-accent)')
  || (kind === 'disabled' && 'var(--disabled)')
  || (kind === 'inverted' && 'var(--color-inverse)')
  || (kind === 'error' && 'var(--error)')
  || 'var(--color-secondary)'
  };
    background: var(--background);
    bottom: ${(size === 'xsmall' && -0.1)
  || (size === 'small' && -0.1)
  || (size === 'medium' && -0.1)
  || (size === 'large' && -0.15)
  || -0.1}rem;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    content: '';
    display: block;
    height: ${(size === 'xsmall' && 0.6)
  || (size === 'small' && 0.8)
  || (size === 'medium' && 1.2)
  || (size === 'large' && 1.6)
  || 1.2}rem;
    position: absolute;
    right: ${(size === 'xsmall' && -0.1) ||
  (size === 'small' && -0.1) ||
  (size === 'medium' && -0.1) ||
  (size === 'large' && -0.05) ||
  -0.05}rem;
    transition: all ${props => props.theme.transition};
    width: ${(size === 'xsmall' && 0.6)
  || (size === 'small' && 0.8)
  || (size === 'medium' && 1.2)
  || (size === 'large' && 1.6)
  || 1.2}rem;
  }
`;

export const gridColumn = column => css`
  display: grid;
  grid-gap: 0 2.4rem;
  grid-template-columns: repeat(${column}, 1fr);
`;

export const gridContent = width => css`
  grid-column: ${(width === 'wide' && '1 / span 12') ||
  (width === 'medium' && '2 / span 10') ||
  (width === 'narrow' && '3 / span 8') ||
  'main-start / main-end'};
`;

// Adding Background Color to a Container.
// Must provide string values [neutral | dark | light | accent] to make it work.

export const bgColor = background => css`
  background: ${props =>
    (background === 'neutral' &&
      props.theme.layout.section.neutral.background) ||
    (background === 'dark' &&
      `linear-gradient(135deg, ${props.theme.layout.section.dark.linear} 0%, ${props.theme.layout.section.dark.background} 60%)`) ||
    (background === 'light' && props.theme.layout.section.light.background) ||
    (background === 'accent' && props.theme.layout.section.accent.background) ||
    props.theme.layout.section.light.background};

  color: ${props =>
    (background === 'neutral' &&
      props.theme.layout.section.neutral.textColor) ||
    (background === 'dark' && props.theme.layout.section.dark.textColor) ||
    (background === 'light' && props.theme.layout.section.light.textColor) ||
    props.theme.layout.section.light.textColor};
`;

export const fontWeight = weight => {
  switch (weight) {
    case 'normal':
      return 'var(--body-font-regular)';
    case 'semibold':
      return 'var(--body-font-semibold)';
    case 'bold':
      return 'var(--body-font-bold)';
  }
};

export const color = name => {
  switch (name) {
    case 'accent':
      return 'var(--color-accent)';
    case 'primary':
      return 'var(--color-primary)';
    case 'secondary':
      return 'var(--color-secondary)';
    case 'inverse':
      return 'var(--color-inverse)';
    case 'transparent':
      return 'transparent';


    // for refactor
    case 'primaryLight':
      return 'var(--color-secondary)';
    case 'primaryGradient':
      return props => `linear-gradient(135deg, ${props.theme.layout.section.dark.linear} 0%, ${props.theme.layout.section.dark.background} 60%)`;
    case 'secondary':
      return props => props.theme.colors.accent.base;
    case 'neutral':
      return props => props.theme.colors.semantic.muted;
    case 'inverted':
      return 'var(--color-inverse)';
  }
};

export const getAppWidth = (appName, media) => ({
  [appName]: props => props.theme.app[appName][media],
})[appName];

export const getColor = (palette, color) => ({
  [palette]: props => props.theme.colors[palette][color],
})[palette];

export const zindex = (component) => ({
  [component]: props => props.theme.zindex[component],
})[component];
