// npm install styled-components@latest babel-plugin-styled-components@latest
// npm install @types/styled-components

import { createGlobalStyle } from 'styled-components';
import { scrollNav } from '@digix/electron/themes/common/scroll-nav';
import { fonts } from '@digix/electron/themes/base/typography';
import { globalCss } from '@digix/electron/themes/common/global';
import { designToken } from '@digix/electron/themes/design-token';
import { reset } from '@digix/electron/themes/common/reset';

export const GlobalStyle = createGlobalStyle`

  /* Load Custom Fonts */
  ${fonts};

  /* Global Scope Styles */
  ${globalCss};

  /* CSS Reset */
  ${reset};

  /* Scoped Component Styles */
  ${designToken};

  /* Custom Styles for Scrolling Navigation */
  ${scrollNav};
`;
