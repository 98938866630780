import React from 'react';
import { Container, Icon } from '@digix/electron/shared/icon/style';

const Arrow = props => (
  <Container {...props}>
    <Icon viewBox="0 0 24 24">
      <path d="M12 21l-12-18h24z" />
    </Icon>
  </Container>
);

Arrow.defaultProps = {
  width: '2rem',
  height: '2rem',
};

export default Arrow;
