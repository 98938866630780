import styled, { css } from 'styled-components';
import { color } from '@digix/electron/shared';
import { Link } from 'react-router-dom';


const LinkStyles = css`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-decoration: ${props => props.underline ? 'underline' : 'none'};
  width: fit-content;

  &:link,
  &:active,
  &:visited {
    --color: ${props => color(props.kind)};
    color: var(--color);

    ${props => props.icon === 'close' && css`
      color: var(--muted);
      font-family: var(--body-font-semibold);
      font-size: var(--font-size-xs);
      letter-spacing: ${props => props.theme.typography.letterSpacing.wider}rem;
      --letter-spacing: var(--letter-spacing-2xl);

      div svg {
        fill: var(--muted);
      }
    `}
  }

  div svg {
    stroke: var(--stroke);
  }

  &:hover {
    --color: ${props => color(props.kind)};

    div svg {
      stroke: var(--stroke);
    }

    ${props => props.icon === 'close' && css`
      color: var(--muted);

      div svg {
        fill: var(--muted);
      }
    `}
  }
`;

export const HtmlLink = styled.a`
  ${LinkStyles}
`;

export const RouterLink = styled(Link)`
  font-family: var(--font-family, var(--default-font-family));

  ${LinkStyles}
`;
