
import { media } from '@digix/electron/shared';
import { css } from 'styled-components';

export const scrollNav = css`

  svg[data-name="logo"][data-page="inverse"] path {
    fill: var(--color-inverse);
  }

  ${media.lg`
    html:not([data-scroll='0']) header {
      --background: var(--color-inverse);
      background: var(--background);
      box-shadow: 0 3px 3px rgba(0,0,0,0.05);
      padding: 2.4rem 0rem;

      &[data-bgcolor="inverted"] {
        background: var(--background);

        a {
          color: var(--link);
          &:hover {
            color: var(--link-hover);
          }
        }

        button[data-name="language"] {
          color: var(--lang-selected);
        }

        ul[data-name="language-list"] {

          :after {
            border-bottom-color: var(--clr-n-black);
          }

          li {
            background-color: var(--color-primary);
          }

          span {
            color: var(--color-inverse);
          }

          svg {
            fill: var(--color-inverse);
            stroke: var(--color-inverse);
          }
        }

        button[data-name="register"] {
          --background: var(--color-primary);
          --border-color: var(--color-primary);
          --color: var(--color-inverse);

          &::before {
            --background: var(--color-primary);
          }

          &:hover {
            --background: var(--color-secondary);
            --border-color: var(--color-secondary);

            &::before {
              --background: var(--color-secondary);
            }
          }
        }

        svg[data-name="logo"][data-page="inverse"] path {
          :first-child {
            fill: var(--brand-color-1);
          }
          :nth-child(2) {
            fill: var(--brand-color-4);
          }
          :nth-child(3) {
            fill: var(--brand-color-3);
          }
          :nth-child(4) {
            fill: var(--brand-color-2);
          }
          :nth-child(5) {
            fill: var(--brand-color-1);
          }
        }

        [data-name="language"][data-page="inverse"] {
          svg {
            fill: none;
            stroke: var(--color-primary);
          }
        }
      }


      [data-page="inverse"][data-name="avatar"] {
        svg {
          stroke: var(--color-inverse);
          ${media.md`
            fill: none;
            stroke: var(--color-primary);
          `}
        }
      }
    }

  `}
`;