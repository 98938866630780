/* eslint-disable no-console */

import ReactGA from 'react-ga';
import { UI_SITE } from '@digix/electron/config/endpoints';
import { getCookie } from '@digix/electron/utils/cookies';

const isProduction = process.env.ENVIRONMENT === 'production';
const debugMode = !isProduction;
const GOOGLE_TRACKING_ID = 'UA-61278742-6';
const FACEBOOK_PIXEL_ID = isProduction
  ? '305356147094424'
  : '511179676235581';

const isGoogleEnabled = () => {
  const digixCookies = getCookie('digix');
  if (!digixCookies) {
    return;
  }

  const cookieConfig = JSON.parse(getCookie('digix'));
  if (cookieConfig.googleAnalytics) {
    return true;
  }

  return false;
};

const isFacebookEnabled = () => {
  const digixCookies = getCookie('digix');
  if (!digixCookies) {
    return;
  }

  const cookieConfig = JSON.parse(getCookie('digix'));
  if (cookieConfig.facebookPixel) {
    return true;
  }

  return false;
};

const initGoogleAnalytics = (opt) => {
  const options = {
    debug: debugMode,
    titleCase: true,
    ...opt,
  };

  if (isGoogleEnabled()) {
    ReactGA.initialize(GOOGLE_TRACKING_ID, options);
  }
};

const initFacebook = () => {
  if (isFacebookEnabled()) {
    if (debugMode) {
      console.log('//////// INITIALIZING FACEBOOK PIXEL ////////');
    }
    window.fbq('init', FACEBOOK_PIXEL_ID);
  }
};

const fbPageView = () => {
  if (isFacebookEnabled()) {
    if (debugMode) {
      console.log('[FB Pixel] Tracking Pageview');
    }

    window.fbq('track', 'PageView');
  }
};

const gaPageView = (location) => {
  if (isGoogleEnabled()) {
    if (isProduction) {
      ReactGA.pageview(`${UI_SITE}/${location.hash}`);
      return;
    }

    // TEST EL_v2-1202: check on kovan if destination goals will convert
    ReactGA.pageview(`/${location.hash}`);
  }
};

const Analytics = {
  fbPageView,
  gaPageView,
  initFacebook,
  initGoogleAnalytics,
};

export default Analytics;
