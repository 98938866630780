import { media } from '@digix/electron/shared';
import styled, { css } from 'styled-components';

export const StyledSteps = {
  BoxWrapper: styled.div`
    background-color: ${props => props.theme.stepper.box.background};
    z-index: 1;
  `,
  Container: styled.div`
    align-items: center;
    background-color: ${props => props.theme.stepper.background};
    display: flex;
    justify-content: space-between;
    position: relative;

    ${({ orientation }) => css`
      flex-direction: ${orientation === 'vertical' ? 'column' : 'initial'};
      padding: ${orientation === 'vertical' ? '0 0.8rem' : '0.6rem 0'};
      width: ${orientation === 'vertical' ? 'fit-content' : '100%'};

      :last-child {
        align-items: baseline;
      }
    `};
  `,
  Details: styled.div`
    line-height: var(--default-line-height);
    margin: 0;
    max-width: ${props => props.isEditable ? 15 : 20}rem;
    position: relative;
    width: max-content;

    ${media.xxs`
      button {
        left: 100%;
        position: absolute;
      }
    `}

    ${media.xs`
      max-width: ${props => props.isEditable ? 55 : 60}rem;

      ${props => props.orientation === 'horizontal' ? css`
        min-width: unset;
        width: ${100 / props.totalSteps};
      ` : css`
        min-width: ${props => props.isEditable ? 19 : 24}rem;
        width: min-content;
      `}
    `}

    ${media.sm`
      max-width: unset;
      min-width: max-content;
      width: max-content;

      ${props => props.orientation === 'horizontal' ? css`
        min-width: unset;
        width: ${100 / props.totalSteps};
      ` : css`
        min-width: max-content;
        width: min-content;
      `}

      button {
        left: 0;
        position: relative;
      }
    `}

    button {
      height: auto;
      top: 0;
    }

    & > p {
      margin-bottom: 0;
      margin-top: 0.8rem;
    }

    &:first-child {
      ${media.xxs`
        margin-left: 0;
      `}

      ${media.lg`
        margin-left: ${props => props.orientation === 'vertical' ? 0 : '6rem'};
      `}
    }

    &:last-child {
      ${media.xxs`
        margin-right: 0;
      `}

      ${media.lg`
        margin-right: ${props => props.orientation === 'vertical' ? 0 : '6rem'};
      `}
    }
  `,
  Line: styled.div`
    ${({ orientation, theme }) => css`
      background-color: ${theme.stepper.progress.default};
      height: ${orientation === 'vertical' ? '100%' : '0.5rem'};
      width:  ${orientation === 'vertical' ? '0.5rem' : '100%'};
    `};
    position: absolute;
  `,
  Progress: styled.div`
    ${({ orientation, percentage, theme }) => css`
      background-color: ${theme.stepper.progress.active};
      width: ${orientation === 'vertical' ? 'inherit' : `${percentage}%`};
      height: ${orientation === 'vertical' ? `${percentage}%` : 'inherit'};
    `}
  `,
  Step: styled.div`
    ${({ current, error, stepKey, theme }) => css`
      background-color: ${theme.stepper.progress[error ? 'error' : (current - 1 >= stepKey - 1 ? 'active' : 'default')]};
      height: 1.5rem;
      width: 1.5rem;
    `};
    position: relative;
  `,
  Title: styled.span`
    color: ${props => props.error ? props.theme.colors.semantic.error : 'inherit'};
    word-break: break-word;
  `,
  TitleWrapper: styled.div`
    font-family: ${props => props.theme.typography.typeface.primary.bold};
    margin-bottom: 0;
    width: 100%;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 100%;

    ${({ height, orientation, totalSteps }) => orientation === 'vertical' && css`
      flex-direction: initial;
      height: ${height || 'inherit'};
      min-height: ${totalSteps * 4.6}rem;
      width: fit-content;
    `}

    ${media.sm`
      padding: 1rem 0;
    `}

    ${media.md`
      padding: 1rem;
    `}
  `
};

export default StyledSteps;
