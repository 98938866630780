import React from 'react';
import TableV1 from '@digix/electron/shared/table/table';
import TableV2 from '@digix/electron/shared/table/table-v2';
import { oneOf } from 'prop-types';

const Table = props => {
  const tableProps = {};
  Object.keys(props)
    .filter(item => item !== 'version')
    .forEach(key => {
      tableProps[key] = props[key];
    });
  if (props.version === 'v2') {
    return <TableV2 {...tableProps} />
  }
  return <TableV1 {...tableProps} />
};

Table.propTypes = {
  version: oneOf(['v1', 'v2']),
};

Table.defaultProps = {
  version: 'v1',
};

export default Table;