import PaginationStyles from '@digix/electron/shared/pagination/styles';
import React from 'react';
import { func, number, bool } from 'prop-types';

const { Button, Wrapper } = PaginationStyles;

const Pagination = ({
  current,
  isDisabled,
  onPageChange,
  totalPages,
  visiblePageButtons,
}) => {
  const hasData = !!totalPages;
  // if (!hasData) { Uncomment this block if the pagination should only appear when there's data
  //   return null;
  // }

  const getRange = (start, stop, step = 1) => Array(Math.ceil((stop - start) / step) + 1)
    .fill(start)
    .map((a, b) => a + b * step);

  const handlePageChange = page => {
    if (page !== current) {
      onPageChange(page);
    }
  };

  const renderPageButtons = () => {
    const stop = current < visiblePageButtons
      ? visiblePageButtons
      : current === visiblePageButtons
        ? visiblePageButtons + 1
        : current + visiblePageButtons;
    const start = (stop > totalPages ? totalPages : stop) - visiblePageButtons;
    return getRange(start + 1, stop > totalPages ? totalPages : stop)
      .filter(item => item > 0 && item <= totalPages)
      .map(page => (
        <Button
          disabled={isDisabled || !hasData}
          isActive={current === page}
          key={page}
          onClick={() => handlePageChange(page)}
          size="xsmall"
          text={page}
          variant={current === page
            ? 'contained'
            : 'text'}
        />
      ));
  };

  return (
    <Wrapper>
      <Button
        data-name="fbackward"
        disabled={isDisabled || current === 1 || !hasData}
        icon="doubleAngle"
        kind="primary"
        onClick={() => handlePageChange(1)}
        size="small"
        variant="icon"
      />
      <Button
        data-name="backward"
        disabled={isDisabled || current === 1 || !hasData}
        icon="angle"
        onClick={() => handlePageChange(current - 1)}
        size="small"
        variant="icon"
      />
      {renderPageButtons()}
      <Button
        data-name="forward"
        disabled={isDisabled || current === totalPages || !hasData}
        icon="angle"
        onClick={() => handlePageChange(current + 1)}
        size="small"
        variant="icon"
      />
      <Button
        data-name="fforward"
        disabled={isDisabled || current === totalPages || !hasData}
        icon="doubleAngle"
        onClick={() => handlePageChange(totalPages)}
        size="small"
        variant="icon"
      />
    </Wrapper>
  );
};

Pagination.propTypes = {
  current: number,
  isDisabled: bool,
  onPageChange: func.isRequired,
  totalPages: number,
  visiblePageButtons: number,
};

Pagination.defaultProps = {
  current: 1,
  isDisabled: false,
  totalPages: 0,
  visiblePageButtons: 3,
};

export default Pagination;
