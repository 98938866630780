import { css } from 'styled-components';

export const designToken = css`

  body {
    font: normal var(--font-size-base)/var(--body-line-height) var(--default-body-font), Arial, Helvetica, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    --color: var(--color-primary);
    --font-serif: var(--heading-font);
    --font-sans: var(--body-font-semibold);
    --font-weight: normal;
    --line-height: var(--heading-line-height);
  }

  h1 {
    --font-size: var(--font-size-3xl);
    --margin-bottom: var(--space-4xl);
    --letter-spacing: var(--letter-spacing-xl);
  }

  h2 {
    --font-size: var(--font-size-2xl);
    --margin-bottom:  var(--space-md);
    --line-height: var(--line-height-sm);
  }

  h3 {
    --font-size: var(--font-size-xl);
    --margin-bottom:  var(--space-md);
    --line-height: var(--line-height-sm);
  }

  h4 {
    --font-size: var(--font-size-lg);
    --line-height: var(--default-line-height);
    --margin-bottom: var(--space-xl);
  }

  h5 {
    --font-size: var(--font-size-md);
    --letter-spacing: var(--letter-spacing-lg);
    --line-height: var(--default-line-height);
    --margin-bottom: var(--space-md);
  }

  h6 {
    --heading-font-size-6: var(--font-size-base);
    --line-height: var(--default-line-height);
    --margin-bottom: var(--space-sm);
  }

  p, span {
    --font: var(--body-font-regular);
    --font-size: var(--font-size-base);
    --line-height: var(--body-line-height);
  }

  a {
    --color: var(--color-secondary);
    --color-hover: var(--color-primary);

    --stroke: var(--color-secondary);
    --stroke-hover: var(--color-primary);
    
    color: var(--color); 
  }

  small {
    --font: var(--body-font-regular);
    --font-size: var(--font-size-2xs);
    --line-height: var(--default-line-height)
  }

  label {
    --line-height: var(--heading-line-height);
  }

  b, strong {
    font-family: var(--body-font-medium);
  }

  button {
    --blur: ;
    --font-family: var(--body-font-semibold);
    --font-size: var(--font-size-xs);
    --icon: ;
    --radius: 0;
    --shadow:  hsla(var(--primary-hsl), 5%);
  }

  div {
    --border-width: 1px;
    --border-style: solid;
  }

  svg {
    --default-color: var(--color-secondary);
    --default-hover-color: var(--color-secondary);
    
    --stroke: var(--color-secondary);
    --stroke-hover: var(--color-primary);
    --fill: var(--color-secondary);
    --fill-hover: var(--color-secondary);
  }

  header {
    --background: var(--color-inverse);
    --color: var(--color-primary);
  }

  section {
    --border-color: hsl(var(--hue), var(--saturation), 93%);
    --background: var(--color-inverse);
  }

  footer {
    --background: var(--grayscale-dark);
    --color: var(--color-inverse);
  }

  input {
    --background: var(--color-neutral-10);
    --disabled: var(--color-neutral-9);
    --border-color: var(--color-neutral-8);
    --box-shadow: var(--shadow);
    --font-family: var(--body-font-regular);
    --font-size: var(--font-size-base);
    
  }

  input, label {
    --border-color: var(--color-neutral-8);
    --label-color: var(--color-neutral-6);
    --text-muted: var(--color-neutral-6);
    --font-size-focus: var(--font-size-sm)
  }
`;
