import { isEqual, some } from 'lodash';
import { actions } from '~/actions/recast-remint';
import { MAX_DGX_DECIMALS } from '@digix/electron/utils/constants';

const initialState = {
  dgxLeft: 0,
  isReminting: false,
  selectedAssets: [],
};

const recastRemint = (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_IS_REMINTING: {
      return {
        ...state,
        isReminting: action.payload,
      };
    }
    case actions.UPDATE_DGX_LEFT: {
      return {
        ...state,
        dgxLeft: action.payload,
      };
    }
    case actions.UPDATE_SELECTED_ASSETS: {
      const asset = action.payload;

      // Reset the `selectedAssets` when there's no payload
      let selectedAssets = [];
      let { dgxLeft } = state;

      if (Array.isArray(asset)) {
        selectedAssets = asset;
      } else if (asset) {
        const goldBarValue = (asset.effectiveWeight / (10 ** MAX_DGX_DECIMALS)) * 1.01;
        // Select/De-select asset on changing checkbox
        if (some(state.selectedAssets, asset)) {
          dgxLeft += goldBarValue;
          selectedAssets = state.selectedAssets.filter(a => !isEqual(a, asset));
        } else {
          dgxLeft -= goldBarValue;
          selectedAssets = [...state.selectedAssets, asset];
        }
      }

      return {
        ...state,
        dgxLeft,
        selectedAssets,
      };
    }
    default:
      return state;
  }
};

export default recastRemint;
