import styled from "styled-components";
import { Button, zindex } from '@digix/electron/shared';

const AnnouncementStyles = {
  Button: styled(Button)`
    margin: 0;
  `,
  Container: styled.div`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: ${zindex('modal')};
  `,
  Content: styled.div`
    margin-top: 3rem;
    `,
  Title: styled.div`
    color: var(--color-primary);
  `,
  Text: styled.p``,
};

export default AnnouncementStyles;
