import { actions } from '~/actions/user';

const initialState = {
  accountAction: '',
  balances: [],
  showSessionTimeoutWarning: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_BALANCES:
      return {
        ...state,
        balances: action.payload,
      };
    case actions.SHOW_SESSION_TIMEOUT_WARNING:
      return {
        ...state,
        showSessionTimeoutWarning: action.payload,
      };
    case actions.SET_ACCOUNT_ACTION:
      return {
        ...state,
        accountAction: action.payload,
      };
    default:
      return state;
  }
};

export default user;
